import { Box, Flex, Text } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import FiatTable from "@components/settings/savedFiat/fiatTable"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { useTranslation } from "react-i18next"
import ScreenContainer from "@components/ui/containers/screen"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { InlineTextLink } from "@components/ui/link"
import useSupport from "@hooks/useSupport"

type Props = {}

export default function SavedAddressesFiat({}: Props) {
    const { t } = useTranslation("settings")
    const { navigate } = useRestrictedNavigation()
    const account = useAppSelector(selectAccountDetails)
    const { USSupport } = useSupport()

    function handleSupportClick() {
        window.open(`mailto:${USSupport}`, "_blank")
    }

    return (
        <ScreenContainer>
            <Box mb="1.5rem" flex={1}>
                <Flex flexDir="column" gap={2}>
                    <BreadCrumbGroup
                        breadCrumbs={[
                            {
                                text: t("savedFiatAccount.settings"),
                                hasAfter: true,
                                onClick: () => navigate(ROUTES.DASHBOARD_SETTINGS),
                            },
                            {
                                text: t("savedFiatAccount.fiat"),
                                hasAfter: false,
                            },
                        ]}
                        py={2}
                    />

                    <Flex justify="start" flexDir={{ base: "column", md: "row" }} w="100%" gap={{ md: 6 }}>
                        <Flex flex={1} flexDir="column" gap={2}>
                            <Text textStyle="ManropeMedium4xLarge">{t("savedFiatAccount.title")}</Text>
                            {account?.country === "CA" ? (
                                <Text textStyle="InterRegularBodySmall">{t("savedFiatAccount.text")}</Text>
                            ) : (
                                <Text>
                                    <Text>
                                        <Text as="span" textStyle="InterRegularBodySmall">
                                            {t("savedFiatAccount.textUS")}
                                        </Text>
                                        <InlineTextLink textStyle="InterRegularBodySmall" onClick={handleSupportClick}>
                                            {t("savedFiatAccount.textUS2")}
                                        </InlineTextLink>
                                        <Text as="span" textStyle="InterRegularBodySmall">
                                            .
                                        </Text>
                                    </Text>
                                </Text>
                            )}
                        </Flex>

                        <Flex flex={1} />
                    </Flex>
                </Flex>

                <Box my={8} borderBottomWidth={1} />

                <FiatTable />
            </Box>

            <DashboardFooter />
        </ScreenContainer>
    )
}
