import { Box, Text, Flex, Switch } from "@chakra-ui/react"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import DashboardFooter from "@components/footer/dashboard"
import NotificationsTable from "@components/settings/notifications/notificationsTable"
import useRestrictedNavigation, { RESTRICTED_FLOWS } from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import ScreenContainer from "@components/ui/containers/screen"
import {
    useDeletePriceAlertMutation,
    useGetNotificationDetailsQuery,
    useGetPriceAlertsQuery,
    useSubscribeNotificationMutation,
} from "@redux/account/apiSlice"
import { NotificationChannels, NotificationTypes, PriceAlert } from "@redux/account/types"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { Option } from "@components/ui/types"
import AddEditPriceAlertDrawer from "./drawerAddEditPriceAlert"
import useRegion from "@hooks/useRegion"
import { useNavigate } from "react-router-dom"
import useGenericToast from "@hooks/useGenericToast"

export default function PriceAlertsPage(): JSX.Element {
    const { t } = useTranslation(["settings", "common"])
    const navigate = useNavigate()
    const { evaluateRestriction } = useRestrictedNavigation()
    const [priceAlertDeleting, setPriceAlertDeleting] = useState<boolean>(false)
    const [addEditPriceAlertDrawer, toggleAddEditPriceAlertDrawer] = useState(false)
    const [selectedPriceAlert, setSelectedPriceAlert] = useState<PriceAlert | null>(null)

    const { data, isLoading } = useGetNotificationDetailsQuery()
    const [subscribeNotifications] = useSubscribeNotificationMutation()
    const { data: priceAlertsData, isLoading: isPriceAlertsLoading } = useGetPriceAlertsQuery()
    const [assetFilter, setAssetFilter] = useState<Option<string> | null>(null)
    const [deletePriceAlert] = useDeletePriceAlertMutation()

    const { isCAUser } = useRegion()

    const { errorToast } = useGenericToast()

    const { data: assetDetails } = useGetAssetsDetailsQuery(undefined)

    const [visiblePriceAlerts, setVisiblePriceAlerts] = useState(priceAlertsData?.price_alerts || [])

    const handleRestriction = (callback: () => void): void => {
        evaluateRestriction(RESTRICTED_FLOWS.NOTIFICATIONS, callback, { blockWithModal: true })
    }

    useEffect(() => {
        if (priceAlertsData?.price_alerts && !priceAlertDeleting) {
            setVisiblePriceAlerts(priceAlertsData.price_alerts)
        }
    }, [priceAlertsData])

    const filteredPriceAlerts = useMemo(() => {
        return visiblePriceAlerts.filter((alert) => {
            if (!assetFilter) return true

            return alert.coin === assetFilter.value
        })
    }, [visiblePriceAlerts, assetFilter])

    const [toggleUpdating, setToggleUpdating] = useState<boolean>(false)

    const priceAlerts = useMemo(() => data?.price_alert, [data])

    const [method, setMethod] = useState({
        sms: priceAlerts?.sms ? true : false,
        email: priceAlerts?.email ? true : false,
    })

    useEffect(() => {
        if (isLoading || toggleUpdating) return

        if (priceAlerts) {
            setMethod({
                sms: priceAlerts.sms ? true : false,
                email: priceAlerts.email ? true : false,
            })
        }
    }, [priceAlerts])

    const toggleMethod = async (name: "sms" | "email") => {
        setToggleUpdating(true)

        const previousState = { ...method }

        const newState = { ...method, [name]: !method[name] }
        setMethod(newState)

        let flagsToSend = Object.keys(newState).filter(
            (key) => newState[key as keyof typeof newState] === true
        ) as NotificationChannels[]

        //Push doesn't change through web updates
        if (priceAlerts?.push) {
            flagsToSend.push(NotificationChannels.PUSH)
        }

        try {
            await subscribeNotifications({
                type: NotificationTypes.PRICE_ALERT,
                flags: flagsToSend,
            }).unwrap()
        } catch (error) {
            errorToast(t("common:error.server"), t("notifications.notificationChannelError"))
            setMethod(previousState)
        } finally {
            setToggleUpdating(false)
        }
    }

    const handlePriceAlertDelete = async (id: number) => {
        handleRestriction(async () => {
            const previousAlerts = [...visiblePriceAlerts]
            setVisiblePriceAlerts((alerts) => alerts.filter((alert) => alert.id !== id))
            setPriceAlertDeleting(true)
            try {
                await deletePriceAlert({
                    id,
                }).unwrap()
            } catch (error: any) {
                const message = error?.data?.message || t("common:error.serverDesc")
                errorToast(t("common:error.server"), message)
                setVisiblePriceAlerts(previousAlerts)
            } finally {
                setPriceAlertDeleting(false)
            }
        })
    }

    const addPriceAlert = () => {
        handleRestriction(() => {
            setSelectedPriceAlert(null)
            toggleAddEditPriceAlertDrawer(true)
        })
    }

    const editPriceAlert = (priceAlert: PriceAlert) => {
        handleRestriction(() => {
            setSelectedPriceAlert(priceAlert)
            toggleAddEditPriceAlertDrawer(true)
        })
    }

    return (
        <>
            <ScreenContainer>
                <Box mb="2rem">
                    <Flex flexDir="column" gap={2}>
                        <BreadCrumbGroup
                            breadCrumbs={[
                                {
                                    text: t("notifications.settings"),
                                    hasAfter: true,
                                    onClick: () => navigate(ROUTES.DASHBOARD_SETTINGS),
                                },
                                {
                                    text: t("notifications.title"),
                                    hasAfter: false,
                                },
                            ]}
                            py={2}
                        />

                        <Flex justify="start" flexDir={{ base: "column", md: "row" }} w="100%" gap={6}>
                            <Flex flex={1} flexDir="column" gap={2}>
                                <Text textStyle="ManropeMedium4xLarge">{t("notifications.title")}</Text>
                                <Text textStyle="InterRegularBodySmall">{t("notifications.text")}</Text>
                            </Flex>

                            <Flex flex={1} />
                        </Flex>
                    </Flex>

                    <Box my={8} borderBottomWidth={1} />

                    <Flex
                        flexDir="column"
                        w="100%"
                        maxWidth={{ md: "605px" }}
                        mx={{ md: "auto" }}
                        alignItems={{ md: "center" }}
                    >
                        <Flex flexDir="column" gap={6}>
                            <Flex flexDir="column" gap={2}>
                                <Text textStyle="ManropeSemiboldBody">{t("notifications.notificationMethod")}</Text>
                                <Text textStyle="InterRegularBodySmall">{t("notifications.selectFromEmailOrSMS")}</Text>
                            </Flex>

                            <Flex flexDir="column">
                                {isCAUser && (
                                    <Flex w="100%" justifyContent="space-between" alignItems="center" py="7px">
                                        <Text textStyle="ManropeMediumBodySmall">{t("notifications.SMS")}</Text>
                                        <Switch
                                            isChecked={method.sms}
                                            onChange={() => handleRestriction(() => toggleMethod("sms"))}
                                        />
                                    </Flex>
                                )}

                                <Flex w="100%" justifyContent="space-between" alignItems="center" py="7px">
                                    <Text textStyle="ManropeMediumBodySmall">{t("notifications.Email")}</Text>
                                    <Switch
                                        isChecked={method.email}
                                        onChange={() => handleRestriction(() => toggleMethod("email"))}
                                    />
                                </Flex>
                            </Flex>

                            <Box borderBottomWidth={1} />

                            <Flex flexDir="column" gap={2}>
                                <Text textStyle="ManropeSemiboldBody">{t("notifications.myAlerts")}</Text>
                                <Text textStyle="InterRegularBodySmall">{t("notifications.managePriceAlerts")}</Text>
                            </Flex>

                            <NotificationsTable
                                priceAlerts={filteredPriceAlerts || []}
                                assets={assetDetails || {}}
                                handleFilter={setAssetFilter}
                                onDeleteClick={handlePriceAlertDelete}
                                addPriceAlert={addPriceAlert}
                                editPriceAlert={editPriceAlert}
                            />
                        </Flex>
                    </Flex>
                </Box>
                <DashboardFooter />
            </ScreenContainer>
            {addEditPriceAlertDrawer && (
                <AddEditPriceAlertDrawer
                    isOpen={addEditPriceAlertDrawer}
                    toggleSideDrawerOpen={() => toggleAddEditPriceAlertDrawer(false)}
                    priceAlert={selectedPriceAlert}
                />
            )}
        </>
    )
}
