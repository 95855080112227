export const IS_PROD = process.env.APP_ENV === "production"
export const POLLING_INTERVAL = 30000

export const TERMS_OF_SERVICE_URL_CA = "/hosted-page/terms-of-service?c=CA"
export const TERMS_OF_SERVICE_URL_US = "/hosted-page/terms-of-service?c=US"
export const PRIVACY_POLICY_URL_CA = "/hosted-page/privacy-policy?c=CA"
export const PRIVACY_POLICY_URL_US = "/hosted-page/privacy-policy?c=US"

export const ZENDESK_CA_LINKS = {
    ROOT: "https://netcoins.zendesk.com/hc/en-us",
    ACQUISITION_LIMITS: "https://netcoins.zendesk.com/hc/en-us/articles/31902817278484-What-are-Net-Acquisition-Limits",
}
