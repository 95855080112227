import login from "./login.json"
import passwordRecovery from "./passwordRecovery.json"
import emailAuthentication from "./emailAuthentication.json"
import signUp from "./signUp.json"
import createNewPassword from "./createNewPassword.json"

export default <const>{
    login,
    signUp,
    passwordRecovery,
    emailAuthentication,
    createNewPassword,
}
