import i18n from "i18next"
import locales from "./locales"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { IS_PROD } from "@util/config"

i18n
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "en", // switch out with languageDetector when available
        fallbackLng: "en",
        resources: locales,
        debug: !IS_PROD,
        interpolation: {
            escapeValue: false, // not needed for react as it does escape per default to prevent xss!
        },
    })

export default i18n
