import { Box, Text, Flex, Icon, Image, Center, Spinner } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import NetcoinsPayTransactionsTable from "@components/netcoinsPay/netcoinsPayTransactionsTable"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useTranslation } from "react-i18next"
import { FaAngleRight } from "react-icons/fa"
import { IoIosAdd, IoIosInformationCircleOutline } from "react-icons/io"
import BitcoinImage from "@assets/svgs/coins/btc.svg"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { MdUnfoldMore } from "react-icons/md"
import IconButton from "@components/ui/buttons/icon"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { IoMdAdd } from "react-icons/io"
import NetcoinsPayCard from "@components/netcoinsPay/netcoinsPayCard"
import { useMemo, useState } from "react"
import TooltipComp from "@components/tooltip"
import ScreenContainer from "@components/ui/containers/screen"
import {
    useGetNPayOverViewQuery,
    useNPayTransactionsQuery,
    useToggleCardLockMutation,
    useViewCardMutation,
} from "@redux/netcoinsPay/apiSlice"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { formatDollar, removeTrailingZeros } from "@util/stringFormatting"
import useGenericToast from "@hooks/useGenericToast"
import AddToWallet from "./addToWallet"
import FundDrawerHOC from "../transfer/drawerFund"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "@routing/routes"
import NetcoinsUnregisteredUserPage from "./unregistered"

export default function NetcoinsPayPage() {
    const { t } = useTranslation(["app", "common"])

    const navigate = useNavigate()

    const userCurrency = useAppSelector(selectAccountDetails)?.currency
    const [rewardsDisplay, setRewardsDisplay] = useState<"crypto" | "fiat">("crypto")

    const toggleCurrencyPosition = () => {
        setRewardsDisplay(rewardsDisplay === "crypto" ? "fiat" : "crypto")
    }

    const [isWalletInstOpen, setWalletInstOpen] = useState(false)
    const [fundingDrawerOpen, setFundingDrawerOpen] = useState(false)

    const { isLoading: isNPayDataLoading, data } = useGetNPayOverViewQuery()
    const [toggleCardLock] = useToggleCardLockMutation()
    const [viewCard] = useViewCardMutation()
    const { isLoading: isNPayTransactionsLoading, data: transactionsData } = useNPayTransactionsQuery({
        page: 1,
        limit: 5,
        sort: "desc",
    })

    const { errorToast } = useGenericToast()

    const { balance, rewards, cards } = data || {}

    const mainRewardsValue = useMemo(() => {
        if (rewardsDisplay === "crypto") {
            return `${removeTrailingZeros(rewards?.earned.crypto || "")} BTC`
        } else {
            return `${formatDollar(rewards?.earned.fiat || "")}`
        }
    }, [rewards, rewardsDisplay])

    const subRewardsValue = useMemo(() => {
        if (rewardsDisplay === "crypto") {
            return `${formatDollar(rewards?.earned.fiat || "")}`
        } else {
            return `${removeTrailingZeros(rewards?.earned.crypto || "")} BTC`
        }
    }, [rewards, rewardsDisplay])

    const physicalCard = useMemo(() => cards?.["mc-physical"], [cards])
    const virtualCard = useMemo(() => cards?.["mc-virtual"], [cards])

    const [virtualCardLocked, setVirtualCardLocked] = useState(virtualCard?.isLocked ? true : false)
    const [physicalCardLocked, setPhysicalCardLocked] = useState(physicalCard?.isLocked ? true : false)

    const [virtualImageViewSrc, setVirtualImageViewSrc] = useState("")
    const [physicalImageViewSrc, setPhysicalImageViewSrc] = useState("")
    const [virtualCardImgLoading, setVirtualCardImgLoading] = useState(false)
    const [physicalCardImgLoading, setPhysicalCardImgLoading] = useState(false)

    const handleCardLockToggle = async (cardType: "virtual" | "physical") => {
        const cardId = cardType === "virtual" ? virtualCard?.cardId : physicalCard?.cardId
        if (!cardId) return

        const previousVirtualCardLocked = virtualCardLocked
        const previousPhysicalCardLocked = physicalCardLocked

        if (cardType === "virtual") {
            setVirtualCardLocked(!virtualCardLocked)
        } else {
            setPhysicalCardLocked(!physicalCardLocked)
        }

        try {
            await toggleCardLock({ id: cardId }).unwrap()
        } catch (error: any) {
            const message = error.data?.message || t("common:error.genericServerError")
            errorToast(t("common:error.server"), message)
            if (cardType === "virtual") {
                setVirtualCardLocked(previousVirtualCardLocked)
            } else {
                setPhysicalCardLocked(!previousPhysicalCardLocked)
            }
        }
    }

    const handleViewCard = async (cardType: "virtual" | "physical") => {
        const isVirtual = cardType === "virtual"

        const cardId = isVirtual ? virtualCard?.cardId : physicalCard?.cardId
        if (!cardId) return

        const setImageViewSrc = isVirtual ? setVirtualImageViewSrc : setPhysicalImageViewSrc
        const setImgLoading = isVirtual ? setVirtualCardImgLoading : setPhysicalCardImgLoading
        const imageViewSrc = isVirtual ? virtualImageViewSrc : physicalImageViewSrc

        if (imageViewSrc) {
            setImageViewSrc("")
            return
        }

        setVirtualImageViewSrc("")
        setPhysicalImageViewSrc("")

        setImgLoading(true)

        try {
            const res = await viewCard({ id: cardId }).unwrap()
            setImageViewSrc(res?.imgSrc)
        } catch (error: any) {
            const message = error.data?.message || t("common:error.genericServerError")
            errorToast(t("common:error.server"), message)
        } finally {
            setImgLoading(false)
        }
    }

    const handleWallInstOpen = () => {
        setWalletInstOpen(true)
    }

    if (isNPayDataLoading) {
        return (
            <Center height="auto" width={"100%"}>
                <Spinner size="xl" />
            </Center>
        )
    }

    return (
        <ScreenContainer>
            <Flex
                gap={{ base: 6, md: 7, lg: 6, xl: 7 }}
                flexDir="column"
                w="full"
                maxWidth={{ md: "811px" }}
                mx={{ md: "auto" }}
                mb={"3rem"}
            >
                <Flex flexDir="column">
                    <Text textStyle="ManropeSemiboldLarge">{t("netcoinsPay.myBalance")}</Text>

                    <Flex gap={{ base: 6, xl: 8 }} flexDir={{ base: "column", md: "row" }}>
                        <Flex flexDir="column" flex="1" gap={2}>
                            <Box display={"flex"} height={"60px"} alignItems={"center"}>
                                <Flex alignItems="flex-end">
                                    <Text
                                        textStyle="ManropeMedium5xLarge"
                                        lineHeight={"none"}
                                    >{`${formatDollar(balance?.spendable || "")}`}</Text>
                                    <Text textStyle={"ManropeSemibold2xLarge"} lineHeight={"none"} mb={"3px"}>
                                        &nbsp;{userCurrency}
                                    </Text>
                                </Flex>
                            </Box>

                            <StandardButton
                                variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                                size="md"
                                width="fit-content"
                                onClick={() => setFundingDrawerOpen(true)}
                                leftIcon={IoIosAdd}
                            >
                                {t("netcoinsPay.addFunds")}
                            </StandardButton>
                        </Flex>

                        <Flex borderColor="grey.light.10" flex="1">
                            <Flex
                                borderWidth="1px"
                                borderRadius="6px"
                                p={4}
                                w="100%"
                                justifyContent={"space-between"}
                                gap={{ sm: 3 }}
                                alignItems="center"
                            >
                                <Flex flexDir="column">
                                    <Flex gap={2} alignItems="center">
                                        <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.80">
                                            {t("netcoinsPay.totalRewardsEarned")}
                                        </Text>

                                        <TooltipComp
                                            bodyText={t("netcoinsPay.totalRewardsEarnedTooltip")}
                                            triggerComponent={
                                                <ReactIcon
                                                    icon={IoIosInformationCircleOutline}
                                                    size={18}
                                                    color="blue.100"
                                                />
                                            }
                                        />
                                    </Flex>

                                    <Flex alignItems="center">
                                        <Text textStyle={{ base: "ManropeSemiboldBody", md: "ManropeSemibold2xLarge" }}>
                                            {mainRewardsValue}
                                        </Text>

                                        <IconButton
                                            icon={MdUnfoldMore}
                                            iconOutline={MdUnfoldMore}
                                            name="unfold"
                                            variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_BLUE_GHOST}
                                            onClick={toggleCurrencyPosition}
                                        />
                                    </Flex>

                                    <Text textStyle="ManropeMediumBodySmall">{subRewardsValue}</Text>
                                </Flex>

                                <Image
                                    src={BitcoinImage}
                                    alt={"bitcoin"}
                                    height={{ base: "48px", sm: "62px" }}
                                    width={{ base: "48px", sm: "62px" }}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Box borderBottomWidth={1} />

                <Flex gap={{ base: 6, xl: 8 }} flexDir={{ base: "column", sm: "row" }}>
                    <NetcoinsPayCard
                        type="virtual"
                        status="active"
                        isRegistered={virtualCard ? true : false}
                        lastFourDigits={virtualCard?.lastFourDigits}
                        isLocked={virtualCardLocked}
                        handleCardLockToggle={handleCardLockToggle}
                        handleViewCard={handleViewCard}
                        cardDetailsImgSrc={virtualImageViewSrc}
                        cardImgLoading={virtualCardImgLoading}
                        openWalletInstModal={handleWallInstOpen}
                    />
                    <NetcoinsPayCard
                        type="physical"
                        status="active"
                        isRegistered={physicalCard ? true : false}
                        lastFourDigits={physicalCard?.lastFourDigits}
                        isLocked={physicalCardLocked}
                        handleCardLockToggle={handleCardLockToggle}
                        handleViewCard={handleViewCard}
                        cardDetailsImgSrc={physicalImageViewSrc}
                        cardImgLoading={physicalCardImgLoading}
                        openWalletInstModal={handleWallInstOpen}
                    />
                </Flex>

                <Flex flexDir="column" w="full">
                    <Flex gap="10px" justifyContent="space-between" alignItems="center" w="full" py={4}>
                        <Text textStyle="ManropeSemiboldLarge">{t("netcoinsPay.netcoinsPayTransactions")}</Text>

                        <Flex
                            alignItems="center"
                            justifyContent="flex-end"
                            cursor="pointer"
                            gap={{
                                base: 1,
                                sm: 3,
                            }}
                            ml={{ base: "auto", md: "unset" }}
                            flex="0 0 auto"
                            whiteSpace="nowrap"
                            sx={{
                                "&:hover": {
                                    textDecorationColor: "blue.100 !important",
                                    textDecoration: "underline",
                                },
                                "&:active": {
                                    textDecorationColor: "blue.70 !important",
                                    textDecoration: "underline",
                                },
                            }}
                            onClick={() => navigate(ROUTES.NPAY_TRANSACTIONS)}
                        >
                            <Text color={"blue.100"} textStyle="ManropeSemiboldBodySmall">
                                {t("netcoinsPay.viewAll")}
                            </Text>
                            <Icon as={FaAngleRight} size={16} color="blue.100" />
                        </Flex>
                    </Flex>

                    <NetcoinsPayTransactionsTable data={transactionsData?.transactions} />
                </Flex>
            </Flex>

            <DashboardFooter />
            <AddToWallet isOpen={isWalletInstOpen} setIsOpen={setWalletInstOpen} />
            <FundDrawerHOC isOpen={fundingDrawerOpen} toggleSideDrawerOpen={() => setFundingDrawerOpen(false)} />
        </ScreenContainer>
    )
}
