import { Box, Flex, Text } from "@chakra-ui/react"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { MdClose } from "react-icons/md"
import { IconType } from "react-icons"

type Props = {
    bgColor: string
    color: string
    title: string
    desc?: string
    onClose?: () => void
    icon: IconType
}

export default function Toasts({ bgColor, color, title, desc, onClose, icon }: Props) {
    return (
        <Flex p={4} gap={3} m="0 2rem 2rem 2rem" bg={bgColor} borderRadius={4}>
            <ReactIcon icon={icon} size={24} color={color} />
            <Box flex={1}>
                <Text textStyle="ManropeSemiboldBody" color={color}>
                    {title}
                </Text>
                {desc && (
                    <Text textStyle="InterRegularBodySmall" color={color}>
                        {desc}
                    </Text>
                )}
            </Box>
            {onClose && (
                <Box cursor="pointer" onClick={onClose}>
                    <ReactIcon icon={MdClose} size={24} color={color} />
                </Box>
            )}
        </Flex>
    )
}
