import { Box, Flex, Text } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import AccountActivityTable from "@components/settings/accountActivity/accountActivityTable"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import ScreenContainer from "@components/ui/containers/screen"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { useState } from "react"
import { useTranslation } from "react-i18next"

type Props = {}

export type TActivity = {
    message: string
    created_at: string // datetime
    session: {
        id: string
    }
}

export type TSession = {
    id: string
    user_agent: string
}

export default function AccountActivity({}: Props) {
    const { t } = useTranslation("settings")
    const { navigate } = useRestrictedNavigation()

    return (
        <ScreenContainer>
            <Flex flexDir="column" flex={1}>
                <Flex flexDir="column" gap={2}>
                    <BreadCrumbGroup
                        breadCrumbs={[
                            {
                                text: t("activity.settings"),
                                hasAfter: true,
                                onClick: () => navigate(ROUTES.DASHBOARD_SETTINGS),
                            },
                            {
                                text: t("activity.accountActivity"),
                                hasAfter: false,
                            },
                        ]}
                        py={2}
                    />

                    <Flex justify="start" flexDir={{ base: "column", md: "row" }} w="100%" gap={{ md: 6 }}>
                        <Flex flex={1} flexDir="column" gap={2}>
                            <Text textStyle="ManropeMedium4xLarge">{t("activity.title")}</Text>
                            <Text textStyle="InterRegularBodySmall">{t("activity.text")}</Text>
                        </Flex>

                        <Flex flex={1} />
                    </Flex>
                </Flex>

                <Box my={8} borderBottomWidth={1} />

                <AccountActivityTable />
            </Flex>

            <DashboardFooter />
        </ScreenContainer>
    )
}
