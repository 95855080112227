import { Table, Tbody, Th, Thead, Tr, Text, Flex, Box, TableContainer, Spinner } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import DocumentRow from "../documentRow"
import { Statement } from "@redux/statements/types"
import { useAppSelector } from "@/store/hooks"
import { useDownloadStatementMutation } from "@redux/statements/apiSlice"
import { useState } from "react"
import useGenericToast from "@hooks/useGenericToast"

type Props = {
    statements: Statement[]
    year: string
    isLoading: boolean
}

export default function AccountStatements({ statements, year, isLoading }: Props) {
    const { t } = useTranslation("settings")
    const user = useAppSelector((state) => state.auth.user)
    const { errorToast } = useGenericToast()
    const [downloadPdf] = useDownloadStatementMutation()

    const [arrDownloading, setArrDownloading] = useState<string[]>([])

    async function openStatementWindow(url: string) {
        try {
            setArrDownloading((prev) => [...prev, url])
            const res = await downloadPdf(url).unwrap()

            const link = document.createElement("a")
            link.href = res.fileUrl
            link.target = "_blank"
            link.click()

            // Clean up the blob URL after a short delay
            setTimeout(() => {
                URL.revokeObjectURL(res.fileUrl)
            }, 100)
        } catch (e) {
            errorToast(t("documents.error.download"))
        } finally {
            setArrDownloading((prev) => prev.filter((item) => item !== url))
        }
    }

    return (
        <Flex flexDir="column" flexGrow={1}>
            <TableContainer flexGrow={1} overflowY={"unset"} overflowX="unset">
                <Table layout="fixed" sx={{ borderCollapse: "collapse" }} w="100%">
                    <Thead bg="grey.light.5">
                        <Tr px={2} py={"6px"} w="full">
                            <Th px={2} display="flex" alignItems="center">
                                <Text color="grey.light.90" textStyle="ManropeSemiboldXSmall" textTransform="none">
                                    {t("documents.statementDate")}
                                </Text>
                            </Th>
                            <Th px={2} />
                        </Tr>
                    </Thead>

                    <Tbody w="full">
                        {!!statements?.length &&
                            statements.map((statement, index) => (
                                <DocumentRow
                                    key={index}
                                    statement={statement}
                                    year={year}
                                    onClick={openStatementWindow}
                                    isDownloading={arrDownloading.includes(statement.download_path)}
                                    isAnyDownloading={arrDownloading.length > 0}
                                />
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>
            {(!statements?.length || isLoading) && (
                <Flex flexGrow={1} align="center" justify="center" minHeight="300px">
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                            {t("documents.noDocs")}
                        </Text>
                    )}
                </Flex>
            )}
        </Flex>
    )
}
