import { imageMap } from "@assets/svgs/coins"
import { RadioSelectOption } from "@components/ui/radio/radioSelect"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { createContainer } from "@util/UnstatedContext"
import { useEffect, useMemo, useState } from "react"
import { FundType } from "."

type InitialStateType = {
    fundType?: FundType
    cryptoDisclaimers: {
        disclaimer1: boolean
        disclaimer2: boolean
        disclaimer3: boolean
    }
    interacDisclaimers: {
        disclaimer1: boolean
        disclaimer2: boolean
        disclaimer3: boolean
    }
    caWireDisclaimers: {
        disclaimer1: boolean
        disclaimer2: boolean
        disclaimer3: boolean
        disclaimer4: boolean
    }
    usWireDisclaimers: {
        disclaimer1: boolean
        disclaimer2: boolean
        disclaimer3: boolean
    }
}
export const InitialState: InitialStateType = {
    cryptoDisclaimers: {
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
    },
    interacDisclaimers: {
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
    },
    caWireDisclaimers: {
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
        disclaimer4: false,
    },
    usWireDisclaimers: {
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
    },
}

const useDrawerFundContext = ({
    fundType,
    cryptoDisclaimers: cryptoDisclaimersInitial,
    interacDisclaimers: interacDisclaimersInitial,
    caWireDisclaimers: caWireDisclaimersInitial,
    usWireDisclaimers: usWireDisclaimersInitial,
}: InitialStateType) => {
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [currentFundType, setCurrentFundType] = useState<FundType | undefined>(fundType)
    const [selectedCoin, setSelectedCoin] = useState<RadioSelectOption | null>(null)

    const [cryptoDisclaimers, setCryptoDisclaimers] = useState(cryptoDisclaimersInitial)
    const [interacDisclaimers, setInteracDisclaimers] = useState(interacDisclaimersInitial)
    const [caWireDisclaimers, setCAWireDisclaimers] = useState(caWireDisclaimersInitial)
    const [usWireDisclaimers, setUSWireDisclaimers] = useState(usWireDisclaimersInitial)

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const assetDetailsAsArray = useMemo(() => Object.values(assetDetailsData || {}), [assetDetailsData])

    const filteredAssets = useMemo(
        () => assetDetailsAsArray.filter((asset) => asset.restrictions?.deposit === true),
        [assetDetailsAsArray]
    )

    const coinRadioOptions = useMemo(
        () =>
            filteredAssets.map((asset) => ({
                name: asset.name,
                symbol: asset.symbol,
                imageUrl: imageMap[asset.symbol.toLowerCase() as keyof typeof imageMap] || imageMap["btc"],
            })),
        [filteredAssets]
    )

    const selectedCoinData = useMemo(() => {
        if (!selectedCoin) return null
        return assetDetailsAsArray.find((asset) => asset.symbol === selectedCoin?.symbol)
    }, [selectedCoin])

    const resetState = () => {
        setCryptoDisclaimers(cryptoDisclaimersInitial)
        setInteracDisclaimers(interacDisclaimersInitial)
        setCAWireDisclaimers(caWireDisclaimersInitial)
        setUSWireDisclaimers(usWireDisclaimersInitial)
        setCurrentFundType(fundType)
        setSelectedCoin(null)
        setErrorMessage("")
    }

    useEffect(() => {
        if (!selectedCoin) {
            const btcOption = coinRadioOptions.find((option) => option.symbol === "BTC")
            setSelectedCoin(btcOption || null)
        }
    }, [coinRadioOptions])

    return {
        coinRadioOptions,
        currentFundType,
        setCurrentFundType,
        cryptoDisclaimers,
        setCryptoDisclaimers,
        selectedCoin,
        setSelectedCoin,

        interacDisclaimers,
        setInteracDisclaimers,

        caWireDisclaimers,
        setCAWireDisclaimers,

        errorMessage,
        setErrorMessage,
        selectedCoinData,

        usWireDisclaimers,
        setUSWireDisclaimers,
        resetState,
    }
}

export const DrawerFundContext = createContainer(useDrawerFundContext)
