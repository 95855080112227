import { Box, Button, Flex, Text } from "@chakra-ui/react"
import { MdArrowBack, MdClose } from "react-icons/md"

type StandardDrawerHeaderProps = {
    title?: string
    onClose?: () => void
    onBack?: () => void
}

export default function StandardDrawerHeader({ title, onClose, onBack }: StandardDrawerHeaderProps) {
    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                {onBack ? (
                    <Button
                        p={0}
                        background={"none"}
                        onClick={onBack}
                        m={0}
                        _hover={{ background: "none" }}
                        _active={{ background: "none" }}
                        justifyContent={"flex-start"}
                        size={"xs"}
                    >
                        <MdArrowBack size={18} color="black" />
                    </Button>
                ) : (
                    <Box width="18px" />
                )}
                {onClose ? (
                    <Button
                        p={0}
                        m={0}
                        background={"none"}
                        onClick={onClose}
                        justifyContent={"flex-end"}
                        _hover={{ background: "none" }}
                        _active={{ background: "none" }}
                        size={"xs"}
                    >
                        <MdClose size={18} color="black" />
                    </Button>
                ) : (
                    <Box width="18px" />
                )}
            </Box>
            {title && (
                <Flex mt={6} h="56px" align="center">
                    {title && <Text textStyle={"ManropeSemiboldXLarge"}>{title}</Text>}
                </Flex>
            )}
        </Box>
    )
}
