import { Box } from "@chakra-ui/react"
import HeaderHostedPage from "@components/header/hostedPage"
import HeaderDashboard, { HEADER_HEIGHT } from "components/header/dashboard"
import { Outlet } from "react-router-dom"

export default function HostedPage() {
    return (
        <Box h="100vh" display="flex" flexDir="column">
            <HeaderHostedPage />
            <Box display="flex" height={`calc(100vh - ${HEADER_HEIGHT}px)`} pos="relative">
                <Outlet />
            </Box>
        </Box>
    )
}
