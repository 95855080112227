import { Box, Button, Flex, Icon, Menu, MenuButton, MenuList, Text, Tooltip, VStack } from "@chakra-ui/react"
import { TextStyles } from "@theme/textStyles"
import { MouseEvent, useEffect, useState } from "react"
import StandardButton from "../../buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "../../buttons/standard/types"
import { Option } from "../../types"
import OptionCheckBox from "../../filterOptions/checkBox"
import { MdChevronRight, MdClose } from "react-icons/md"
import Searchbar from "../../searchbar"
import { useTranslation } from "react-i18next"

interface MultiSelectProps<T extends string | number> {
    options: Option<T>[]
    selectedOptions: Option<T>[]
    setSelectedOptions: (options: Option<T>[]) => void
    placeholder?: string
    search?: boolean
    onSaveClick?: (selectedOptions: Option<T>[]) => void
}

const MultiSelect = <T extends string | number>({
    search,
    options,
    selectedOptions,
    setSelectedOptions,
    placeholder = "Filter Category",
    onSaveClick,
}: MultiSelectProps<T>) => {
    const { t } = useTranslation("common")
    const [temporarySelection, setTemporarySelection] = useState<Option<T>[]>([])
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [isOpen, setIsOpen] = useState(false)

    const handleOptionToggle = (option: Option<T>) => {
        setTemporarySelection((prev) =>
            prev.some((item) => item.value === option.value)
                ? prev.filter((item) => item.value !== option.value)
                : [...prev, option]
        )
    }

    useEffect(() => {
        setTemporarySelection(selectedOptions)
    }, [selectedOptions])

    const handleCancel = () => {
        setTemporarySelection(selectedOptions)
        setIsOpen(false)
    }

    const handleSave = () => {
        setSelectedOptions(temporarySelection)
        setIsOpen(false)
        if (onSaveClick) {
            onSaveClick(temporarySelection)
        }
    }

    const handleClear = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        e.stopPropagation()
        setSelectedOptions([])
        setIsOpen(false)
    }

    const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))

    const displayText = (): string => {
        if (selectedOptions.length === 0) {
            return placeholder
        } else if (selectedOptions.length <= 3) {
            return selectedOptions.map((option) => option.label).join(", ")
        } else {
            return `${selectedOptions.length} items selected`
        }
    }

    return (
        <Box maxWidth="300px">
            <Menu isOpen={isOpen} onClose={() => handleCancel()}>
                <MenuButton
                    as={Button}
                    textStyle={"ManropeSemiboldBody"}
                    fontWeight={500}
                    rightIcon={
                        <MdChevronRight
                            style={{ transform: `rotate(${isOpen ? 270 : 90}deg)` }}
                            color="black"
                            size={18}
                        />
                    }
                    width="100%"
                    textAlign="left"
                    variant="outline"
                    borderColor={"grey.light.10"}
                    onClick={() => setIsOpen(!isOpen)}
                    _active={{ bg: "grey.light.5 !important" }}
                    _hover={{ bg: "unset", bgColor: "unset" }}
                    sx={{
                        "& > span": {
                            display: "inline-block",
                            maxWidth: "calc(100% - 24px)",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        },
                    }}
                >
                    <Flex align="center" justifyContent={"space-between"} alignItems={"center"}>
                        <Text textStyle={"ManropeMediumBodySmall"} lineHeight={"unset"}>
                            {displayText()}
                        </Text>
                        {!!selectedOptions.length && (
                            <Flex onClick={handleClear} pointerEvents={"auto"} ml={2.5}>
                                <Icon size={16} as={MdClose} />
                            </Flex>
                        )}
                    </Flex>
                </MenuButton>
                <MenuList padding={0}>
                    <Box paddingBottom={3}>
                        {search && (
                            <Box py="0.75rem" mt={1}>
                                <Searchbar
                                    size="md"
                                    removeBorder
                                    value={searchTerm}
                                    placeholder={t("search")}
                                    onChange={setSearchTerm}
                                />
                            </Box>
                        )}
                        <VStack
                            width="300px"
                            align="stretch"
                            spacing={0}
                            maxHeight="200px"
                            overflowY="auto"
                            mt={search ? 0 : "0.75rem"}
                            pt={!search ? 0 : "0.75rem"}
                            borderTopWidth={search ? "1px" : 0}
                            borderTopColor="grey.light.10"
                        >
                            {filteredOptions.map((option) => (
                                <OptionCheckBox
                                    key={option.value}
                                    option={option}
                                    isChecked={temporarySelection.some((item) => item.value === option.value)}
                                    onToggle={handleOptionToggle}
                                />
                            ))}
                        </VStack>
                    </Box>
                    <Flex justify="space-between" p={3} borderTop="1px" borderColor="gray.200">
                        <StandardButton
                            variant={ENUM_BUTTON_VARIANTS.SECONDARY_GHOST}
                            type="button"
                            size="md"
                            onClick={handleCancel}
                            {...TextStyles.ManropeSemiboldBodySmall}
                        >
                            {t("cancel")}
                        </StandardButton>

                        <StandardButton
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_SOLID}
                            type="button"
                            size="md"
                            onClick={handleSave}
                            {...TextStyles.ManropeMediumBodySmall}
                        >
                            {t("save")}
                        </StandardButton>
                    </Flex>
                </MenuList>
            </Menu>
        </Box>
    )
}

export default MultiSelect
