import { Button, Flex, Icon, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { RiFilter3Fill } from "react-icons/ri"

type Props = {
    setIsOpen: () => void
    text: string
    textAlign?: "left" | "center" | "right"
}

export default function MobileFilterButton({ setIsOpen, textAlign, text }: Props) {
    const { t } = useTranslation("common")

    return (
        <Button
            onClick={setIsOpen}
            bgColor="white"
            borderRadius={"6px"}
            borderColor="grey.light.10"
            borderWidth="1px"
            _hover={{ bg: "unset", bgColor: "unset" }}
            leftIcon={<Icon as={RiFilter3Fill} color="black" height={18} width={18} mr="10px" />}
        >
            <Flex align="center" justifyContent={"space-between"} alignItems={"center"}>
                <Text textStyle={"ManropeMediumBodySmall"} noOfLines={1} textAlign={textAlign}>
                    {text}
                </Text>
            </Flex>
        </Button>
    )
}
