import { useLazyValidateAddressQuery } from "@redux/withdraw/apiSlice"
import { debounce } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"

export default function useValidateAddr() {
    const [validateAddressQuery] = useLazyValidateAddressQuery()

    const currentRequestId = useRef(0)

    const [isValidAddr, setIsValidAddr] = useState<boolean | undefined>(undefined)
    const [isValidatingAddr, setIsValidatingAddr] = useState(false)
    const [isWarningChecked, setWarningChecked] = useState(false)

    const debounceValidateAddr = useCallback(
        debounce(async (address: string, currency: string) => {
            setWarningChecked(false)
            setIsValidatingAddr(true)
            const requestId = ++currentRequestId.current // ensures that only the most recent Response is used
            try {
                const result = await validateAddressQuery({ address, currency }).unwrap()
                if (requestId === currentRequestId.current) {
                    setIsValidAddr(result?.data?.isValid ?? false)
                }
            } catch (error) {
                if (requestId === currentRequestId.current) {
                    setIsValidAddr(false)
                }
            } finally {
                if (requestId === currentRequestId.current) {
                    setIsValidatingAddr(false)
                }
            }
        }, 300),
        [validateAddressQuery]
    )

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debounceValidateAddr.cancel()
        }
    }, [debounceValidateAddr])

    return {
        isValidAddr,
        setIsValidAddr,
        isValidatingAddr,
        isWarningChecked,
        setWarningChecked,
        debounceValidateAddr,
        isValidatingFetching: isValidatingAddr,
    }
}
