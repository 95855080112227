import { Box, Text } from "@chakra-ui/layout"
import ScreenContainer from "@components/ui/containers/screen"
import { useTranslation } from "react-i18next"
import disputeResolution from "@assets/otherHostedPages/disputeResolution.html"
import HeaderHostedPage from "@components/header/hostedPage"
import { HEADER_HEIGHT } from "@components/header/dashboard"

export default function DisputeResolution() {
    const { t } = useTranslation("common")

    return (
        <Box h="100vh" display="flex" flexDir="column">
            <HeaderHostedPage />
            <Box display="flex" height={`calc(100vh - ${HEADER_HEIGHT}px)`} pos="relative">
                <ScreenContainer>
                    <Box pb={8} borderBottomWidth={1} borderBottomColor="grey.light.10">
                        <Text textStyle="ManropeMedium4xLarge">{t("disputeResolutionServices")}</Text>
                    </Box>
                    <Box dangerouslySetInnerHTML={{ __html: disputeResolution }} py={8} className="hosted-page" />
                </ScreenContainer>
            </Box>
        </Box>
    )
}
