import { appendVersion } from "@util/RTKApi"

export const STATEMENT_TAG_TYPES = {
    STATEMENTS: "STATEMENTS",
    KOHO_STATEMENTS: "KOHO_STATEMENTS",
} as const

export const API_PATH = {
    STATEMENTS: appendVersion("statements", "v3"),
    KOHO_STATEMENTS: appendVersion("koho/statements", "v3"),
} as const
