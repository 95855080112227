import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import AddCanadianBank from "@screens/dashboard/transfer/withdraw/wire/addBank/canadian"
import { useForm } from "react-hook-form"
import { AddNewBankFormData } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import useGenericToast from "@hooks/useGenericToast"
import { useCreateCABankMutation, useUpdateCABankMutation } from "@redux/withdraw/apiSlice"
import { BankDetails } from "@redux/withdraw/types"
import { useEffect, useMemo, useState } from "react"
import { Box } from "@chakra-ui/layout"
import ErrorMessage from "@components/ui/errorMessage"

export interface PutCABankProps {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    editBankFormData: BankDetails | null // will add IntlBankDetails in future
}

export default function PutCABank({ isOpen, toggleSideDrawerOpen, editBankFormData }: PutCABankProps) {
    const { t } = useTranslation(["settings", "transfer"])
    const { errorToast } = useGenericToast()
    const defaultCanadianValues = useMemo(
        () => ({
            type: { value: "canadian" as "canadian", label: t("withdraw.wire.canadian", { ns: "transfer" }) },
            bank_country: { value: "CA", label: "Canada" },
            account: "",
            nickname: "",
            bank_name: "",
            beneficiary: "",
            routing: "",
            swift: "",
            instructions: "",
            institution: "",
            transit: "",
            bank_address: "",
            bank_province: { value: "", label: "" },
            bank_zip_code: "",
            bank_city: "",
        }),
        []
    )
    const { control, getValues, watch, setValue, handleSubmit, reset } = useForm<AddNewBankFormData>({
        defaultValues: defaultCanadianValues,
    })
    const { successToast } = useGenericToast()

    const [createCADBank, { isLoading: isLoadingCreateCADBank }] = useCreateCABankMutation()
    const [updateCABank, { isLoading: isLoadingUpdateCADBank }] = useUpdateCABankMutation()

    const [err, setErr] = useState("")

    useEffect(() => {
        if (editBankFormData) {
            // currently setup to only work with Canadian users with Canadian banks
            setValue("type", { value: "canadian", label: t("withdraw.wire.canadian", { ns: "transfer" }) })
            setValue("account", editBankFormData.account_number)
            setValue("nickname", editBankFormData.title ?? "")
            setValue("bank_name", editBankFormData.bank_name)
            setValue("institution", editBankFormData.institution_number)
            setValue("transit", editBankFormData.transit_number)
            setValue("bank_address", editBankFormData.bank_address)
            setValue("bank_country", { value: editBankFormData.bank_country, label: editBankFormData.bank_country })
            setValue("bank_province", { value: editBankFormData.bank_province, label: editBankFormData.bank_province }) // TODO parse correct value
            setValue("bank_city", editBankFormData.bank_city)
            setValue("bank_zip_code", editBankFormData.bank_zip_code)
        } else {
            reset(defaultCanadianValues)
        }
    }, [editBankFormData])

    async function handleUpdateCABank(values: AddNewBankFormData, bankRegion: string) {
        await updateCABank({
            id: editBankFormData?.bank_id as number,
            body: {
                ...values,
                ...(values.type.value === "canadian"
                    ? {
                          institution_number: values.institution!,
                          transit_number: values.transit!,
                      }
                    : {
                          swift_code: values.swift!,
                          routing_number: values.routing!,
                      }),
                account_number: values.account,
                name: values.bank_name,
                title: values.nickname ?? "",
                institution: values.bank_name,
                bank_postal_code: values.bank_zip_code,
                bank_country: values.bank_country?.value,
                bank_province: bankRegion,
            },
            currency: "CAD",
        })
            .unwrap()
            .then(() => {
                successToast(t("savedFiatAccount.toastUpdated"))
                handleClose()
            })
            .catch(() => {
                // TODO show server errors
                errorToast(t("savedFiatAccount.errors.updatedBankAccount"))
            })
    }

    async function handleCreateCABank(values: AddNewBankFormData, bankRegion: string) {
        await createCADBank({
            account_number: values.account,
            ...(values.type.value === "canadian"
                ? {
                      institution_number: values.institution!,
                      transit_number: values.transit!,
                  }
                : {
                      swift_code: values.swift!,
                      routing_number: values.routing!,
                  }),
            nickname: values.nickname ?? "",
            bank_name: values.bank_name,
            bank_address: values.bank_address,
            bank_city: values.bank_city,
            bank_zip_code: values.bank_zip_code,
            bank_country: values.bank_country?.value as string,
            bank_province: bankRegion,
            currency: "CAD",
        })
            .unwrap()
            .then(() => {
                successToast(t("savedFiatAccount.toastCreated"))
                handleClose()
            })
            .catch((e) => {
                // TODO show server errors
                setErr(t("savedFiatAccount.errors.updatedBankAccount"))
            })
    }

    async function submitForm(values: AddNewBankFormData) {
        const regionData = values?.bank_province?.value.split("-")
        const bankRegion = regionData && regionData.length > 1 ? regionData[1] : ""
        editBankFormData ? handleUpdateCABank(values, bankRegion) : handleCreateCABank(values, bankRegion)
    }

    function handleClose() {
        setErr("")
        reset(defaultCanadianValues)
        toggleSideDrawerOpen()
    }

    return (
        <SideDrawer
            isOpen={isOpen}
            toggleSideDrawerOpen={handleClose}
            header={
                <StandardDrawerHeader
                    title={t(editBankFormData ? "savedFiatAccount.editBankTitle" : "savedFiatAccount.addNewBankTitle")}
                    onClose={handleClose}
                />
            }
            extraFooter={
                err && (
                    <Box mb="10px">
                        <ErrorMessage>{err}</ErrorMessage>
                    </Box>
                )
            }
            footerButton={{
                children: t(editBankFormData ? "savedFiatAccount.saveChanges" : "savedFiatAccount.addBank"),
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                type: "submit",
                form: "put-cad-bank",
                isLoading: isLoadingCreateCADBank || isLoadingUpdateCADBank,
            }}
        >
            <form id="put-cad-bank" onSubmit={handleSubmit(submitForm)} style={{ marginTop: "1.5rem" }}>
                <AddCanadianBank
                    control={control}
                    getValues={getValues}
                    watch={watch}
                    setValues={setValue}
                    isCanadianForced
                />
            </form>
        </SideDrawer>
    )
}
