import { Box, Flex, Text } from "@chakra-ui/react"
import { ChangePassFormData } from "."
import { Control, UseFormWatch } from "react-hook-form"
import TextInput from "@components/ui/textInput"
import { useTranslation } from "react-i18next"
import PasswordMeter from "@components/passwordMeter"

type Props = {
    control: Control<ChangePassFormData>
    watch: UseFormWatch<ChangePassFormData>
}

export default function NewPass({ control, watch }: Props) {
    const { t } = useTranslation(["settings", "common"])

    return (
        <Flex rowGap="1.5rem" flexDir="column" mt={6}>
            <TextInput
                name="currentPass"
                control={control}
                rules={{ required: t("error.required", { ns: "common" }) }}
                label={t("profile.currentPass")}
                type="password"
            />
            <Box>
                <TextInput
                    name="newPass"
                    control={control}
                    label={t("profile.newPass")}
                    maxLength={127}
                    rules={{
                        required: t("error.required", { ns: "common" }),
                        minLength: {
                            value: 12,
                            message: t("error.minLength", { ns: "common", len: 12 }),
                        },
                        maxLength: {
                            value: 127,
                            message: t("error.maxLength", { ns: "common", len: 127 }),
                        },
                    }}
                    type="password"
                    mb="0.75rem"
                />
                <PasswordMeter isLight password={watch("newPass")} />
                <Text textStyle="InterRegularBodySmall">{t("profile.passMin")}</Text>
            </Box>
            <TextInput
                control={control}
                maxLength={127}
                name="confirmNewPass"
                label={t("profile.rePass")}
                rules={{
                    required: t("error.required", { ns: "common" }),
                    minLength: {
                        value: 12,
                        message: t("error.minLength", { ns: "common", len: 12 }),
                    },
                    maxLength: {
                        value: 127,
                        message: t("error.maxLength", { ns: "common", len: 127 }),
                    },
                }}
                type="password"
            />
        </Flex>
    )
}
