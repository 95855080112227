import React, { useMemo } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { BankDetails, IntlBankDetails } from "@redux/withdraw/types"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"

type Props = {
    focusedBank: BankDetails | IntlBankDetails
}

export default function CanadianBankDetails({ focusedBank }: Props) {
    const { t } = useTranslation(["settings"])
    const account = useAppSelector(selectAccountDetails)

    const {
        account_number,
        address,
        bank_address,
        bank_city,
        bank_country,
        bank_name,
        bank_province,
        bank_zip_code,
        city,
        first_name,
        last_name,
        institution_number,
        routing_number,
        swift_code,
        transit_number,
        zip_code,
        title,
    } = focusedBank

    const isIntl = useMemo(() => "type" in focusedBank && focusedBank.type === "international", [bank_country])

    return (
        <Flex rowGap="1.5rem" flexDir="column">
            <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                {title ?? bank_name}
            </Text>

            <Flex gap={3} justifyContent="space-between" flexDir={{ base: "column", md: "row" }}>
                <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                    {t("savedFiatAccount.nameOnAcc")}
                </Text>

                <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal" textAlign="right">
                    {first_name} {last_name}
                </Text>
            </Flex>

            <Box borderBottomWidth={1} />

            <Flex gap={3} justifyContent="space-between" flexDir={{ base: "column", md: "row" }}>
                <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                    {t("savedFiatAccount.beneficiaryAddress")}:
                </Text>
                <Flex flexDir="column" justify="flex-end" flex={1}>
                    <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {address}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {city}, {account?.identity?.address?.region}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {account?.identity?.address?.country}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {zip_code}
                    </Text>
                </Flex>
            </Flex>

            <Box borderBottomWidth={1} />

            <Flex gap={3} justifyContent="space-between" flexDir={{ base: "column", md: "row" }}>
                <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                    {t("savedFiatAccount.bankDetails")}:
                </Text>
                <Flex flexDir="column" justify="flex-end" flex={1}>
                    <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {bank_name}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {bank_address}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {bank_city}, {bank_province}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {bank_zip_code}
                    </Text>
                    {bank_country !== "CA" && bank_country !== "Canada" && (
                        <Text
                            textStyle="InterRegularBodySmall"
                            whiteSpace="normal"
                            wordBreak="normal"
                            textAlign="right"
                        >
                            {bank_country}
                        </Text>
                    )}
                </Flex>
            </Flex>

            <Box borderBottomWidth={1} />

            <Flex flexDir="column" rowGap="0.5rem">
                <Flex gap={3} justifyContent="space-between" flexDir={{ base: "column", md: "row" }}>
                    <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                        {isIntl ? t("savedFiatAccount.routingNum") : t("savedFiatAccount.instNumber")}:
                    </Text>

                    <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {isIntl ? routing_number : institution_number}
                    </Text>
                </Flex>
                <Flex gap={3} justifyContent="space-between" flexDir={{ base: "column", md: "row" }}>
                    <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                        {isIntl ? t("savedFiatAccount.swift") : t("savedFiatAccount.transitNumber")}:
                    </Text>

                    <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {isIntl ? swift_code : transit_number}
                    </Text>
                </Flex>
                <Flex gap={3} justifyContent="space-between" flexDir={{ base: "column", md: "row" }}>
                    <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                        {t("savedFiatAccount.accNumber")}:
                    </Text>

                    <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal" textAlign="right">
                        {account_number}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
