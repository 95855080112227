import { RootState } from "@/store/reducer"
import { AppDispatch } from "@/store/types"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { userManager } from "@util/oidc-config"
import { User as oidcUser } from "oidc-client-ts"
import { ACTION_TYPES, SLICE_NAME } from "./const"

type SerializedUser = {
    id_token: string
    access_token: string
    expires_at: number
    profile: {
        sub: string
        [key: string]: any // Store any additional profile claims here
    }
    expired: boolean | undefined
    url_state?: string
}

type AuthState = {
    user: SerializedUser | null
    isRefreshing: boolean
    loading: boolean
    error: string | null
}

const serializeUser = (user: oidcUser | null): SerializedUser | null => {
    if (!user) return null

    return {
        id_token: user.id_token ?? "",
        access_token: user.access_token,
        expires_at: user.expires_at ?? 0,
        profile: user.profile,
        expired: user.expired,
        url_state: user.url_state,
    }
}
// Initialize the userManager events
export const initializeUserManagerEvents = (dispatch: AppDispatch) => {
    // This should ensure whenever odic automatically renews token its updated in redux store
    userManager.events.addUserLoaded((user) => {
        dispatch(setUser(serializeUser(user)))
    })

    userManager.events.addUserUnloaded(() => {
        dispatch(setUser(null))
    })

    userManager.events.addAccessTokenExpired(() => {
        dispatch(setUser(null))
    })

    userManager.events.addSilentRenewError((error) => {
        dispatch(setError(error.message))
    })
}

const initialState: AuthState = {
    user: null,
    isRefreshing: false,
    loading: false,
    error: null,
}

export const resolveUserOrLogin = createAsyncThunk(ACTION_TYPES.RESOLVE_USER_OR_LOGIN, async (_) => {
    const user = await userManager.getUser()
    if (user) return serializeUser(user)

    const stateExists = new URLSearchParams(window.location.search).has("state")
    if (stateExists) {
        const _user = await userManager.signinCallback()
        return serializeUser(_user || null) || null
    }

    await userManager.signinRedirect({
        url_state: window.location.pathname + window.location.search,
    })
    return null
})

export const renewToken = createAsyncThunk(ACTION_TYPES.RENEW_TOKEN, async (_, { getState, dispatch }) => {
    const state = getState() as RootState
    if (state.auth.isRefreshing) {
        return new Promise<SerializedUser | null>((resolve) => {
            const interval = setInterval(() => {
                if (!state.auth.isRefreshing) {
                    clearInterval(interval)
                    resolve(state.auth.user)
                }
            }, 100)
        })
    }

    dispatch(setIsRefreshing(true))
    const user = await userManager.signinSilent()
    dispatch(setIsRefreshing(false))
    return serializeUser(user)
})

export const logout = createAsyncThunk(ACTION_TYPES.LOGOUT, async (params?: Record<string, string>) => {
    await userManager.signoutRedirect(
        params
            ? {
                  extraQueryParams: params,
              }
            : undefined
    )
})

const authSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setIsRefreshing(state, action: PayloadAction<boolean>) {
            state.isRefreshing = action.payload
        },
        setUser(state, action: PayloadAction<SerializedUser | null>) {
            state.user = action.payload
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resolveUserOrLogin.pending, (state) => {
                state.error = null
                state.loading = true
            })
            .addCase(resolveUserOrLogin.fulfilled, (state, action) => {
                state.user = action.payload
                state.loading = false
            })
            .addCase(resolveUserOrLogin.rejected, (state, action) => {
                state.error = action.error.message || "Failed to authenticate"
                state.loading = false
            })
            .addCase(renewToken.fulfilled, (state, action) => {
                state.user = action.payload
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
    },
})

export const { setIsRefreshing, setUser, setError } = authSlice.actions
export default authSlice.reducer
