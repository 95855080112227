export type TransactionParams = {
    types: string
    limit: number
    page: number
    sort?: "asc" | "desc"
    statuses?: string
    assets?: string
    range?: string
}

export enum CsvExportType {
    Default = "default",
    Koinly = "koinly",
    Zenledger = "zenledger",
}

export type TransactionCsvParams = {
    exportType: CsvExportType
    sort?: "asc" | "desc"
    assets?: string
    types?: string
    email?: boolean
    range?: string
}

export enum TransactionAction {
    Buy = "buy",
    Sell = "sell",
    Deposit = "deposit",
    Withdraw = "withdraw",
    Reward = "reward",
    StakeReward = "stake_reward",
    KohoReward = "koho_reward",
    Stake = "stake",
    Unstake = "unstake",
    LimitBuy = "limit-buy",
    LimitSell = "limit-sell",
}

export type TransactionStatus = "processing" | "pending" | "delivered" | string

export type Transaction = {
    created_at: number
    counter_asset: string
    action: TransactionAction
    symbol: string
    asset: string
    uuid: string
    subtotal: string
    total: string
    fee: string
    rate: string
    increase_amount: string
    decrease_amount: string
    increase_currency: string
    decrease_currency: string
    order_id: number
    created_at_pst: Date
    status: TransactionStatus
    transaction_link?: string | null
    transaction_hash?: string | null
    wallet?: string
    wallet_link?: string
    wallet_label?: string | null
    wallet_memo?: string | null
    notes?: string | null
    isIssuedKohoReward?: boolean
    fee_currency?: string | null
    defaultSymbol?: string | null
}

export type TransactionsResponse = {
    showRewardsDisclaimer: boolean
    total: number
    transactions: Transaction[]
}

export type LimitOrderResponse = {
    asset: string
    counter_asset: string
    netcoins_fee: string
    price: string
    quantity: string
    side: "buy" | "sell"
    order_id: string
    amount: string
    subtotal: string
    total: string
    status: string
    date: string
}

export type CancelOrderParams = {
    order_id: string
}
