import { useAppSelector } from "@/store/hooks"
import { Box, Flex, Text } from "@chakra-ui/react"

import DashboardFooter from "@components/footer/dashboard"
import SavedWalletAddressTable from "@components/settings/savedCrypto/savedWalletAddressTable"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { useTranslation } from "react-i18next"
import ScreenContainer from "@components/ui/containers/screen"

type Props = {}

export default function SavedAddressesCrypto({}: Props) {
    const { t } = useTranslation("settings")
    const { navigate } = useRestrictedNavigation()

    return (
        <ScreenContainer>
            <Flex flex={1} mb="1.5rem" flexDir="column">
                <Flex flexDir="column" gap={2}>
                    <BreadCrumbGroup
                        breadCrumbs={[
                            {
                                text: t("savedWalletAddress.settings"),
                                hasAfter: true,
                                onClick: () => navigate(ROUTES.DASHBOARD_SETTINGS),
                            },
                            {
                                text: t("savedWalletAddress.crypto"),
                                hasAfter: false,
                            },
                        ]}
                        py={2}
                    />

                    <Flex justify="start" flexDir={{ base: "column", md: "row" }} w="100%" gap={6}>
                        <Flex flex={1} flexDir="column" gap={2}>
                            <Text textStyle="ManropeMedium4xLarge">{t("savedWalletAddress.title")}</Text>
                            <Text textStyle="InterRegularBodySmall">{t("savedWalletAddress.text")}</Text>
                        </Flex>

                        <Flex flex={1} />
                    </Flex>
                </Flex>

                <Box my={8} borderBottomWidth={1} />

                <SavedWalletAddressTable />
            </Flex>

            <DashboardFooter />
        </ScreenContainer>
    )
}
