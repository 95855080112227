export enum ENUM_BUTTON_VARIANTS {
    PRIMARY_SOLID = "PRIMARY_SOLID",
    PRIMARY_BLUE_SOLID = "PRIMARY_BLUE_SOLID",
    PRIMARY_BLUE_OUTLINE = "PRIMARY_BLUE_OUTLINE",
    PRIMARY_OUTLINE = "PRIMARY_OUTLINE",
    PRIMARY_GHOST = "PRIMARY_GHOST",
    PRIMARY_BLUE_GHOST = "PRIMARY_BLUE_GHOST",
    SECONDARY_SOLID = "SECONDARY_SOLID",
    SECONDARY_OUTLINE = "SECONDARY_OUTLINE",
    SECONDARY_GHOST = "SECONDARY_GHOST",
    BLUE_PRIMARY_FAT = "BLUE_PRIMARY_FAT",
    PRIMARY_LINK = "PRIMARY_LINK",
    RED_SOLID = "RED_SOLID",
    RED_GHOST = "RED_GHOST",
}

type STATEFUL_PROPERTIES = {
    bg: string
    borderColor: string
    color: string
    textDecoration?: "underline"
}

type STRUCTURE_BUTTON_VARIANT = {
    normal: STATEFUL_PROPERTIES
    hover: STATEFUL_PROPERTIES
    active: STATEFUL_PROPERTIES
    disabled: STATEFUL_PROPERTIES
    textStyleWeight: "Medium" | "Regular" | "Semibold" | "Bold"
    borderWidth: number
}

const BUTTON_VARIANTS: Record<ENUM_BUTTON_VARIANTS, STRUCTURE_BUTTON_VARIANT> = {
    PRIMARY_SOLID: {
        normal: {
            bg: "bluePurple.100",
            borderColor: "bluePurple.100",
            color: "white",
        },
        hover: {
            bg: "blue.100",
            borderColor: "blue.100",
            color: "white",
        },
        disabled: {
            bg: "bluePurple.60",
            borderColor: "bluePurple.60",
            color: "white",
        },
        active: {
            bg: "blue.80",
            borderColor: "blue.80",
            color: "white",
        },
        textStyleWeight: "Medium",
        borderWidth: 2,
    },
    PRIMARY_BLUE_OUTLINE: {
        normal: {
            bg: "white",
            borderColor: "blue.100",
            color: "blue.100",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "blue.100",
            color: "blue.100",
        },
        hover: {
            bg: "bluePurple.10",
            borderColor: "blue.100",
            color: "blue.100",
        },
        disabled: {
            bg: "white",
            borderColor: "bluePurple.50",
            color: "bluePurple.50",
        },
        textStyleWeight: "Semibold",
        borderWidth: 2,
    },
    PRIMARY_OUTLINE: {
        normal: {
            bg: "white",
            borderColor: "bluePurple.100",
            color: "bluePurple.100",
        },
        hover: {
            bg: "blue.100",
            borderColor: "bluePurple.80",
            color: "white",
        },
        disabled: {
            bg: "white",
            borderColor: "bluePurple.50",
            color: "bluePurple.40",
        },
        active: {
            bg: "blue.70",
            borderColor: "bluePurple.80",
            color: "white",
        },
        textStyleWeight: "Semibold",
        borderWidth: 2,
    },
    PRIMARY_GHOST: {
        normal: {
            bg: "transparent",
            borderColor: "transparent",
            color: "bluePurple.100",
        },
        hover: {
            bg: "blue.10",
            borderColor: "blue.10",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "white",
            borderColor: "white",
            color: "bluePurple.50",
        },
        active: {
            bg: "blue.30",
            borderColor: "blue.30",
            color: "bluePurple.60",
        },
        textStyleWeight: "Semibold",
        borderWidth: 2,
    },
    PRIMARY_BLUE_GHOST: {
        normal: {
            bg: "white",
            borderColor: "white",
            color: "blue.100",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.20",
            color: "bluePurple.100",
        },
        hover: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.10",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "white",
            borderColor: "white",
            color: "bluePurple.50",
        },
        textStyleWeight: "Semibold",
        borderWidth: 2,
    },
    SECONDARY_SOLID: {
        normal: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.10",
            color: "grey.light.90",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.20",
            color: "grey.light.60",
        },
        hover: {
            bg: "bluePurple.30",
            borderColor: "bluePurple.30",
            color: "grey.light.80",
        },
        disabled: {
            bg: "bluePurple.40",
            borderColor: "bluePurple.40",
            color: "bluePurple.10",
        },
        textStyleWeight: "Semibold",
        borderWidth: 2,
    },
    SECONDARY_OUTLINE: {
        normal: {
            bg: "white",
            borderColor: "bluePurple.20",
            color: "bluePurple.100",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.70",
            color: "bluePurple.100",
        },
        hover: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.70",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "white",
            borderColor: "bluePurple.20",
            color: "bluePurple.20",
        },
        textStyleWeight: "Semibold",
        borderWidth: 1,
    },
    PRIMARY_BLUE_SOLID: {
        normal: {
            bg: "blue.100",
            borderColor: "blue.100",
            color: "white",
        },
        hover: {
            bg: "bluePurple.100",
            borderColor: "bluePurple.100",
            color: "white",
        },
        disabled: {
            bg: "bluePurple.60",
            borderColor: "bluePurple.60",
            color: "white",
        },
        active: {
            bg: "darkBlue.70",
            borderColor: "darkBlue.70",
            color: "white",
        },
        textStyleWeight: "Medium",
        borderWidth: 2,
    },
    BLUE_PRIMARY_FAT: {
        normal: {
            bg: "blue.100",
            borderColor: "blue.100",
            color: "white",
        },
        hover: {
            bg: "bluePurple.100",
            borderColor: "bluePurple.100",
            color: "white",
        },
        disabled: {
            bg: "bluePurple.60",
            borderColor: "bluePurple.60",
            color: "white",
        },
        active: {
            bg: "darkBlue.70",
            borderColor: "darkBlue.70",
            color: "white",
        },
        textStyleWeight: "Medium",
        borderWidth: 2,
    },
    SECONDARY_GHOST: {
        normal: {
            bg: "white",
            borderColor: "white",
            color: "grey.light.90",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.20",
            color: "bluePurple.100",
        },
        hover: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.10",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "white",
            borderColor: "white",
            color: "bluePurple.50",
        },
        textStyleWeight: "Semibold",
        borderWidth: 2,
    },
    PRIMARY_LINK: {
        normal: {
            bg: "transparent",
            borderColor: "transparent",
            color: "blue.100",
        },
        active: {
            bg: "transparent",
            borderColor: "transparent",
            color: "blue.70",
            textDecoration: "underline",
        },
        hover: {
            bg: "transparent",
            borderColor: "transparent",
            color: "blue.100",
            textDecoration: "underline",
        },
        disabled: {
            bg: "transparent",
            borderColor: "transparent",
            color: "bluePurple.50",
        },
        textStyleWeight: "Semibold",
        borderWidth: 2,
    },
    RED_GHOST: {
        normal: {
            bg: "transparent",
            borderColor: "transparent",
            color: "red.light.100",
        },
        active: {
            bg: "red.light.20",
            borderColor: "transparent",
            color: "red.light.100",
        },
        hover: {
            bg: "red.light.10",
            borderColor: "transparent",
            color: "red.light.100",
        },
        disabled: {
            bg: "transparent",
            borderColor: "transparent",
            color: "bluePurple.40",
        },
        textStyleWeight: "Semibold",
        borderWidth: 2,
    },
    RED_SOLID: {
        normal: {
            bg: "red.light.100",
            borderColor: "transparent",
            color: "white",
        },
        active: {
            bg: "red.light.80",
            borderColor: "transparent",
            color: "white",
        },
        hover: {
            bg: "red.light.50",
            borderColor: "transparent",
            color: "white",
        },
        disabled: {
            bg: "bluePurple.40",
            borderColor: "transparent",
            color: "white",
        },
        textStyleWeight: "Medium",
        borderWidth: 2,
    },
}

export default BUTTON_VARIANTS
