import { ResponsiveValue } from "@chakra-ui/react"
import type { Property } from "csstype"

export enum ENUM_COPY_FIELD_VARIANTS {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
}

type STATEFUL_PROPERTIES = {
    borderWidth: number | string
    borderColor: string
    px: number | string
    iconColor: string
    containerSpacingType: ResponsiveValue<Property.JustifyContent>
    containerSpacing: number | string
    minH: number | string
    textFlex?: number | string
}

export const COPY_FIELD_VARIANTS: Record<ENUM_COPY_FIELD_VARIANTS, STATEFUL_PROPERTIES> = {
    PRIMARY: {
        borderWidth: "1px",
        borderColor: "grey.light.10",
        px: 4,
        iconColor: "black",
        containerSpacingType: "justify-between",
        textFlex: 1,
        containerSpacing: 2,
        minH: "48px",
    },
    SECONDARY: {
        borderWidth: 0,
        borderColor: "transparent",
        px: 0,
        iconColor: "grey.light.40",
        containerSpacingType: "flex-start",
        containerSpacing: "10px",
        minH: "unset",
    },
}
