import { Text, Flex, Table, Tbody, Tr, Th, Thead, Box, Td } from "@chakra-ui/react"
import AlertRow from "../alertRow"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import SingleSelect from "@components/ui/select/singleSelect"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoAdd } from "react-icons/io5"
import { Option } from "@components/ui/types"
import AddNewPriceAlert from "./AddNewPriceAlert"
import { PriceAlert } from "@redux/account/types"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { formatDollar } from "@util/stringFormatting"

export interface NotificationTableProps {
    priceAlerts: Array<PriceAlert>
    onDeleteClick?: (id: number) => void
    assets: Record<string, Partial<AssetDetailsFull>>
    handleFilter?: (option: Option<string> | null) => void
    addPriceAlert?: () => void
    editPriceAlert?: (priceAlert: PriceAlert) => void
}

export default function NotificationsTable({
    priceAlerts,
    onDeleteClick,
    assets,
    handleFilter,
    addPriceAlert,
    editPriceAlert,
}: NotificationTableProps): JSX.Element {
    const { t } = useTranslation("settings")

    const coinOptions = useMemo(() => {
        if (assets) {
            return Object.values(assets).map((asset) => ({
                value: asset.symbol,
                label: asset.symbol,
                desc: asset.name,
            })) as Option<string>[]
        }
        return []
    }, [assets])

    const getMessage = (priceAlert: PriceAlert) => {
        if (priceAlert.change_percentage) {
            return t("notifications.movesPercent", {
                asset: assets[priceAlert.coin.toUpperCase()]?.name || priceAlert.coin,
                percent: priceAlert.change_percentage,
                hours: priceAlert.time_window,
            })
        }

        if (priceAlert.target_price) {
            return t("notifications.hitsTarget", {
                asset: assets[priceAlert.coin.toUpperCase()]?.name || priceAlert.coin,
                price: formatDollar(priceAlert?.target_price ?? 0),
            })
        }

        return ""
    }

    return (
        <Flex flexDir="column" gap={3}>
            <Flex alignItems="center" justifyContent="space-between" w="full">
                <Flex alignItems="center" gap={6} py={3}>
                    <Text
                        textStyle="ManropeMediumBodySmall"
                        color="bluePurple.100"
                        display={{ base: "none", md: "flex" }}
                    >
                        {t("notifications.filterBy")}
                    </Text>
                    <SingleSelect
                        isLoading={coinOptions.length === 0}
                        isDisabled={coinOptions.length === 0}
                        placeholder="Coin"
                        options={coinOptions}
                        onSelect={(option) => {
                            handleFilter && handleFilter(option)
                        }}
                        cancellable
                        categoryName="Coin"
                    />
                </Flex>

                {coinOptions.length > 0 && (
                    <Flex alignItems="center">
                        <StandardButton
                            children={t("notifications.newPriceAlert")}
                            variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                            leftIcon={IoAdd}
                            onClick={() => addPriceAlert && addPriceAlert()}
                            size="sm"
                        />
                    </Flex>
                )}
            </Flex>

            <Table>
                <Thead bg="grey.light.5">
                    <Tr px={2} py={"6px"}>
                        <Th display={{ base: "none", md: "flex" }}>
                            <Text color="grey.light.90" textStyle="ManropeSemiboldXSmall" textTransform="none">
                                {t("notifications.cryptocurrency")}
                            </Text>
                        </Th>
                        <Th colSpan={2} px={{ base: 2, md: 6 }}>
                            <Text color="grey.light.90" textStyle="ManropeSemiboldXSmall" textTransform="none">
                                {t("notifications.alert")}
                            </Text>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {priceAlerts.length === 0 || coinOptions.length === 0 ? (
                        <Tr>
                            <Td colSpan={3} textAlign="center" py={6}>
                                <Text color="grey.light.80" textStyle={"ManropeMediumBody"}>
                                    {coinOptions.length === 0
                                        ? `${t("common:loading")}...`
                                        : t("notifications.noAlerts")}
                                </Text>
                            </Td>
                        </Tr>
                    ) : (
                        priceAlerts.map((row, index) => (
                            <AlertRow
                                key={index}
                                coin={assets[row.coin.toUpperCase()]?.name || row.coin}
                                symbol={row.coin}
                                alert={getMessage(row)}
                                onDeleteClick={() => {
                                    onDeleteClick && onDeleteClick(row.id)
                                }}
                                onEditClick={() => {
                                    editPriceAlert && editPriceAlert(row)
                                }}
                            />
                        ))
                    )}
                </Tbody>
            </Table>
        </Flex>
    )
}
