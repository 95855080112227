import { Box, Link } from "@chakra-ui/react"
import { ROUTES } from "@routing/routes"
import LogoFull from "assets/svgs/netcoins/logo-full"
import { NavLink } from "react-router-dom"
import { HEADER_HEIGHT } from "../dashboard"

export default function HeaderHostedPage() {
    return (
        <Box pos="relative">
            <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                w="full"
                bg="bluePurple.100"
                height={HEADER_HEIGHT + "px"}
                px="1.5rem"
            >
                <Link as={NavLink} to={ROUTES.DASHBOARD}>
                    <LogoFull color="white" size={200} />
                </Link>
            </Box>
        </Box>
    )
}
