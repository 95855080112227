export type FormData = {
    input: string
}

export type WithInputProps = {
    handleChange: (name: "pin" | "survey", value: string) => void
    value: string
}

export const TIMEZONE_OPTIONS = [
    { value: "Pacific/Midway", label: "Pacific/Midway" },
    { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
    { value: "America/Anchorage", label: "America/Anchorage" },
    { value: "America/Vancouver", label: "America/Vancouver" },
    { value: "America/Edmonton", label: "America/Edmonton" },
    { value: "America/Winnipeg", label: "America/Winnipeg" },
    { value: "America/Toronto", label: "America/Toronto" },
    { value: "America/Halifax", label: "America/Halifax" },
    { value: "America/St_Johns", label: "America/St_Johns" },
    { value: "America/Argentina/Buenos_Aires", label: "America/Argentina/Buenos_Aires" },
    { value: "America/Sao_Paulo", label: "America/Sao_Paulo" },
    { value: "Atlantic/Cape_Verde", label: "Atlantic/Cape_Verde" },
    { value: "UTC", label: "UTC" },
    { value: "Europe/London", label: "Europe/London" },
    { value: "Europe/Berlin", label: "Europe/Berlin" },
    { value: "Europe/Kiev", label: "Europe/Kiev" },
    { value: "Europe/Moscow", label: "Europe/Moscow" },
    { value: "Asia/Tehran", label: "Asia/Tehran" },
    { value: "Asia/Dubai", label: "Asia/Dubai" },
    { value: "Indian/Maldives", label: "Indian/Maldives" },
    { value: "Asia/Calcutta", label: "Asia/Calcutta" },
    { value: "Asia/Dacca", label: "Asia/Dacca" },
    { value: "Asia/Bangkok", label: "Asia/Bangkok" },
    { value: "Asia/Hong_Kong", label: "Asia/Hong_Kong" },
    { value: "Asia/Tokyo", label: "Asia/Tokyo" },
    { value: "Australia/Adelaide", label: "Australia/Adelaide" },
    { value: "Australia/Sydney", label: "Australia/Sydney" },
    { value: "Pacific/Auckland", label: "Pacific/Auckland" },
]
