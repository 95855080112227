import { Box, Flex, Text, useToast } from "@chakra-ui/react"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import TwoFACode from "../twoFACode"
import PinInput from "@components/ui/pinInput"
import { useTranslation } from "react-i18next"
import { useSendPasswordChangeOtpMutation } from "@redux/account/apiSlice"
import { useEffect } from "react"
import useGenericToast from "@hooks/useGenericToast"

type Props = {
    pin: string
    handleChange: (_: string) => void
    onComplete: (_: string) => void
    title?: string
    descPrefix?: string
    err?: string
}

export default function SecurityCode({ pin, handleChange, onComplete, title, descPrefix, err }: Props) {
    const { t } = useTranslation(["twoFA", "common"])
    const account = useAppSelector(selectAccountDetails)
    const { successToast } = useGenericToast()

    const [sendPasswordOtp] = useSendPasswordChangeOtpMutation()

    useEffect(() => {
        if (!account?.tfa) {
            sendPasswordOtp()
        }
    }, [])

    function handleClick() {
        sendPasswordOtp()
        successToast(t("twoFASetup.emailCodeSent"))
    }

    return (
        <Box>
            {account?.tfa ? (
                <TwoFACode
                    value={pin}
                    onChange={handleChange}
                    onComplete={onComplete}
                    title={title}
                    description={descPrefix + t("twoFASetup.2faDesc")}
                    showTFARecovery
                />
            ) : (
                <Box>
                    <Flex flexDir="column" gap={2}>
                        <Text textStyle="ManropeSemiboldBody">{title}</Text>
                        <Text textStyle="InterRegularBody">{descPrefix + t("twoFASetup.enterEmailCode")}</Text>
                    </Flex>

                    <PinInput
                        baseColor="white"
                        name="pin"
                        numOfFields={6}
                        value={pin}
                        onChange={(value) => handleChange(value)}
                        onComplete={(val) => onComplete(val)}
                        error={!!err}
                    />

                    <Box onClick={handleClick} cursor="pointer">
                        <Text
                            textStyle="ManropeSemiboldBodySmall"
                            color="blue.100"
                            _hover={{ textDecoration: "underline", textDecorationColor: "blue.100" }}
                        >
                            {t("resendCode", { ns: "common" })}
                        </Text>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
