import { format } from "date-fns"

export const formatShortDate = (date: Date) => {
    return format(new Date(date), "MMM dd, yyyy")
}

export const formatShortDateTime = (date: Date) => {
    return format(new Date(date), "MMM dd, yyyy 'at' hh:mmaaa")
}

export const formatRangeDate = (date: Date) => {
    const year = date.getFullYear()
    const month = date.toLocaleString("en-US", { month: "short" })
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}+${month}+${day}`
}

export const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000)
    return date.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    })
}
