import { Text, Flex } from "@chakra-ui/react"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import { useTranslation } from "react-i18next"
import DashboardFooter from "@components/footer/dashboard"
import AuthorizedDeviceTable from "@components/settings/authorizedDevice/authorizedDeviceTable"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import ScreenContainer from "@components/ui/containers/screen"

export default function AuthorizedDevicesPage(): JSX.Element {
    const { t } = useTranslation("settings")
    const { navigate } = useRestrictedNavigation()

    return (
        <ScreenContainer>
            <Flex flexDir="column" gap={2} mb={"40px"}>
                <BreadCrumbGroup
                    breadCrumbs={[
                        {
                            text: t("authorizedDevices.settings"),
                            hasAfter: true,
                            onClick: () => navigate(ROUTES.DASHBOARD_SETTINGS),
                        },
                        {
                            text: t("authorizedDevices.title"),
                            hasAfter: false,
                        },
                    ]}
                    py={2}
                />

                <Flex justify="start" flexDir={{ base: "column", md: "row" }} w="100%">
                    <Flex flex={1} flexDir="column" gap={2}>
                        <Text textStyle="ManropeMedium4xLarge">{t("authorizedDevices.title")}</Text>
                        <Text textStyle="InterRegularBodySmall">{t("authorizedDevices.text")}</Text>
                    </Flex>
                    <Flex flex={1} />
                </Flex>
            </Flex>
            <AuthorizedDeviceTable />
            <DashboardFooter />
        </ScreenContainer>
    )
}
