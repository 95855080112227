import { API_PATH } from "./const"
import { splitApi } from "@redux/splitApi"
import { INPayTransactionsResponse, nPayResponse, nPayTransactionsParams } from "./types"

export const nPayApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getNPayOverView: builder.query<nPayResponse, void>({
            query: () => ({
                url: `${API_PATH.NPAY_OVERVIEW}`,
                method: "GET",
            }),
        }),
        toggleCardLock: builder.mutation<void, { id: number }>({
            query: (params) => ({
                url: `${API_PATH.LOCK_NPAY}`,
                method: "POST",
                body: params,
            }),
        }),
        viewCard: builder.mutation<{ imgSrc: string }, { id: number }>({
            query: (params) => ({
                url: `${API_PATH.VIEW_NPAY_CARD}`,
                method: "POST",
                body: params,
            }),
        }),

        nPayTransactions: builder.query<INPayTransactionsResponse, nPayTransactionsParams>({
            query: ({ page = 1, limit = 10, sort = "desc", range = "", cardTypes = "" }) => {
                let url = `${API_PATH.NPAY_TRANSACTIONS}?page=${page}&limit=${limit}&sort=${sort}&range=${range}&cards=${cardTypes}`

                return {
                    url: url,
                    method: "GET",
                }
            },
        }),
    }),
})

export const { useGetNPayOverViewQuery, useToggleCardLockMutation, useViewCardMutation, useNPayTransactionsQuery } =
    nPayApi
