import { useAppSelector } from "@/store/hooks"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Alert, AlertIcon, Flex, Text } from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useEmailTransactionsMutation } from "@redux/account/apiSlice"
import { selectAccountDetails } from "@redux/account/selectors"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineDownload } from "react-icons/md"

type Props = {
    setErr: (_: string) => void
}

export default function CloseDownloadTransactions({ setErr }: Props): JSX.Element {
    const { t } = useTranslation(["settings", "common"])
    const account = useAppSelector(selectAccountDetails)
    const [emailTransactionMutation, { isLoading }] = useEmailTransactionsMutation()

    const [isButtonClicked, setIsButtonClicked] = useState(false)

    const onClick = async () => {
        setErr("")
        await emailTransactionMutation()
            .then(() => {
                setIsButtonClicked(true)
                setErr("")
            })
            .catch(() => setErr(t("error.serverDesc", { ns: "common" })))
    }

    return (
        <Flex flexDir={"column"} gap={6}>
            <Flex alignItems="center" width="96px" height="56px" justifyContent="center" bg="blue.10" borderRadius={1}>
                <ReactIcon icon={MdOutlineDownload} size={24} color="blue.100" />
            </Flex>

            <Flex flexDir="column" gap={3}>
                <Text textStyle="ManropeSemiboldBody">{t("profile.downloadTransactions")}</Text>
                <Text textStyle="InterRegularBody">{t("profile.onceClosed")}</Text>
                <Text textStyle="InterRegularBody">{t("profile.pleaseEnsure")}</Text>
            </Flex>

            {isButtonClicked ? (
                <Alert status="info" textStyle="InterRegularBodySmall" sx={{ background: "grey.light.5" }}>
                    <AlertIcon />
                    {t("profile.emailSent", { email: account?.email })}
                </Alert>
            ) : (
                <StandardButton
                    variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                    children={t("profile.requestByEmail")}
                    onClick={onClick}
                    isLoading={isLoading}
                    w="100%"
                    size="md"
                />
            )}
        </Flex>
    )
}
