import { border, defineStyleConfig } from "@chakra-ui/react"
import { SolidSuccessVariant, SolidVariant, SubtleVariant } from "./alerts"

const Skeleton = defineStyleConfig({
    baseStyle: {
        animationDuration: "10s",
        transitionDuration: "10s",
        //@ts-ignore
        fadeDuration: 10,
    },
})

export const Components = {
    Alert: {
        // Toasts are considered Alerts under the hood
        variants: {
            subtle: SubtleVariant,
            solid: SolidVariant,
            solidSuccess: SolidSuccessVariant,
        },
    },
    Button: {
        sizes: {
            xl: {
                h: "56px",
                fontSize: "lg",
                px: "32px",
            },
        },
    },
    Switch: {
        baseStyle: {
            track: {
                _checked: {
                    bg: "green.light.100",
                },
            },
        },
    },
    Menu: {
        baseStyle: {
            item: {
                bgColor: "white",
                _hover: {
                    bg: "blue.100",
                    color: "white",
                },
                justifyContent: "flex-start",
                "&:hover": {
                    p: {
                        color: "white",
                    },
                    svg: {
                        fill: "white !important",
                    },
                },
            },
        },
    },
    Input: {},
    Skeleton,
    Text: {
        baseStyle: {
            color: "black",
        },
    },
    Radio: {
        baseStyle: {
            control: {
                _checked: {
                    bg: "blue.100",
                    borderColor: "blue.100",
                },
            },
        },
    },
}
