import { Box, Text } from "@chakra-ui/layout"
import ScreenContainer from "@components/ui/containers/screen"
import { useTranslation } from "react-i18next"
import riskStatement from "@assets/otherHostedPages/riskStatement.html"

export default function RiskStatement() {
    const { t } = useTranslation("common")

    return (
        <ScreenContainer>
            <Box pb={8} borderBottomWidth={1} borderBottomColor="grey.light.10">
                <Text textStyle="ManropeMedium4xLarge">{t("riskStatement")}</Text>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: riskStatement }} py={8} className="hosted-page" />
        </ScreenContainer>
    )
}
