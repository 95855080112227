import { useAppSelector } from "@/store/hooks"
import { TCurrencyType } from "@/types"
import { selectAccountDetails } from "@redux/account/selectors"
import { useMemo } from "react"

const currencyConfig: Record<TCurrencyType, { value: string; label: string; desc: string }> = {
    CAD: { value: "cad", label: "CAD", desc: "CAD" },
    USD: { value: "usd", label: "USD", desc: "USD" },
}

const useRegion = () => {
    const accountDetails = useAppSelector(selectAccountDetails)

    const country = accountDetails?.country
    const currency = accountDetails?.currency

    const isCAUser = useMemo(() => country === "CA", [country])

    const getBaseCountryUri = (uri: string) => {
        if (!country) {
            return uri
        }

        return `${uri}?c=${country}`
    }

    const getCurrencyOptions = () => {
        const selectedCurrency = isCAUser ? "CAD" : "USD"
        return [currencyConfig[selectedCurrency]]
    }

    return {
        isCAUser,
        country,
        currency,
        getBaseCountryUri,
        getCurrencyOptions,
    }
}

export default useRegion
