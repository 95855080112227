import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import TwoFACode from "../twoFACode"
import TextInput from "@components/ui/textInput"
import { PasswordFormData } from "@screens/dashboard/settings/profile/CloseAccount"
import { Control } from "react-hook-form"
import ErrorMessage from "@components/ui/errorMessage"

type Props = {
    pin: string
    setPin: (_: string) => void
    control: Control<PasswordFormData>
    noPinErr: boolean
}

export default function Disable2FA({ pin, setPin, control, noPinErr }: Props) {
    const { t } = useTranslation(["twoFA"])

    return (
        <Box mt={"1.725rem"}>
            <Text textStyle="ManropeSemiboldBody" mb="1.5rem">
                {t("twoFASetup.disabledCodes")}
            </Text>
            <TwoFACode value={pin} onChange={setPin} error={noPinErr} />
            {noPinErr && <ErrorMessage>{t("error.pinRequired", { ns: "common" })}</ErrorMessage>}
            <TextInput
                name="password"
                control={control}
                rules={{ required: t("error.required", { ns: "common" }) }}
                label={t("twoFASetup.accountPass")}
                mt={"1.5rem"}
                type="password"
                placeholder={t("twoFASetup.enterPass")}
            />
        </Box>
    )
}
