import { store } from "@/store"
import { useAppDispatch } from "@/store/hooks"
import { useToast } from "@chakra-ui/react"
import Toasts from "@components/ui/toasts"
import { MAX_TOASTS, add, remove } from "@redux/toasts"
import { useTranslation } from "react-i18next"
import { IconType } from "react-icons"
import { MdCheck, MdInfoOutline } from "react-icons/md"

const useGenericToast = () => {
    const { t } = useTranslation("common")
    const toast = useToast()
    const dispatch = useAppDispatch()

    function serverErrorToast() {
        generateToast("red.light.100", "white", MdInfoOutline, t("error.server"), t("error.serverDesc"))
    }

    function closeExcessToasts() {
        // get state directly from store in order to avoid unnecessary re-renders in the component consuming the hook
        const reduxState = store.getState()

        const toasts = reduxState.toast
        const len = toasts.length
        if (len >= MAX_TOASTS - 1) {
            const toRemove = len - MAX_TOASTS + 1
            for (let i = 0; i < toRemove; i++) {
                if (toast.isActive(toasts[i])) {
                    toast.close(toasts[i])
                }
            }
        }
    }

    const generateToast = (
        bgColor: string,
        color: string,
        icon: IconType,
        title: string,
        desc?: string,
        cannotClose?: boolean
    ) => {
        closeExcessToasts()
        const id = crypto.randomUUID()
        dispatch(add(id))
        toast({
            id,
            position: "bottom-right",
            render: ({ onClose }) => (
                <Toasts
                    bgColor={bgColor}
                    color={color}
                    title={title}
                    desc={desc}
                    onClose={cannotClose ? undefined : onClose}
                    icon={icon}
                />
            ),
            onCloseComplete: () => {
                dispatch(remove(id))
            },
            duration: 5000,
        })
    }

    return {
        serverErrorToast,
        successToast: (title: string, desc?: string, cannotClose?: boolean) =>
            generateToast("green.light.100", "white", MdCheck, title, desc, cannotClose),
        errorToast: (title: string, desc?: string, cannotClose?: boolean) =>
            generateToast("red.light.100", "white", MdInfoOutline, title, desc, cannotClose),
        infoToast: (title: string, desc?: string, cannotClose?: boolean) =>
            generateToast("blue.10", "black", MdInfoOutline, title, desc, cannotClose),
        warningToast: (title: string, desc?: string, cannotClose?: boolean) =>
            generateToast("blue.100", "white", MdInfoOutline, title, desc, cannotClose),
    }
}

export default useGenericToast
