import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { IoShieldCheckmarkOutline } from "react-icons/io5"
import TextInput from "@components/ui/textInput"
import { Control } from "react-hook-form"
import { PasswordFormData } from "../CloseAccount"

export default function CloseWithPassword({ control }: { control: Control<PasswordFormData> }): JSX.Element {
    const { t } = useTranslation(["settings", "common"])

    return (
        <Flex flexDir={"column"} gap={6}>
            <Flex
                alignItems="center"
                width="96px"
                height="56px"
                justifyContent="center"
                bg="green.light.10"
                borderRadius={1}
            >
                <ReactIcon icon={IoShieldCheckmarkOutline} size={24} color="green.light.100" />
            </Flex>

            <Text textStyle="ManropeSemiboldBody">{t("profile.confirmThisIsYou")}</Text>

            <TextInput
                type="password"
                placeholder={t("profile.enterPass")}
                control={control}
                name="password"
                rules={{ required: t("error.required", { ns: "common" }) }}
            />
        </Flex>
    )
}
