import { Icon, Input, InputGroup, InputLeftAddon, InputRightAddon } from "@chakra-ui/react"
import { FIELD_ICON_SIZE, FIELD_SIZE } from "@components/ui/types"
import React, { useMemo } from "react"
import useColorFormatConverter from "theme/useColorFormatConverter"
import SEARCHBAR_VARIANTS, { ENUM_SEARCHBAR_VARIANTS } from "./types"
import { MdClose, MdOutlineSearch } from "react-icons/md"
import ReactIcon from "@assets/svgs/ReactIconWrapper"

export type Props = {
    value?: string
    onChange: (_: string) => void
    variant?: ENUM_SEARCHBAR_VARIANTS
    placeholder?: string
    removeBorder?: boolean
    disabled?: boolean
    [rest: string]: any
}

export function Searchbar({
    value,
    onChange,
    variant = ENUM_SEARCHBAR_VARIANTS.PRIMARY,
    placeholder,
    disabled,
    removeBorder,
    ...rest
}: Props) {
    const colorConverter = useColorFormatConverter()

    const palette = useMemo(() => SEARCHBAR_VARIANTS[variant], [variant])

    const getBorderColor = React.useCallback(
        (isFocused: boolean) => {
            if (isFocused) {
                return "blue.100"
            }
            return "transparent"
        },
        [palette]
    )

    return (
        <InputGroup
            _focusWithin={{
                borderColor: getBorderColor(true),
                ".chakra-input__left-addon": {
                    borderColor: `${palette.active.borderColor} !important`,
                },
                ".chakra-input__right-addon": {
                    borderColor: `${palette.active.borderColor} !important`,
                },
                ".chakra-input": {
                    borderTopColor: `${palette.active.borderColor} !important`,
                    borderBottomColor: `${palette.active.borderColor} !important`,
                    borderLeftColor: `transparent !important`,
                    borderRightColor: `${palette.active.borderColor} !important`,
                },
            }}
            borderColor={getBorderColor(false)}
            borderWidth={removeBorder ? 0 : "1px"}
            borderRadius="8px"
            h={`40px`}
            alignContent={"center"}
            {...rest}
        >
            <InputLeftAddon
                pointerEvents="none"
                h={`38px`}
                borderColor={palette?.normal.borderColor}
                borderTopWidth={removeBorder ? 0 : "1px"}
                borderBottomWidth={removeBorder ? 0 : "1px"}
                borderLeftWidth={removeBorder ? 0 : "1px"}
                borderRightWidth={0}
                bgColor={palette.normal.bg}
                paddingRight={"0 !important"}
            >
                <ReactIcon icon={MdOutlineSearch} size={22} color="black" />
            </InputLeftAddon>
            <Input
                h={`38px`}
                data-testid={`input-${name}`}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                placeholder={placeholder ?? "Search"}
                _placeholder={{ color: palette?.normal.placeholder }}
                fontFamily="Manrope"
                fontSize={"16px"}
                fontStyle="normal"
                fontWeight="500"
                lineHeight="150%"
                letterSpacing="0.15px"
                bgColor={palette.normal.bg}
                color={palette.normal.color}
                borderColor={palette.normal.borderColor}
                _disabled={{
                    borderColor: palette?.disabled.borderColor,
                }}
                _hover={{
                    borderColor: palette?.normal.borderColor,
                }}
                borderTopWidth={removeBorder ? 0 : "1px"}
                borderBottomWidth={removeBorder ? 0 : "1px"}
                borderLeftWidth={0}
                borderRightWidth={removeBorder || value?.length ? 0 : "1px"}
                focusBorderColor="transparent"
                errorBorderColor="transparent"
                _autofill={{
                    WebkitTextFillColor: colorConverter(palette.normal.color),
                    WebkitBoxShadow: `inset 0 0 20px 20px ${
                        palette.normal.bg === "white" || palette.normal.bg === "black"
                            ? palette.normal.bg
                            : colorConverter(palette.normal.bg)
                    }`,
                }}
            />
            {!!value?.length && (
                <InputRightAddon
                    borderColor={palette?.normal.borderColor}
                    borderTopWidth={removeBorder ? 0 : "1px"}
                    borderBottomWidth={removeBorder ? 0 : "1px"}
                    borderRightWidth={removeBorder ? 0 : "1px"}
                    borderLeftWidth={0}
                    h={`38px`}
                    bgColor={palette.normal.bg}
                    paddingLeft={"0 !important"}
                    onClick={() => onChange("")}
                    cursor="pointer"
                >
                    <ReactIcon icon={MdClose} size={22} color="black" />
                </InputRightAddon>
            )}
        </InputGroup>
    )
}

export default Searchbar
