import { Text, Box, Flex, Icon } from "@chakra-ui/react"
import StandardButton, { StandardButtonProps } from "@components/ui/buttons/standard"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { MdChevronRight } from "react-icons/md"
import { useNavigate } from "react-router-dom"

type ButtonProps = {
    button: StandardButtonProps
    link?: never
}

type LinkProps = {
    link: {
        label: string
        href: string
        action?: () => void
    }
    button?: never
}

type Props = {
    title?: string
    text: string
} & (ButtonProps | LinkProps)

export const SETTINGS_ROW_BUTTON_WIDTH = 205

export default function SettingsRow({ title, text, button, link }: Props): JSX.Element {
    const { navigate } = useRestrictedNavigation()

    return (
        <Box>
            {title && (
                <Text textStyle="ManropeSemiboldBody" color="black">
                    {title}
                </Text>
            )}

            <Box
                display="flex"
                flexDir={{ base: "column", sm: "row" }}
                justifyContent={{ base: "flex-start", sm: "space-between" }}
                alignItems="center"
                flexWrap="wrap"
                alignSelf="stretch"
                mt={{ base: 1 }}
            >
                <Box alignSelf="stretch" flex="1" mb={{ base: 0, sm: 2 }}>
                    <Text textStyle="InterRegularBodySmall" color="grey.light.80" whiteSpace="normal">
                        {text}
                    </Text>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    alignSelf={{ base: "flex-end", md: "stretch" }}
                    justifyContent="flex-end"
                    w={`${SETTINGS_ROW_BUTTON_WIDTH}px`}
                    mt={{ base: 2, sm: 0 }}
                >
                    {button ? (
                        <Box onClick={button.onClick} display="flex" alignItems="center">
                            <StandardButton {...button}>{button.children}</StandardButton>
                        </Box>
                    ) : (
                        <Flex
                            alignItems="center"
                            gap={{ base: 1, sm: 3 }}
                            _hover={{
                                textDecorationColor: "blue.100 !important",
                                textDecoration: "underline",
                            }}
                            _active={{
                                textDecorationColor: "blue.70 !important",
                                textDecoration: "underline",
                            }}
                            onClick={() => {
                                if (link.action) {
                                    link.action()
                                } else {
                                    navigate(link.href)
                                }
                            }}
                        >
                            <Text
                                color={"blue.100"}
                                textStyle={{
                                    sm: "ManropeSemiboldBody",
                                    base: "ManropeSemiboldBodySmall",
                                }}
                                cursor="pointer"
                            >
                                {link.label}
                            </Text>
                            <Icon as={MdChevronRight} size={24} color="blue.100" />
                        </Flex>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
