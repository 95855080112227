import { Box, Text, Flex, Icon } from "@chakra-ui/react"
import { useMemo, useState } from "react"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { AiOutlineInfoCircle } from "react-icons/ai"
import Select from "@components/ui/select"
import { useForm } from "react-hook-form"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { IoShieldCheckmarkOutline } from "react-icons/io5"
import CopyComponent from "@components/ui/copyField"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useTranslation } from "react-i18next"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import DashboardFooter from "@components/footer/dashboard"
import { InlineTextLink } from "@components/ui/link"
import useSupport from "@hooks/useSupport"
import { selectAccountDetails } from "@redux/account/selectors"
import { useAppSelector } from "@/store/hooks"
import CloseAccount from "./CloseAccount"
import { TIMEZONE_OPTIONS } from "./types"
import { Option } from "@components/ui/types"
import { useUpdateAccountMutation } from "@redux/account/apiSlice"
import useGenericToast from "@hooks/useGenericToast"
import ScreenContainer from "@components/ui/containers/screen"

type FormData = {
    timezone: Option<string>
}

export default function Profile(): JSX.Element {
    const accountDetails = useAppSelector(selectAccountDetails)
    const { control, watch, handleSubmit } = useForm<FormData>({
        defaultValues: {
            timezone: accountDetails?.timezone
                ? { value: accountDetails?.timezone, label: accountDetails?.timezone }
                : undefined,
        },
    })
    const { t } = useTranslation(["settings", "common"])
    const { navigate } = useRestrictedNavigation()
    const { supportEmail, USSupport, isBusinessUser } = useSupport()
    const [updateAccount, { isLoading }] = useUpdateAccountMutation()
    const { successToast, serverErrorToast } = useGenericToast()

    const onSubmit = async (values: FormData) => {
        await updateAccount({ timezone: values.timezone.value })
            .unwrap()
            .then(() => successToast(t("changesSaved", { ns: "common" })))
            .catch(() => serverErrorToast())
    }

    return (
        <ScreenContainer>
            <BreadCrumbGroup
                breadCrumbs={[
                    {
                        text: t("profile.settings"),
                        hasAfter: true,
                        onClick: () => navigate(ROUTES.DASHBOARD_SETTINGS),
                    },
                    {
                        text: t("profile.titleLower"),
                        hasAfter: false,
                    },
                ]}
                py={2}
            />

            <Text textStyle="ManropeMedium4xLarge">{t("profile.title")}</Text>

            <Box my={8} borderBottomWidth={1} />

            <Flex flexDir="column" alignItems={{ lg: "center" }} gap={8} mb={"1.5rem"}>
                <Flex flexDir="column" gap={4}>
                    <Flex alignItems="stretch" justifyContent="space-between" gap={2}>
                        <Text textStyle="ManropeSemiboldXLarge">
                            {accountDetails?.identity.first_name} {accountDetails?.identity.last_name}
                        </Text>
                        <Flex flexDir="column" justifyContent="flex-end" textAlign="end">
                            <Box>
                                {isBusinessUser && (
                                    <DetailsTypeCard isPersonal={!isBusinessUser} text={t("profile.businessProfile")} />
                                )}
                            </Box>
                            <Text textStyle="InterRegularBody">{accountDetails?.email}</Text>
                            {!isBusinessUser && (
                                <Text textStyle="InterRegularBody">{accountDetails?.identity.phone}</Text>
                            )}
                        </Flex>
                    </Flex>

                    <Box py={1} borderBottomWidth={1} />

                    <Flex alignItems="stretch" gap={2} justifyContent="space-between">
                        <Text textStyle="ManropeSemiboldBody">{t("profile.accountNumber")}</Text>
                        <Text textStyle="ManropeSemiboldBody">{accountDetails?.account_number}</Text>
                    </Flex>

                    <Box py={1} />

                    <Flex alignItems="stretch" gap={2} justifyContent="space-between" pb={4} borderBottomWidth={1}>
                        <Text whiteSpace={"nowrap"} textStyle="ManropeSemiboldBody">
                            {t("profile.address")}
                        </Text>
                        <Flex flexDir="column" justifyContent="flex-end" textAlign="end">
                            <Text textStyle="InterRegularBody">{accountDetails?.identity.address.street_address}</Text>
                            {accountDetails?.identity.address.building_address && (
                                <Text textStyle="InterRegularBody">
                                    {accountDetails?.identity.address.building_address}
                                </Text>
                            )}
                            <Text textStyle="InterRegularBody">
                                {accountDetails?.identity.address.city}, {accountDetails?.identity.address.region}
                            </Text>
                            <Text textStyle="InterRegularBody">{accountDetails?.identity.address.country}</Text>
                            <Text textStyle="InterRegularBody">{accountDetails?.identity.address.postal_code}</Text>
                        </Flex>
                    </Flex>

                    <InfoAlert
                        variant={ENUM_INFO_ALERT_VARIANT.ORANGE}
                        children={
                            <Text>
                                <Text as="span" textStyle="InterRegularBodySmall">
                                    {t("profile.infoText")}&nbsp;
                                </Text>
                                <InlineTextLink
                                    onClick={() =>
                                        window.open(
                                            `mailto:${accountDetails?.country === "CA" ? supportEmail : USSupport}`
                                        )
                                    }
                                    textStyle="InterRegularBodySmall"
                                >
                                    {t("profile.contact")}
                                </InlineTextLink>
                            </Text>
                        }
                        icon={{
                            icon: () => <Icon as={AiOutlineInfoCircle} color={"yellow.light.100"} h="24px" w="24px" />,
                        }}
                    />

                    <Box py={0.5} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex flexDir="column" mb={4}>
                            <Select
                                name="timezone"
                                options={TIMEZONE_OPTIONS}
                                label={t("profile.timezone")}
                                control={control}
                            />
                        </Flex>

                        {accountDetails?.country === "CA" && accountDetails?.features.interac?.secret_question && (
                            <Flex
                                flexDir="column"
                                px={8}
                                py={6}
                                borderRadius="6px"
                                borderColor="grey.light.10"
                                borderWidth="1px"
                                gap={6}
                                alignSelf="stretch"
                                mb={4}
                            >
                                <Flex alignItems="flex-start" flexDir={{ base: "column", md: "row" }} gap={3}>
                                    <Flex
                                        h={{ md: "42px" }}
                                        w={{
                                            base: "100%",
                                            md: "42px",
                                        }}
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems={"center"}
                                        bg="green.light.10"
                                        borderRadius="md"
                                        padding={{
                                            base: "5px 0",
                                            sm: "24px 0",
                                            md: "unset",
                                        }}
                                    >
                                        <ReactIcon
                                            icon={IoShieldCheckmarkOutline}
                                            size={24}
                                            color={"green.light.100"}
                                        />
                                    </Flex>

                                    <Flex flexDir="column">
                                        <Text textStyle="ManropeSemiboldBody">{t("profile.security.title")}</Text>
                                        <Text textStyle="InterRegularBodySmall">{t("profile.security.text")}</Text>
                                    </Flex>
                                </Flex>
                                <Flex
                                    gap={6}
                                    alignSelf="stretch"
                                    flexDir={{ base: "column", md: "row" }}
                                    alignItems="flex-start"
                                    w="100%"
                                >
                                    <Flex flexDir="column" gap={3} flex={1} w="100%">
                                        <Text textStyle="InterRegularBody">{t("profile.security.question")}:</Text>
                                        <CopyComponent text={accountDetails?.features.interac.secret_question} />
                                    </Flex>

                                    <Flex flexDir="column" gap={3} flex={1} w="100%">
                                        <Text textStyle="InterRegularBody">{t("profile.security.answer")}:</Text>
                                        <CopyComponent text={accountDetails?.features.interac.secret_answer} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}

                        <Flex justifyContent="space-between" mb="2rem">
                            <CloseAccount />

                            <StandardButton
                                type="submit"
                                children={t("profile.saveChanges")}
                                isLoading={isLoading}
                                disabled={watch("timezone")?.value === accountDetails?.timezone}
                                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                            />
                        </Flex>
                    </form>
                </Flex>
            </Flex>
            <DashboardFooter />
        </ScreenContainer>
    )
}
