import { Flex, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuOptionGroup, Text } from "@chakra-ui/react"
import IconButton from "@components/ui/buttons/icon"
import { useTranslation } from "react-i18next"
import { MdChevronRight, MdOutlineSettings, MdPayment, MdSettings } from "react-icons/md"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { SETTINGS_DRAWERS } from "@screens/dashboard/settings"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"

export default function MenuSettings() {
    const { t } = useTranslation(["app", "common"])
    const { navigate } = useRestrictedNavigation()
    const account = useAppSelector(selectAccountDetails)

    return (
        <Menu gutter={6}>
            <MenuButton
                as={IconButton}
                key={"Settings"}
                name={"Settings"}
                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_SOLID}
                size="lg"
                icon={MdSettings}
                iconOutline={MdOutlineSettings}
            />
            <MenuList>
                <MenuItem
                    sx={{
                        padding: "0.75rem 1rem",
                        margin: "0 0.5rem",
                        width: "calc(100% - 1rem)",
                        borderRadius: "6px",
                    }}
                    justifyContent={"flex-start !important"}
                    onClick={() => navigate(ROUTES.DASHBOARD_SETTINGS)}
                >
                    <Flex w="full" align="center" justifyContent="space-between">
                        <Text textStyle="ManropeMediumBody">{t("nav.viewAll")}</Text>
                        <Icon as={MdChevronRight} color="black" />
                    </Flex>
                </MenuItem>
                <MenuDivider />
                <MenuOptionGroup
                    color="grey.light.80"
                    textStyle="ManropeSemiboldBodySmall"
                    title={t("nav.savedGroup")}
                    sx={{
                        marginInlineStart: "0.75rem",
                        marginTop: "20px",
                    }}
                />
                <MenuItem
                    sx={{
                        padding: "0.75rem 1rem",
                        margin: "0 0.5rem",
                        width: "calc(100% - 1rem)",
                        borderRadius: "6px",
                    }}
                    onClick={() => navigate(ROUTES.SAVED_ADDRESSES_CRYPTO)}
                >
                    <Text textStyle="ManropeMediumBody">{t("crypto", { ns: "common" })}</Text>
                </MenuItem>
                <MenuItem
                    sx={{
                        padding: "0.75rem 1rem",
                        margin: "0 0.5rem",
                        width: "calc(100% - 1rem)",
                        borderRadius: "6px",
                    }}
                    onClick={() => navigate(ROUTES.SAVED_ADDRESSES_FIAT)}
                >
                    <Text textStyle="ManropeMediumBody">{t("fiat", { ns: "common" })}</Text>
                </MenuItem>
                {account?.country === "CA" && (
                    <>
                        <MenuDivider />
                        <MenuItem
                            sx={{
                                padding: "0.75rem 1rem",
                                margin: "0 0.5rem",
                                width: "calc(100% - 1rem)",
                                borderRadius: "6px",
                            }}
                            onClick={() =>
                                navigate(ROUTES.DASHBOARD_SETTINGS, {
                                    state: { openDrawer: SETTINGS_DRAWERS.BUY_WITH_CREDIT_CARD },
                                })
                            }
                        >
                            <Flex align="center" justifyContent="space-between">
                                <Icon as={MdPayment} color="black" mr="10px" />
                                <Text textStyle="ManropeMediumBody">{t("nav.buyWithCredit")}</Text>
                            </Flex>
                        </MenuItem>
                    </>
                )}
            </MenuList>
        </Menu>
    )
}
