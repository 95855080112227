import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { AccountType } from "@redux/account/types"
import { useMemo } from "react"

export const ZEN_LEDGER_LEARN_MORE = "https://zenledger.io/"
export const KOINLY_LEARN_MORE = "https://koinly.io/"
export const ZEN_LEDGER_REFERRAL = "https://zenledger.io/?ref=netcoins"
export const ZENDESK_NETCOINS_PAY = "https://netcoins.zendesk.com/hc/en-us/categories/4418209751828-Netcoins-Pay"

const useSupport = () => {
    const depositEmail = "deposit@gonetcoins.com"
    const accountDetails = useAppSelector(selectAccountDetails)
    const isCAUser = accountDetails?.country === "CA"
    const isBusinessUser = accountDetails?.type === AccountType.BUSINESS
    const CASupport = "support@netcoins.ca"
    const USSupport = "usa.support@netcoins.com"

    const CABSOnbSupport = "businessonboarding@netcoins.com"
    const USOnbSupport = "usa.onboarding@gonetcoins.com"

    const supportEmail = isCAUser ? CASupport : USSupport

    const onbSupportEmail = useMemo(() => {
        if (isBusinessUser) {
            return isCAUser ? CABSOnbSupport : USOnbSupport
        }

        return isCAUser ? CASupport : USOnbSupport
    }, [isBusinessUser, isCAUser])

    const supportPhoneNumber = "+1-844-515-2646"

    const CAFaqLink = "https://netcoins.zendesk.com/hc/en-us"
    const USFaqLink = "https://netcoinsusa.zendesk.com/hc/en-us"
    const faqLink = isCAUser ? CAFaqLink : USFaqLink

    const tfaInfoLink = `${faqLink}/articles/${isCAUser ? "360050646511" : "10512178264852"}-How-do-I-set-up-2FA-`
    const cadWireBankFeesLink = `${faqLink}/articles/7880963628564-Bank-Wire-Fees-Funding`

    return {
        supportEmail,
        onbSupportEmail,

        supportPhoneNumber,
        faqLink,

        CASupport,
        USSupport,
        CABSOnbSupport,
        USOnbSupport,

        depositEmail,

        tfaInfoLink,
        cadWireBankFeesLink,

        isBusinessUser,
    }
}
export default useSupport
