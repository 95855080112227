import { Text, Flex, Table, Tbody, Tr, Th, Thead, useBreakpointValue, TableContainer, Spinner } from "@chakra-ui/react"
import TablePaginator from "@components/ui/paginator/tablePaginator"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import AuthorizedDeviceRow from "../authorizedDeviceRow"
import {
    useDeleteAuthorizedDeviceMutation,
    useGetAuthorizedDevicesQuery,
    useUpdateAuthorizedDevicesMutation,
} from "@redux/account/apiSlice"
import useGenericToast from "@hooks/useGenericToast"
import DeleteAuthDevice from "../authorizedDeviceRow/deleteAuthDevice"
import EditAuthDevice from "../authorizedDeviceRow/editAuthDevice"

export type AuthorizedDevice = {
    label: string
    type: string
    deviceID: string
    user_agent: string
    authorized_at: string // datetime
}

const ITEMS_PER_PAGE = 10

export default function AuthorizedDeviceTable() {
    const { t } = useTranslation("settings")
    const isMobile = useBreakpointValue({ base: true, md: false })
    const { successToast } = useGenericToast()
    const { data: authorizedDevicesData, isLoading: isAuthorizedDevicesLoading } = useGetAuthorizedDevicesQuery()

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [focusedDevice, setFocusedDevice] = useState<AuthorizedDevice | null>(null)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const handlePreviousPage = () => setCurrentPage((prev) => Math.max(1, prev - 1))
    const handleNextPage = () => setCurrentPage((prev) => Math.min(totalPages, prev + 1))

    useEffect(() => {
        if (authorizedDevicesData?.devices?.length) {
            const num = Math.ceil(authorizedDevicesData.devices.length / ITEMS_PER_PAGE)
            setTotalPages(num ? num : 1)
        }
    }, [authorizedDevicesData])

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
        const endIndex = startIndex + ITEMS_PER_PAGE
        const arr = authorizedDevicesData?.devices.slice(startIndex, endIndex)
        return arr ?? []
    }, [authorizedDevicesData, currentPage])

    function handleDeleteClick(device: AuthorizedDevice) {
        setFocusedDevice(device)
        setIsDeleteModalOpen(true)
    }

    function handleEditClick(device: AuthorizedDevice) {
        setFocusedDevice(device)
        setIsEditModalOpen(true)
    }

    return (
        <Flex flexDir="column" flexGrow={1}>
            <TableContainer flex={1} pos="relative" minHeight="300px">
                <Table>
                    <Thead bg="grey.light.5">
                        <Tr>
                            <Th px="0.5rem" py="0.75rem" w={{ base: "50%", md: "25%" }}>
                                <Text
                                    whiteSpace="normal"
                                    wordBreak="normal"
                                    textStyle="ManropeSemiboldXSmall"
                                    textTransform="none"
                                >
                                    {t(
                                        isMobile
                                            ? "authorizedDevices.authorizedDateAndLabel"
                                            : "authorizedDevices.authorizedAt"
                                    )}
                                </Text>
                            </Th>

                            <Th
                                px={{ base: 0, md: "0.5rem" }}
                                py="0.75rem"
                                w="25%"
                                display={{ base: "none", md: "flex" }}
                            >
                                <Text textStyle="ManropeSemiboldXSmall" textTransform="none">
                                    {t("authorizedDevices.label")}
                                </Text>
                            </Th>

                            <Th verticalAlign={"top"} px={{ base: 0, md: "0.5rem" }} py="0.75rem" w="50%">
                                <Text
                                    whiteSpace="normal"
                                    wordBreak="normal"
                                    textStyle="ManropeSemiboldXSmall"
                                    textTransform="none"
                                >
                                    {t("authorizedDevices.userAgent")}
                                </Text>
                            </Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {paginatedData.map((row) => (
                            <AuthorizedDeviceRow
                                key={row.deviceID}
                                onDeleteClick={handleDeleteClick}
                                onEditClick={handleEditClick}
                                authorizedDevice={row}
                            />
                        ))}
                    </Tbody>
                </Table>
                {isAuthorizedDevicesLoading && (
                    <Flex h="full" flex={1} justify="center" align="center">
                        <Spinner />
                    </Flex>
                )}
            </TableContainer>
            <TablePaginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
            />
            {focusedDevice && isEditModalOpen && (
                <EditAuthDevice
                    toggleDeleteModalOpen={setIsDeleteModalOpen}
                    isEditModalOpen={isEditModalOpen}
                    toggleEditModalOpen={() => setIsEditModalOpen(false)}
                    focusedDevice={focusedDevice}
                    setFocusedDevice={setFocusedDevice}
                />
            )}
            <DeleteAuthDevice
                isOpen={isDeleteModalOpen}
                toggleOpen={() => setIsDeleteModalOpen(false)}
                focusedDevice={focusedDevice}
                setFocusedDevice={setFocusedDevice}
            />
        </Flex>
    )
}
