import { Flex, Text } from "@chakra-ui/react"
import SingleSelect from "@components/ui/select/singleSelect"
import Tabs from "@components/ui/tabs"
import { ENUM_TAB_VARIANTS } from "@components/ui/tabs/types"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Option } from "@components/ui/types"
import AccountStatements from "./AccountStatements"
import NetcoinsPayBanner from "./NetcoinsPayBanner"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { Statement } from "@redux/statements/types"
import {
    useGetKOHOStatementsQuery,
    useGetStatementsQuery,
    useLazyGetKOHOStatementMonthsQuery,
    useLazyGetStatementMonthsQuery,
} from "@redux/statements/apiSlice"

// if january, show previous year because statements are created after the end of the month
const now = new Date(Date.now())
const startingYear = now.getMonth() === 0 ? (now.getFullYear() - 1).toString() : now.getFullYear().toString()

export default function DocumentTable({ selectedIndex = 0 }: { selectedIndex?: 0 | 1 }) {
    const { t } = useTranslation("settings")
    const account = useAppSelector(selectAccountDetails)
    const canNetcoinsPay = useMemo(() => account?.features.ncpay?.accessible, [account])

    const { data: statementYearsData, isLoading: isStatementYearsLoading } = useGetStatementsQuery()
    const [getStatementMonths, { isLoading: isStatementsMonthsLoading }] = useLazyGetStatementMonthsQuery()
    const { data: kohoStatementYearsData, isLoading: isKohoStatementYearsLoading } = useGetKOHOStatementsQuery(
        undefined,
        {
            skip: !canNetcoinsPay,
        }
    )
    const [getKohoStatementMonths, { isLoading: isKohoStatementsMonthsLoading }] = useLazyGetKOHOStatementMonthsQuery()

    const [statements, setStatements] = useState<Record<string, Statement[]>>({})
    const [kohoStatements, setKohoStatements] = useState<Record<string, Statement[]>>({})

    const [index, setIndex] = useState<0 | 1>(selectedIndex)
    const [filter, setFilter] = useState<Option<string>>({
        value: startingYear,
        label: startingYear,
    })

    useEffect(() => {
        if (kohoStatementYearsData?.success && kohoStatementYearsData.years.length) {
            getKohoStatements(kohoStatementYearsData.years)
        }
    }, [kohoStatementYearsData])

    useEffect(() => {
        if (statementYearsData && statementYearsData.success) {
            getStatements(statementYearsData.years)
        }
    }, [statementYearsData])

    async function getKohoStatements(years: Array<string>) {
        const responses = await Promise.all(years.map((year) => getKohoStatementMonths(year)))
        let obj: Record<string, Statement[]> = {}
        responses.forEach((response) => {
            if (response.status === "fulfilled" && response.data.success) {
                obj[response.originalArgs] = response.data.months
            }
        })
        setKohoStatements(obj)
    }

    async function getStatements(years: Array<string>) {
        const responses = await Promise.all(years.map((year) => getStatementMonths(year)))
        let obj: Record<string, Statement[]> = {}
        responses.forEach((response) => {
            if (response.status === "fulfilled" && response.data.success) {
                obj[response.originalArgs] = response.data.months
            }
        })
        setStatements(obj)
    }

    const showNetcoinsPayBanner = useMemo(
        () => account?.features.ncpay.accessible && !account?.features.ncpay.active,
        [account]
    )

    const years = useMemo(() => {
        const years = Object.keys(statements).reverse()
        return years.map((year) => ({
            value: year,
            label: year,
        }))
    }, [statements])

    const yearsKoho = useMemo(() => {
        const years = Object.keys(kohoStatements)
        return years.map((year) => ({
            value: year,
            label: year,
        }))
    }, [kohoStatements])

    const yearOptions = useMemo(() => {
        return index === 0 ? years : yearsKoho
    }, [index, years, yearsKoho])

    const filteredStatements: Statement[] = useMemo(() => {
        return index === 0
            ? statements[filter.value as keyof typeof statements]
            : kohoStatements[filter.value as keyof typeof kohoStatements]
    }, [index, filter, statements, kohoStatements])

    return (
        <Flex flexDir="column" w="100%">
            <Flex
                gap={3}
                w="100%"
                justifyContent={"space-between"}
                flexDir={{ base: "column", md: "row" }}
                alignItems={{ md: "end" }}
                h={{ base: "fit-content", md: "64px" }}
            >
                <Tabs
                    variant={ENUM_TAB_VARIANTS.SECONDARY}
                    tabIndex={index}
                    setTabIndex={(num) => setIndex(num as 0 | 1)}
                    tabs={[
                        t("documents.accountStatement"),
                        ...(canNetcoinsPay ? [t("documents.netcoinsPayStatement")] : []),
                    ]}
                    flex={1}
                    maxW={{ base: "100%", md: "497px" }}
                />

                {(index == 0 || (index == 1 && !showNetcoinsPayBanner)) && (
                    <Flex gap={3} mb={3} alignItems="center" w={{ base: "100%", md: "fit-content" }}>
                        <Text textStyle="ManropeMediumBodySmall" color="bluePurple.100">
                            {t("documents.sortBy")}
                        </Text>
                        <SingleSelect
                            placeholder={t("documents.selectPlaceholder")}
                            options={yearOptions}
                            onSelect={(option) => setFilter(option as Option<string>)}
                            preSelected={filter}
                            flex={1}
                            maxW={"100% !important"}
                            textAlign="center"
                            categoryName="Year"
                        />
                    </Flex>
                )}
            </Flex>

            {(index === 0 || (index === 1 && !showNetcoinsPayBanner)) && (
                <AccountStatements
                    year={filter.value}
                    statements={filteredStatements}
                    isLoading={
                        isKohoStatementYearsLoading ||
                        isStatementYearsLoading ||
                        isKohoStatementsMonthsLoading ||
                        isStatementsMonthsLoading
                    }
                />
            )}
            {index === 1 && showNetcoinsPayBanner && <NetcoinsPayBanner />}
        </Flex>
    )
}
