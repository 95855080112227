import { Flex, Text } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import TextInput from "@components/ui/textInput"
import { AuthorizedDevice } from "../authorizedDeviceTable"
import useGenericToast from "@hooks/useGenericToast"
import { useUpdateAuthorizedDevicesMutation } from "@redux/account/apiSlice"
import ErrorMessage from "@components/ui/errorMessage"

type Props = {
    isEditModalOpen: boolean
    toggleEditModalOpen: () => void
    setFocusedDevice: React.Dispatch<React.SetStateAction<AuthorizedDevice | null>>
    focusedDevice: AuthorizedDevice
    toggleDeleteModalOpen: (_: boolean) => void
}

type FormData = {
    label: string
}

export default function EditAuthDevice({
    isEditModalOpen,
    toggleEditModalOpen,
    focusedDevice,
    setFocusedDevice,
    toggleDeleteModalOpen,
}: Props) {
    const { authorized_at, label, user_agent } = focusedDevice
    const { t } = useTranslation("settings")
    const { t: ct } = useTranslation("common")
    const { successToast } = useGenericToast()
    const [updateAuthorizedDevice, { isLoading: updateLoading }] = useUpdateAuthorizedDevicesMutation()

    const [err, setErr] = useState("")

    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            label,
        },
    })

    async function handleUpdate(values: FormData) {
        await updateAuthorizedDevice({ id: focusedDevice.deviceID, label: values.label })
            .unwrap()
            .then(() => {
                successToast(t("authorizedDevices.updated"))
                setFocusedDevice(null)
                toggleEditModalOpen()
            })
            .catch((e) => {
                const err = Object.values(e.data?.errors)[0]
                setErr((err as string) ?? t("authorizedDevices.error.delete"))
            })
    }

    return (
        <SideDrawer
            isOpen={isEditModalOpen}
            toggleSideDrawerOpen={toggleEditModalOpen}
            header={
                <StandardDrawerHeader title={t("authorizedDevices.editDeviceDetails")} onClose={toggleEditModalOpen} />
            }
            footerButton={{
                children: t("authorizedDevices.saveChanges"),
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                type: "submit",
                form: "edit-device",
                isLoading: updateLoading,
            }}
            extraFooter={
                <Flex flexDir="column" mb="10px" rowGap="10px">
                    {err && <ErrorMessage>{err}</ErrorMessage>}
                    <StandardButton
                        variant={ENUM_BUTTON_VARIANTS.RED_GHOST}
                        children={t("authorizedDevices.deleteDevice")}
                        onClick={() => toggleDeleteModalOpen(true)}
                        w="100%"
                    />
                </Flex>
            }
        >
            <form id="edit-device" onSubmit={handleSubmit(handleUpdate)}>
                <Flex mt={7} flexDir="column" gap={6}>
                    <Text textStyle="InterRegularBody">{t("authorizedDevices.editDeviceDetailsText")}</Text>

                    <TextInput
                        label={t("authorizedDevices.deviceLabel")}
                        control={control}
                        name="label"
                        rules={{ required: ct("error.required", { ns: "common" }) }}
                    />

                    <Flex flexDir="column" gap={3}>
                        <Flex gap={3} justifyContent="space-between">
                            <Text
                                textStyle="ManropeSemiboldBody"
                                whiteSpace="normal"
                                wordBreak="normal"
                                minWidth="120px"
                                width="120px"
                            >
                                {t("authorizedDevices.userAgent")}
                            </Text>

                            <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                                {user_agent}
                            </Text>
                        </Flex>

                        <Flex gap={3} justifyContent="space-between">
                            <Text
                                textStyle="ManropeSemiboldBody"
                                whiteSpace="normal"
                                wordBreak="normal"
                                minWidth="120px"
                                width="120px"
                            >
                                {t("authorizedDevices.authorizedAt")}
                            </Text>

                            <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                                {authorized_at}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </form>
        </SideDrawer>
    )
}
