import React from "react"
import { Box, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import RadioGroup from "@/components/ui/radio"
import Select from "@/components/ui/select"
import { useForm } from "react-hook-form"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import NotNA from "./notNA"
import useFetchWrapper, { Method } from "@/hooks/useFetchWrapper"
import { establishBusinessOnboardingSteps } from "@/util/verificationSteps"
import AmplitudeClient from "@/sdks/amplitude"
import { formatDefaultCountry, formatDefaultRegion, RegionOptions } from "@/util/optionsFormatted"
import NotPermittedState from "./notPermittedState"
import CountriesJSON from "../../../json/countries.json"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import { SubScreenProps } from ".."
import { SurveyData } from "../surveys/types"
import { IAccountDetails } from "@redux/account/types"
import useGenericToast from "@hooks/useGenericToast"

type Props = {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setPhases: React.Dispatch<React.SetStateAction<REG_SCREENS[]>>
    setCurrentPhase: React.Dispatch<React.SetStateAction<{ name?: REG_SCREENS; num?: number }>>
    doSurveyData: (_data: IAccountDetails) => Promise<SurveyData[]>
    countSurveyScreens: (_surveyData: SurveyData[]) => number
    phases: REG_SCREENS[]
} & SubScreenProps

type FormData = {
    country: { value: string; label: string }
    region?: { value: string; label: string }
}

export const BANNED_STATES = ["HI", "NY", "LA"]

export const checkIfBannedState = (country: string, region: string) => {
    if (country !== "US") return false

    return BANNED_STATES.includes(region)
}

export default function whereIncorporated({
    isLoading,
    setUserObj,
    setIsLoading,
    setCurrentPhase,
    phases,
    setPhases,
    userObj,
    doSurveyData,
    countSurveyScreens,
}: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control, watch, getValues, setValue, clearErrors } = useForm<FormData>({
        defaultValues: {
            country: userObj?.country
                ? formatDefaultCountry(userObj?.country as keyof typeof CountriesJSON)
                : { value: "CA", label: t("canada", { ns: "common" }) },
            region: userObj?.region ? formatDefaultRegion(userObj?.country, userObj?.region) : undefined,
        },
    })
    const { fetchWrapper } = useFetchWrapper()
    const { serverErrorToast, errorToast } = useGenericToast()

    const [isOutsideNA, toggleOutsideNA] = React.useState(false)
    const [isNotPermittedState, toggleNotPermittedState] = React.useState(false)

    // Forces region to clear on country change
    React.useEffect(() => {
        // @ts-expect-error Set value as null
        setValue("region", null)
        clearErrors()
    }, [getValues("country"), isOutsideNA])

    function onSubmitForm(values: FormData) {
        if (checkIfBannedState(values.country.value, values.region?.value as string)) {
            toggleNotPermittedState(true)
        } else {
            setIsLoading(true)
            const payload = {
                country: values.country.value,
                region: values.region?.value,
            }
            fetchWrapper("/api/v2/account", Method.POST, payload)
                .then(async (data: { data: IAccountDetails }) => {
                    if (data.data.country === "XX") {
                        toggleOutsideNA(true)
                    } else {
                        const _data = data.data as IAccountDetails
                        const _surveys = await doSurveyData(_data)
                        const surveyCount = countSurveyScreens(_surveys)
                        const newPhases: REG_SCREENS[] = establishBusinessOnboardingSteps(_data, surveyCount)
                        const prevPhasesLength = phases.length
                        setPhases((prev) => [...prev, ...newPhases])
                        setUserObj(_data)
                        setTimeout(() => {
                            // Start at the beginnging of the new phases even though its concattenated with the previous phases
                            setCurrentPhase({ name: newPhases[0], num: prevPhasesLength })
                        }, 0)
                        AmplitudeClient.logEvent(
                            REG_SCREENS.WhereIncorporated,
                            REGISTRATION_STEP.BUSINESS_WHERE_INCORPORATED
                        )
                    }
                })
                .catch((e) => {
                    let desc
                    if (e.status === 409) {
                        desc = t("whereIncorporated.errorCountryChange")
                        errorToast(desc)
                    }
                })
                .finally(() => setIsLoading(false))
        }
    }

    const COUNTRY_OPTIONS = React.useMemo(
        () => [
            { value: "CA", label: t("canada", { ns: "common" }) },
            { value: "US", label: t("usa", { ns: "common" }) },
            { value: "XX", label: t("other", { ns: "common" }) },
        ],
        [t]
    )

    return (
        <Box w="full">
            {isOutsideNA ? (
                <NotNA />
            ) : isNotPermittedState ? (
                <NotPermittedState
                    toastServerError={serverErrorToast}
                    region={watch("region")?.value as "NY" | "HI" | "LA"}
                    email={userObj?.email as string}
                    goBack={() => toggleNotPermittedState(false)}
                />
            ) : (
                <>
                    <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                        {t("whereIncorporated.title")}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                        {t("whereIncorporated.question")}
                    </Text>
                    <form onSubmit={handleSubmit(onSubmitForm)}>
                        <RadioGroup
                            control={control}
                            name="country"
                            options={COUNTRY_OPTIONS}
                            defaultOption={{ value: "CA", label: t("canada", { ns: "common" }) }}
                            isHorizontal={false}
                            mb="1.5rem"
                            isDisabled={!!userObj?.country}
                        />
                        <Select
                            key={"region-component-" + watch("country")?.value}
                            name="region"
                            label={
                                watch("country")?.value === "CA"
                                    ? t("whereReside.selectProvince")
                                    : t("whereReside.selectState")
                            }
                            options={watch("country")?.value === "CA" ? RegionOptions.provinces : RegionOptions.states}
                            control={control}
                            rules={
                                watch("country")?.value === "XX"
                                    ? { required: false }
                                    : { required: t("error.required", { ns: "common" }) }
                            }
                            disabled={!watch("country")?.value || watch("country")?.value === "XX"}
                        />
                        <StandardButton
                            data-testid="continue-button"
                            isLoading={isLoading}
                            type="submit"
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                            mt="3rem"
                            w="full"
                        >
                            {t("continue", { ns: "common" })}
                        </StandardButton>
                    </form>
                </>
            )}
        </Box>
    )
}
