import {
    Box,
    Td,
    Text,
    Tr,
    Image,
    useBreakpointValue,
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
} from "@chakra-ui/react"
import IconButton from "@components/ui/buttons/icon"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { MdOutlineEdit, MdEdit } from "react-icons/md"
import { FaRegTrashAlt, FaTrash } from "react-icons/fa"
import { FiMoreVertical } from "react-icons/fi"
import { useTranslation } from "react-i18next"
import { imageMap } from "@assets/svgs/coins"
import IconMenu from "@components/ui/menus/icon"

type Props = {
    coin: string
    symbol: string
    alert: string
    onEditClick: () => void
    onDeleteClick: () => void
}

export default function AlertRow({ coin, symbol, alert, onEditClick, onDeleteClick }: Props): JSX.Element {
    const { t } = useTranslation("common")
    const image = imageMap[symbol.toLowerCase() as keyof typeof imageMap]

    const isMobile = useBreakpointValue({ base: true, md: false })

    return (
        <Tr w="full">
            {isMobile ? (
                <>
                    <Td display="flex" flexDir="column" gap={1} px={2} py={3}>
                        <Flex alignItems="center" gap={3}>
                            <Box>
                                <Image
                                    src={image}
                                    alt={"coin" + symbol.toLowerCase()}
                                    height="42px"
                                    width="42px"
                                    borderRadius={"50%"}
                                />
                            </Box>

                            <Flex gap={2} alignItems="center">
                                <Text textStyle="ManropeSemiboldBodySmall">{coin}</Text>
                                <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                                    {symbol}
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex gap={2} alignItems="center" w="100%">
                            <Text
                                whiteSpace="normal"
                                wordBreak="normal"
                                textStyle="ManropeSemiboldBodySmall"
                                py={2}
                                flex="1"
                            >
                                {alert}
                            </Text>

                            <IconMenu
                                items={[
                                    { text: t("edit"), onClick: onEditClick },
                                    { text: t("delete"), onClick: onDeleteClick },
                                ]}
                            />
                        </Flex>
                    </Td>
                </>
            ) : (
                <>
                    <Td width="200px" px="1.5rem">
                        <Flex alignItems="center" gap={3}>
                            <Box>
                                <Image
                                    src={image}
                                    alt={"coin" + symbol.toLowerCase()}
                                    height="42px"
                                    width="42px"
                                    borderRadius={"50%"}
                                />
                            </Box>

                            <Flex gap={2} alignItems="center">
                                <Text textStyle="ManropeSemiboldBodySmall" lineHeight={1}>
                                    {coin}
                                </Text>
                                <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50" lineHeight={1}>
                                    {symbol}
                                </Text>
                            </Flex>
                        </Flex>
                    </Td>

                    <Td>
                        <Text whiteSpace="normal" wordBreak="normal" textStyle="ManropeSemiboldBodySmall">
                            {alert}
                        </Text>
                    </Td>

                    <Td width="92px">
                        <Flex alignItems="center" justifyContent="flex-end">
                            <IconButton
                                name="edit"
                                onClick={onEditClick}
                                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_GHOST}
                                iconOutline={MdOutlineEdit}
                                icon={MdEdit}
                            />

                            <IconButton
                                name="delete"
                                onClick={onDeleteClick}
                                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_GHOST}
                                iconOutline={FaRegTrashAlt}
                                icon={FaTrash}
                            />
                        </Flex>
                    </Td>
                </>
            )}
        </Tr>
    )
}
