import { Box, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { MdOutlineVerifiedUser } from "react-icons/md"

type Props = {}

export default function DisableRoot({}: Props) {
    const { t } = useTranslation(["twoFA"])

    return (
        <Box mt={"1.725rem"}>
            <Flex
                columnGap="1.5rem"
                rowGap="0.5rem"
                align={{ base: "flex-start", sm: "center" }}
                mb="1.5rem"
                flexDir={{ base: "column", sm: "row" }}
            >
                <Flex
                    justifyContent={"center"}
                    align="center"
                    w="96px"
                    py={"1rem"}
                    borderRadius={"md"}
                    bgColor={"green.light.10"}
                >
                    <ReactIcon icon={MdOutlineVerifiedUser} color="green.light.100" size={24} />
                </Flex>
                <Text textStyle={"ManropeMediumBody"}>{t("twoFASetup.disabledSubtitle")}</Text>
            </Flex>
            <Text textStyle={"ManropeSemiboldBody"} mb={"0.5rem"}>
                {t("twoFASetup.disabledDisclaimer1")}
            </Text>
            <Text textStyle={"InterRegularBodySmall"} mb={"1.725rem"}>
                {t("twoFASetup.disabledDisclaimer2")}
            </Text>
        </Box>
    )
}
