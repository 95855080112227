import { Flex, Text, Button, Box } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

export type Props = {
    currentPage: number
    totalPages: number
    onPreviousPage: () => void
    onNextPage: () => void
}

const BUTTON_WIDTH = "80px"

export default function TablePaginator({ currentPage, totalPages, onPreviousPage, onNextPage }: Props) {
    const { t } = useTranslation("common")

    return (
        <Flex width="100%" justifyContent="space-between" alignItems="center" py="1rem" pos="relative">
            <Box w={BUTTON_WIDTH}>
                <Button
                    onClick={onPreviousPage}
                    isDisabled={currentPage === 1}
                    variant="outline"
                    px={2}
                    py={1}
                    size={"sm"}
                    textStyle={"ManropeSemiboldBodySmall"}
                >
                    {t("prev")}
                </Button>
            </Box>
            <Text textStyle={"ManropeSemiboldBodySmall"}>
                {t("pageOf", { current: currentPage, total: totalPages })}
            </Text>
            <Flex w={BUTTON_WIDTH} justify="end">
                <Button
                    onClick={onNextPage}
                    isDisabled={currentPage === totalPages}
                    variant="outline"
                    px={2}
                    py={1}
                    size={"sm"}
                    textStyle={"ManropeSemiboldBodySmall"}
                >
                    {t("next")}
                </Button>
            </Flex>
        </Flex>
    )
}
