import React, { useCallback, useEffect, useMemo } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import zxcvbn from "zxcvbn"
import { useTranslation } from "react-i18next"

type Props = {
    password: string
    isLight: boolean
}

export default function PasswordMeter({ password, isLight }: Props) {
    const { t } = useTranslation("settings", { keyPrefix: "profile" })

    const [strength, setStrength] = React.useState<zxcvbn.ZXCVBNResult>()

    useEffect(() => setStrength(zxcvbn(password ?? "")), [password])

    const strengthColour = useCallback(
        (reqStrength: number) => {
            if (!password?.length) return `grey.${isLight ? "light" : "dark"}.10`

            const num = Number(strength?.score)
            if (num >= reqStrength) {
                switch (num) {
                    case 0:
                    case 1:
                        return `red.${isLight ? "light" : "dark"}.100`
                    case 2:
                        return `yellow.${isLight ? "light" : "dark"}.100`
                    case 3:
                    case 4:
                        return `green.${isLight ? "light" : "dark"}.100`
                }
            }
            return `grey.${isLight ? "light" : "dark"}.10`
        },
        [strength]
    )

    const StrengthText = useMemo(() => {
        if (!password?.length) return ""

        switch (Number(strength?.score)) {
            case 0:
            case 1:
                return t("weakPass")
            case 2:
                return t("mediumPass")
            case 3:
            case 4:
                return t("strongPass")
            default:
                return ""
        }
    }, [strength])

    return (
        <Flex h="21px" w="full" alignItems="center" justifyContent={"space-between"} columnGap="0.5rem">
            <Flex columnGap=".5rem">
                <Box w={{ base: "3rem", sm: "4rem" }} h="6px" borderRadius={"3px"} bgColor={strengthColour(0)} />
                <Box w={{ base: "3rem", sm: "4rem" }} h="6px" borderRadius={"3px"} bgColor={strengthColour(2)} />
                <Box w={{ base: "3rem", sm: "4rem" }} h="6px" borderRadius={"3px"} bgColor={strengthColour(3)} />
            </Flex>
            <Text textAlign="end" textStyle="ManropeSemiboldNano" color={isLight ? "initial" : "white"}>
                {StrengthText}
            </Text>
        </Flex>
    )
}
