import { Box, Button, Flex, Table, TableContainer, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react"
import FilterDrawer from "@components/filterDrawer"
import DashboardFooter from "@components/footer/dashboard"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import Checkbox from "@components/ui/checkbox"
import MultiSelect from "@components/ui/select/multiselect"
import TablePaginator from "@components/ui/paginator/tablePaginator"
import SingleSelect from "@components/ui/select/singleSelect"
import { Option } from "@components/ui/types"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import {
    useDownloadTransactionsMutation,
    useGetTransactionsQuery,
    useLazyGetTransactionsQuery,
} from "@redux/transactions/apiSlice"
import { CsvExportType, Transaction } from "@redux/transactions/types"
import { buildRouteWithParams } from "@routing/route-utils"
import { ROUTES } from "@routing/routes"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClose, MdOutlineSearch } from "react-icons/md"
import { RiFilter3Fill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import DrawerTransactionDetails from "./drawerTransactionDetails"
import TransactionCard from "./transactionCard"
import useRegion from "@hooks/useRegion"
import ScreenContainer from "@components/ui/containers/screen"
import PlainModal from "@components/ui/modals/plain"
import useGenericToast from "@hooks/useGenericToast"
import { saveAs } from "file-saver"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import DatePicker from "@components/ui/datePicker"
import { formatRangeDate } from "@util/dateFormating"
import { Td } from "@chakra-ui/react"

const SortByOptions = [
    { value: "desc", label: "Newest to Oldest" },
    { value: "asc", label: "Oldest to Newest" },
]

const OrderTypeOptions: Option<string>[] = [
    { value: "buy", label: "Buy Orders" },
    { value: "sell", label: "Sell Orders" },
    { value: "deposit", label: "Deposits" },
    { value: "withdraw", label: "Withdrawals" },
    { value: "reward", label: "Rewards" },
    { value: "stake", label: "Staking" },
]

const CsvExportTypeOptions: Option<CsvExportType>[] = [
    { value: CsvExportType.Default, label: "Default" },
    { value: CsvExportType.Koinly, label: "Koinly" },
    { value: CsvExportType.Zenledger, label: "Zenledger" },
]

const generateYearOptions = (fromYear: number): Option<string>[] => {
    const currentYear = new Date().getFullYear()
    const endYear = currentYear - 1
    const yearOptions = []

    for (let year = endYear; year >= fromYear; year--) {
        yearOptions.push({ value: year.toString(), label: year.toString() })
    }

    return yearOptions
}

export const Transactions: React.FC = () => {
    const { asset } = useParams()
    const { t } = useTranslation(["app", "common"])
    const { navigate } = useRestrictedNavigation()
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [processingState, setProcessingState] = useState(false)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [oldestExportYear, setOldestExportYear] = useState<number>(new Date().getFullYear() - 1)

    const [isExporting, setIsExporting] = useState<"email" | "download" | null>(null)
    const [sortByOption, setSortByOption] = useState<Option<string> | null>(SortByOptions[0])
    const [selectedOrderTypeOptions, setSelectedOrderTypeOptions] = useState<Option<string>[]>([])
    const [selectedCoinOptions, setSelectedCoinOptions] = useState<Option<string>[]>([])
    const [exportOption, setExportOption] = useState<Option<CsvExportType>>(CsvExportTypeOptions[0])
    const [selectedExportYear, setSelectedExportYear] = useState<Option<string> | null>(null)

    const accountEmail = useAppSelector(selectAccountDetails)?.email

    const koinlyYearOptions = useMemo(
        () => generateYearOptions(oldestExportYear < 2022 ? 2022 : oldestExportYear),
        [oldestExportYear]
    )
    const zenledgerYearOptions = useMemo(
        () => generateYearOptions(oldestExportYear < 2024 ? 2024 : oldestExportYear),
        [oldestExportYear]
    )

    const { serverErrorToast, successToast } = useGenericToast()

    const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)

    const { getCurrencyOptions } = useRegion()

    const onFilterDrawerOpen = () => setFilterDrawerOpen(true)
    const toggleSideDrawerOpen = () => setFilterDrawerOpen(!isFilterDrawerOpen)

    const [exportModal, setExportModalOpen] = useState(false)
    const cancelRef = useRef<HTMLButtonElement>(null)

    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(1, prev - 1))
    }

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(totalPages, prev + 1))
    }

    const rangeFilter = useMemo(() => {
        if (startDate && !endDate) {
            const startFormattedDate = formatRangeDate(startDate)
            const endFormattedDate = formatRangeDate(new Date())
            return `${startFormattedDate},${endFormattedDate}`
        }
        if (!startDate && endDate) {
            const startFormattedDate = formatRangeDate(new Date(1999))
            const endFormattedDate = formatRangeDate(endDate)
            return `${startFormattedDate},${endFormattedDate}`
        }
        if (startDate && endDate) {
            const startFormattedDate = formatRangeDate(startDate)
            const endFormattedDate = formatRangeDate(endDate)
            return `${startFormattedDate},${endFormattedDate}`
        }
        return ""
    }, [startDate, endDate])

    const typesFilter = useMemo(() => {
        if (selectedOrderTypeOptions.length === 0) {
            return ""
        }
        return selectedOrderTypeOptions.map((option) => option.value).join(",")
    }, [selectedOrderTypeOptions])

    const assetFilter = useMemo(() => {
        if (selectedCoinOptions.length === 0) {
            return ""
        }
        return selectedCoinOptions.map((option) => option.value).join(",")
    }, [selectedCoinOptions])

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)
    const { data: transactionsData, isLoading } = useGetTransactionsQuery({
        assets: assetFilter,
        types: typesFilter,
        page: currentPage,
        statuses: processingState ? "Processing,Pending" : "",
        limit: 10,
        sort: sortByOption?.value as "asc" | "desc",
        range: rangeFilter,
    })
    const [getTransactionsOnDemand] = useLazyGetTransactionsQuery()

    const updateOldestExportYear = useCallback(async () => {
        const transactions = await getTransactionsOnDemand({
            assets: assetFilter,
            types: typesFilter,
            limit: 1,
            page: 1,
            sort: "asc",
            statuses: "Delivered",
        }).unwrap()

        if (transactions.total <= 0) return

        const transDate = transactions.transactions[0].created_at_pst
        const year = new Date(transDate).getFullYear()

        setOldestExportYear(year)
    }, [assetFilter, typesFilter])

    useEffect(() => {
        updateOldestExportYear()
    }, [assetDetailsData, assetFilter, typesFilter])

    const [downloadCsv] = useDownloadTransactionsMutation()

    const assetOptions: Option<string>[] = useMemo(() => {
        let assetOptions: Option<string>[] = []
        if (assetDetailsData) {
            assetOptions = Object.keys(assetDetailsData).map((key) => ({
                value: key,
                label: assetDetailsData[key].name,
                desc: assetDetailsData[key].symbol,
            }))
        }

        const currencyOptions = getCurrencyOptions()
        return [...assetOptions, ...currencyOptions]
    }, [assetDetailsData])

    useMemo(() => {
        if (transactionsData) {
            setTotalPages(Math.ceil(transactionsData.total / 10))
        }
    }, [transactionsData])

    const selectedAsset = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const navigateToTrade = () => {
        navigate(ROUTES.DASHBOARD_TRADE)
    }

    const navigateToMarketDetails = () => {
        navigate(buildRouteWithParams(ROUTES.MARKET_DETAILS, { asset: selectedAsset?.symbol || "" }))
    }

    const clearFilters = () => {
        setSelectedOrderTypeOptions([])
        setSelectedCoinOptions([])
        setProcessingState(false)
        setStartDate(null)
        setEndDate(null)
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [selectedOrderTypeOptions, selectedCoinOptions, processingState, sortByOption])

    const setInitialCoinSelection = () => {
        const selectedCoinOption = assetOptions.find((coin) => coin.value === asset)
        setSelectedCoinOptions(selectedCoinOption ? [selectedCoinOption] : [])
    }

    useEffect(() => {
        setInitialCoinSelection()
    }, [])

    const getRangeFilter = () => {
        if (exportOption.value === CsvExportType.Default) {
            return rangeFilter
        }

        const startOfYear = new Date(`${selectedExportYear?.value}-01-01`)
        const endOfYear = new Date(`${selectedExportYear?.value}-12-31`)

        const startFormattedDate = formatRangeDate(startOfYear)
        const endFormattedDate = formatRangeDate(endOfYear)

        return `${startFormattedDate},${endFormattedDate}`
    }

    const handleExport = async (email = false) => {
        setIsExporting(email ? "email" : "download")

        try {
            const res = await downloadCsv({
                exportType: exportOption.value,
                assets: assetFilter,
                sort: sortByOption?.value as "asc" | "desc",
                types: typesFilter,
                email: email,
                range: getRangeFilter(),
            }).unwrap()
            if (!email) {
                const csvBlob = new Blob([res], { type: "text/csv" })
                saveAs(csvBlob, "transactions.csv")
            } else {
                successToast(t("transactions.emailSent", { email: accountEmail }))
            }
        } catch (error) {
            serverErrorToast()
        } finally {
            setIsExporting(null)
            setExportModalOpen(false)
            setExportOption(CsvExportTypeOptions[0])
        }
    }

    const filtersApplied = useMemo(() => {
        return (
            selectedOrderTypeOptions.length > 0 ||
            selectedCoinOptions.length > 0 ||
            processingState ||
            startDate ||
            endDate
        )
    }, [selectedOrderTypeOptions, selectedCoinOptions, processingState, startDate, endDate])

    const openExportModal = () => {
        setExportOption(CsvExportTypeOptions[0])
        setExportModalOpen(true)
        setSelectedExportYear(null)
    }

    const renderedTransactions = useMemo(() => {
        return transactionsData?.transactions.map((transaction, index) => (
            <TransactionCard
                key={`${transaction.created_at}-${index}`}
                transaction={transaction}
                viewMore={() => setSelectedTransaction(transaction)}
                isLast={index === transactionsData.transactions.length - 1}
            />
        ))
    }, [transactionsData?.transactions, setSelectedTransaction])

    return (
        <ScreenContainer>
            <Box display={"flex"} flexDir={"column"} h="full">
                <FilterDrawer
                    isOpen={isFilterDrawerOpen}
                    toggleSideDrawerOpen={toggleSideDrawerOpen}
                    orderTypes={OrderTypeOptions}
                    coins={assetOptions}
                    selectedOrderTypes={selectedOrderTypeOptions}
                    setSelectedOrderTypes={setSelectedOrderTypeOptions}
                    selectedCoins={selectedCoinOptions}
                    setSelectedCoins={setSelectedCoinOptions}
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                />
                <BreadCrumbGroup
                    mb="0.5rem"
                    breadCrumbs={[
                        { text: t("trade.trade"), onClick: navigateToTrade },
                        { text: selectedAsset?.name || "", onClick: navigateToMarketDetails },
                        { text: t("transactions.transactions") },
                    ]}
                />
                <Box display={"flex"} flexDir={"column"} height={"full"} flexGrow={1}>
                    <Box
                        display="flex"
                        flexDir={{ base: "column", md: "column", lg: "row" }}
                        rowGap="1rem"
                        columnGap="1.5rem"
                        mb="1.5rem"
                        alignItems={{ base: "normal", md: "flex-end", lg: "flex-end" }}
                        whiteSpace={"normal"}
                    >
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection={{ base: "column", md: "column", lg: "row" }}
                            alignItems="center"
                            justifyContent={"space-between"}
                            rowGap={"1rem"}
                        >
                            <Box flex={1}>
                                <Text textStyle="ManropeMedium4xLarge" mb={2}>
                                    {t("transactions.title")}
                                </Text>
                                <Text textStyle={"InterRegularBodySmall"}>{t("transactions.subTitle")}</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={{ base: "flex-start", md: "center" }}
                        justifyContent={"space-between"}
                    >
                        <Box display={{ base: "flex", lg: "none" }}>
                            <Button
                                onClick={onFilterDrawerOpen}
                                variant="outline"
                                borderColor="gray.200"
                                borderRadius="md"
                                py={2}
                                px={4}
                                bg="white"
                                _hover={{ bg: "gray.50" }}
                                width="auto"
                            >
                                <Flex align="center">
                                    <RiFilter3Fill size={14} />
                                    <Text ml={2} textStyle={"ManropeMediumBodySmall"}>
                                        {t("common:filters")}
                                    </Text>
                                </Flex>
                            </Button>
                        </Box>
                        <Box display={{ base: "none", lg: "flex" }} flexDirection={"row"} alignItems={"center"}>
                            <Box mr={6}>
                                <Text textStyle={"ManropeMediumBodySmall"}>{t("common:filterBy")}</Text>
                            </Box>
                            <Box mr={3}>
                                <MultiSelect
                                    selectedOptions={selectedOrderTypeOptions}
                                    options={OrderTypeOptions}
                                    placeholder={t("transactions.orderType")}
                                    setSelectedOptions={setSelectedOrderTypeOptions}
                                />
                            </Box>
                            <Box mr={3}>
                                <MultiSelect
                                    selectedOptions={selectedCoinOptions}
                                    options={assetOptions}
                                    setSelectedOptions={setSelectedCoinOptions}
                                    placeholder={t("common:assets")}
                                    search
                                />
                            </Box>
                        </Box>
                        <Box display={{ base: "block", md: "flex" }} flexDirection={"row"} alignItems={"center"}>
                            <Box mr={3} display={{ base: "none", lg: "block" }}>
                                <Text textStyle={"ManropeMediumBodySmall"}>{t("common:sortBy")}</Text>
                            </Box>
                            <Box>
                                <SingleSelect
                                    options={SortByOptions}
                                    preSelected={sortByOption || undefined}
                                    placeholder="Sort By"
                                    title={t("common:sortBy")}
                                    onSelect={setSortByOption}
                                    shouldIgnoreMobileView
                                />
                            </Box>
                            <Box
                                display={{ base: "block", lg: "none" }}
                                mt={{ base: 3, md: 0 }}
                                ml={{ base: 0, md: 3 }}
                            >
                                <StandardButton
                                    w="full"
                                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                                    children={"Export"}
                                    size={"md"}
                                    onClick={openExportModal}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        display={{ base: "none", lg: "flex" }}
                        mt={4}
                    >
                        <Flex alignItems={"center"} gap={2}>
                            <DatePicker
                                selectedDate={startDate}
                                label={t("transactions.startDate")}
                                maxDate={endDate}
                                onDateChange={(date) => setStartDate(date)}
                            />
                            <Text>-</Text>
                            <DatePicker
                                selectedDate={endDate}
                                minDate={startDate}
                                label={t("transactions.endDate")}
                                onDateChange={(date) => setEndDate(date)}
                            />
                            <Box ml={2} mr={1}>
                                <Checkbox
                                    text="Processing"
                                    setValue={() => setProcessingState(!processingState)}
                                    isChecked={processingState}
                                />
                            </Box>
                            {filtersApplied && (
                                <Box>
                                    <StandardButton
                                        flexGrow={1}
                                        type="button"
                                        leftIcon={MdClose}
                                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_GHOST}
                                        onClick={clearFilters}
                                        size="md"
                                        textStyle="ManropeMediumBodySmall"
                                    >
                                        {t("common:clearFilters")}
                                    </StandardButton>
                                </Box>
                            )}
                        </Flex>
                        <StandardButton
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                            children={"Export"}
                            size={"md"}
                            onClick={openExportModal}
                        />
                    </Flex>
                    <Flex flexDir="column" flexGrow={1} mt={9}>
                        <TableContainer
                            flexGrow={1}
                            overflowY="auto"
                            pos="relative"
                            minHeight="300px"
                            display="flex"
                            flexDir="column"
                            justifyContent={"space-between"}
                        >
                            <Table variant="simple" layout="fixed" w="full" pos="relative">
                                <Thead w="full" backgroundColor={"grey.light.10"}>
                                    <Tr
                                        sx={{
                                            "& th": {
                                                textTransform: "none",
                                            },
                                        }}
                                        borderBottom="1px solid #636366"
                                    >
                                        <Th px={2} display={{ base: "none", md: "table-cell", lg: "table-cell" }}>
                                            <Text textStyle={"ManropeSemiboldXSmall"}>{t("common:date")}</Text>
                                        </Th>
                                        <Th colSpan={2} px={2}>
                                            <Text textStyle={"ManropeSemiboldXSmall"}>
                                                {t("transactions.transaction")}
                                            </Text>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody w="full" h="full">
                                    {renderedTransactions}
                                </Tbody>
                            </Table>
                            <Box
                                w="full"
                                display="block"
                                position="absolute"
                                top="40%"
                                left="50%"
                                transform="translate(-50%,-50%)"
                            >
                                {isLoading ? (
                                    <Text textAlign="center">{t("common:loading")}...</Text>
                                ) : !transactionsData?.transactions?.length ? (
                                    <Box textAlign={"center"}>
                                        <Box
                                            display="inline-block"
                                            p="1rem"
                                            borderRadius={"0.5rem"}
                                            bgColor="grey.dark.10"
                                            mb="0.5rem"
                                        >
                                            <MdOutlineSearch size={24} color={"#48484A"} />
                                        </Box>
                                        <Text>{t("common:noResults")}</Text>
                                    </Box>
                                ) : null}
                            </Box>
                            {totalPages > 1 && (
                                <TablePaginator
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPreviousPage={handlePreviousPage}
                                    onNextPage={handleNextPage}
                                />
                            )}
                        </TableContainer>
                        <DashboardFooter />
                    </Flex>
                </Box>
                <DrawerTransactionDetails
                    transaction={selectedTransaction}
                    isOpen={!!selectedTransaction}
                    toggleSideDrawerOpen={() => setSelectedTransaction(null)}
                />
            </Box>
            <PlainModal
                isOpen={exportModal}
                cancelRef={cancelRef}
                onClose={() => setExportModalOpen(false)}
                title={t("transactions.title")}
                buttons={[
                    {
                        children: t("transactions.emailMe"),
                        onClick: () => handleExport(true),
                        variant: ENUM_BUTTON_VARIANTS.SECONDARY_GHOST,
                        isLoading: isExporting === "email",
                    },
                    {
                        children: t("transactions.export"),
                        onClick: () => handleExport(),
                        variant: ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_SOLID,
                        isLoading: isExporting === "download",
                    },
                ]}
            >
                <>
                    <Box py={5} px={1}>
                        <Text textStyle={"InterRegularBody"}>{t("transactions.pleaseSelectFormat")}</Text>
                        <Box mt={6}>
                            <SingleSelect
                                options={CsvExportTypeOptions}
                                preSelected={exportOption}
                                onSelect={(option) => setExportOption(option as Option<CsvExportType>)}
                                shouldIgnoreMobileView
                            />
                        </Box>
                        {exportOption.value != CsvExportType.Default && (
                            <Box mt={6}>
                                <Text textStyle={"ManropeRegularBodySmall"}>{t("transactions.selectYear")}</Text>
                                <Box mt={1}>
                                    <SingleSelect
                                        options={
                                            exportOption.value == CsvExportType.Koinly
                                                ? koinlyYearOptions
                                                : zenledgerYearOptions
                                        }
                                        preSelected={
                                            exportOption.value == CsvExportType.Koinly
                                                ? koinlyYearOptions[0]
                                                : zenledgerYearOptions[0]
                                        }
                                        placeholder={t("common:assets")}
                                        onSelect={setSelectedExportYear}
                                        shouldIgnoreMobileView
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box borderBottomWidth={1} borderBottomColor={"grey.light.10"} mt={5} />
                </>
            </PlainModal>
        </ScreenContainer>
    )
}
