import { useState, useEffect, useMemo } from "react"
import { Box, Flex, Stack, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import { Option } from "@components/ui/types"
import OptionCheckBox from "@components/ui/filterOptions/checkBox"
import Searchbar from "@components/ui/searchbar"
import SideDrawer from "@components/sideDrawer"
import FilterDrawerHeader from "@components/sideDrawer/headers/filterDrawerHeader"
import DatePicker from "@components/ui/datePicker"

type FilterDrawerProps<T extends string | number> = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    orderTypes?: Option<T>[]
    coins: Option<T>[]
    selectedOrderTypes?: Option<T>[]
    setSelectedOrderTypes?: (options: Option<T>[]) => void
    selectedCoins: Option<T>[]
    setSelectedCoins: (options: Option<T>[]) => void
    startDate?: Date | null
    endDate?: Date | null
    onStartDateChange?: (date: Date | null) => void
    onEndDateChange?: (date: Date | null) => void
}

export default function FilterDrawer<T extends string | number>({
    isOpen,
    toggleSideDrawerOpen,
    orderTypes,
    coins,
    selectedOrderTypes,
    setSelectedOrderTypes,
    selectedCoins,
    setSelectedCoins,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
}: FilterDrawerProps<T>) {
    const { t } = useTranslation(["app", "common"])

    const [localOrderTypes, setLocalOrderTypes] = useState<Option<T>[]>(selectedOrderTypes || [])
    const [localCoins, setLocalCoins] = useState<Option<T>[]>(selectedCoins)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [localStartDate, setLocalStartDate] = useState<Date | null>(startDate || null)
    const [localEndDate, setLocalEndDate] = useState<Date | null>(endDate || null)

    useEffect(() => {
        if (isOpen) {
            setLocalOrderTypes(selectedOrderTypes || [])
            setLocalCoins(selectedCoins)
            setLocalStartDate(startDate || null)
            setLocalEndDate(endDate || null)
        }
    }, [isOpen, selectedOrderTypes, selectedCoins, startDate, endDate])

    const filteredCoins = useMemo(
        () => coins.filter((coin) => coin.label.toLowerCase().includes(searchTerm.toLowerCase())),
        [coins, searchTerm]
    )

    const handleToggleOrderType = (value: Option<T>) => {
        if (localOrderTypes.some((item) => item.value === value.value)) {
            setLocalOrderTypes(localOrderTypes.filter((item) => item.value !== value.value))
        } else {
            setLocalOrderTypes([...localOrderTypes, value])
        }
    }

    const handleToggleCoin = (value: Option<T>) => {
        if (localCoins.some((item) => item.value === value.value)) {
            setLocalCoins(localCoins.filter((item) => item.value !== value.value))
        } else {
            setLocalCoins([...localCoins, value])
        }
    }

    const handleSave = () => {
        if (setSelectedOrderTypes) {
            setSelectedOrderTypes(localOrderTypes)
        }
        setSelectedCoins(localCoins)
        toggleSideDrawerOpen()
        if (onStartDateChange) {
            onStartDateChange(localStartDate)
        }
        if (onEndDateChange) {
            onEndDateChange(localEndDate)
        }
    }

    const onClear = () => {
        setLocalOrderTypes([])
        setLocalCoins([])
        setLocalStartDate(null)
        setLocalEndDate(null)
    }

    return (
        <SideDrawer
            isOpen={isOpen}
            toggleSideDrawerOpen={toggleSideDrawerOpen}
            size="full"
            header={
                <FilterDrawerHeader
                    title={t("transactions.filterDrawer")}
                    onClose={toggleSideDrawerOpen}
                    clearButtonLabel={t("common:clear")}
                    onClear={onClear}
                />
            }
            footerButton={{
                children: t("common:save"),
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_SOLID,
                type: "submit",
                onClick: handleSave,
            }}
            contentPadding={4}
        >
            {orderTypes && (
                <Box mb={4} py={3} borderBottom={"1px solid"} borderColor={"grey.light.10"}>
                    <Box px={2} mb={3}>
                        <Text textStyle={"ManropeSemiboldBodySmall"}>{t("transactions.orderType")}</Text>
                    </Box>
                    <Stack spacing={2}>
                        {orderTypes.map((type) => (
                            <OptionCheckBox
                                key={type.value}
                                option={type}
                                isChecked={localOrderTypes.some((item) => item.value === type.value)}
                                onToggle={() => handleToggleOrderType(type)}
                            />
                        ))}
                    </Stack>
                </Box>
            )}

            <Box as="fieldset" mb={4} borderBottom={"1px solid"} borderColor={"grey.light.10"} p={3}>
                <Text textStyle={"ManropeSemiboldBodySmall"} mb={6}>
                    {t("transactions.customDateRange")}
                </Text>
                <Flex w="fit-content" alignItems={"center"} gap={2} mb={3}>
                    <DatePicker
                        label={t("transactions.startDate")}
                        maxDate={localEndDate}
                        selectedDate={localStartDate}
                        onDateChange={setLocalStartDate}
                    />
                    <Text>-</Text>
                    <DatePicker
                        label={t("transactions.endDate")}
                        minDate={localStartDate}
                        selectedDate={localEndDate}
                        onDateChange={setLocalEndDate}
                    />
                </Flex>
            </Box>

            <Box as="fieldset" mb={4}>
                <Box px={2} py={3}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("common:assets")}</Text>
                </Box>
                <Box px={2} mb={3}>
                    <Searchbar
                        placeholder={t("transactions.searchAsset")}
                        value={searchTerm}
                        onChange={setSearchTerm}
                    />
                </Box>
                <Stack spacing={2}>
                    {filteredCoins.length > 0 ? (
                        filteredCoins.map((coin) => (
                            <OptionCheckBox
                                key={coin.value}
                                option={coin}
                                isChecked={localCoins.some((item) => item.value === coin.value)}
                                onToggle={() => handleToggleCoin(coin)}
                            />
                        ))
                    ) : (
                        <Text>{t("transactions.noAssetFound")}</Text>
                    )}
                </Stack>
            </Box>
        </SideDrawer>
    )
}
