import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "@util/RTKApi"
import { ACCOUNT_TAG_TYPES } from "./account/const"
import { ASSET_TAG_TYPES } from "./asset/const"
import { ASSETS_DETAILS_TAG_TYPES } from "./assetsDetails/const"
import { FUND_TAG_TYPES } from "./fund/const"
import { ORDERS_TAG_TYPES } from "./orders/const"
import { TRANSACTIONS_TAG_TYPES } from "./transactions/const"
import { STAKES_TAG_TYPES } from "./stake/const"
import { BALANCES_TAG_TYPES } from "./balances/const"
import { WITHDRAW_TAG_TYPES } from "./withdraw/const"
import { NPAY_TAG_TYPES } from "./netcoinsPay/const"

export const splitApi = createApi({
    reducerPath: "splitApi",
    baseQuery: baseQuery(),
    endpoints: () => ({}),
    tagTypes: [
        ...Object.values(ACCOUNT_TAG_TYPES),
        ...Object.values(ASSET_TAG_TYPES),
        ...Object.values(ASSETS_DETAILS_TAG_TYPES),
        ...Object.values(FUND_TAG_TYPES),
        ...Object.values(ORDERS_TAG_TYPES),
        ...Object.values(TRANSACTIONS_TAG_TYPES),
        ...Object.values(STAKES_TAG_TYPES),
        ...Object.values(BALANCES_TAG_TYPES),
        ...Object.values(WITHDRAW_TAG_TYPES),
        ...Object.values(NPAY_TAG_TYPES),
    ],
})
