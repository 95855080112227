import { Box, Text, Flex, Icon, Image, Switch } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { FaAngleRight } from "react-icons/fa"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import PhysicalCardImage from "@assets/images/netcoins/physical-card.png"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { MdLockOutline, MdAdd } from "react-icons/md"
import Chip from "@components/ui/badge"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import { useMemo } from "react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { IoMdAdd } from "react-icons/io"
import { useStableBreakpoint } from "@hooks/useStableBreakpoint"

type Props = {
    type: "physical" | "virtual"
    status: "active" | "pending"
    isRegistered?: boolean
    lastFourDigits?: string
    handleCardLockToggle?: (type: "physical" | "virtual") => void
    handleViewCard?: (type: "physical" | "virtual") => void
    isLocked?: boolean
    cardDetailsImgSrc?: string
    cardImgLoading?: boolean
    openWalletInstModal?: () => void
}

export default function NetcoinsPayCard({
    status,
    type,
    isRegistered,
    lastFourDigits,
    handleCardLockToggle,
    handleViewCard,
    isLocked,
    cardDetailsImgSrc,
    cardImgLoading,
    openWalletInstModal,
}: Props) {
    const { t } = useTranslation(["app", "common"])

    const isShown = useMemo(() => cardDetailsImgSrc && true, [cardDetailsImgSrc])

    const renderCardImage = () => {
        const cardImages = {
            virtual: cardDetailsImgSrc ? cardDetailsImgSrc : PhysicalCardImage,
            physical: cardDetailsImgSrc ? cardDetailsImgSrc : PhysicalCardImage,
        }

        return cardImages[type] || ""
    }

    const title = t(type === "virtual" ? "netcoinsPay.virtualCard" : "netcoinsPay.physicalCard")

    return (
        <Flex flexDir="column" width="100%">
            <Flex position="relative" flexDir="column" flex="1" gap={4}>
                <Box position="relative" aspectRatio={16 / 10} width="100%">
                    {isRegistered ? (
                        <Image
                            src={renderCardImage()}
                            alt={`${type} card`}
                            height="100%"
                            width="100%"
                            objectFit="fill"
                            borderRadius="12px"
                            opacity={status === "pending" ? 0.15 : 1}
                        />
                    ) : (
                        <Flex
                            borderRadius="12px"
                            alignItems="center"
                            justifyContent="center"
                            border="1px dashed"
                            borderColor="grey.light.20"
                            width={"100%"}
                            height={"100%"}
                        >
                            <StandardButton
                                variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_GHOST}
                                leftIcon={IoMdAdd}
                                alignSelf={"center"}
                            >
                                {type === "physical"
                                    ? t("netcoinsPay.addPhysicalCard")
                                    : t("netcoinsPay.addVirtualCard")}
                            </StandardButton>
                        </Flex>
                    )}

                    {isRegistered && isLocked && (
                        <Flex
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            bg="rgba(0, 0, 0, 0.50)"
                            zIndex="1"
                            borderRadius="12px"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                        >
                            <ReactIcon icon={MdLockOutline} size={32} color="white" />
                        </Flex>
                    )}
                </Box>
            </Flex>

            {isRegistered && (
                <>
                    <Flex w="100%" mt={4} height={21}>
                        {status === "active" && !isLocked && (
                            <Flex
                                alignItems="center"
                                justifyContent="flex-end"
                                cursor={cardImgLoading ? "not-allowed" : "pointer"}
                                gap={1}
                                whiteSpace="nowrap"
                                height="100%"
                                ml="auto"
                                sx={{
                                    "&:hover": {
                                        textDecorationColor: "blue.100 !important",
                                        textDecoration: cardImgLoading ? "none" : "underline",
                                    },
                                    "&:active": {
                                        textDecorationColor: "blue.70 !important",
                                        textDecoration: cardImgLoading ? "none" : "underline",
                                    },
                                }}
                                onClick={() => {
                                    handleViewCard && handleViewCard(type)
                                }}
                            >
                                {cardImgLoading ? (
                                    <Text color="blue.100" textStyle="ManropeSemiboldBodySmall">
                                        {t("common:loading")}...
                                    </Text>
                                ) : (
                                    <>
                                        <Icon as={isShown ? FiEyeOff : FiEye} boxSize={4} color="blue.100" />
                                        <Text color="blue.100" textStyle="ManropeSemiboldBodySmall">
                                            {t(isShown ? "netcoinsPay.hideCard" : "netcoinsPay.viewCard")}
                                        </Text>
                                    </>
                                )}
                            </Flex>
                        )}
                    </Flex>

                    <Flex
                        gap={4}
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px"
                        borderColor="grey.light.10"
                        height="64px"
                        width="100%"
                    >
                        <Flex alignItems="center" gap={4}>
                            <Text textStyle="ManropeSemiboldBody">{title}</Text>
                            {isLocked || status === "pending" ? (
                                <Chip
                                    text={isLocked ? t("netcoinsPay.locked") : t("netcoinsPay.onTheWay")}
                                    variant={isLocked ? ENUM_CHIP_VARIANTS.RED : ENUM_CHIP_VARIANTS.YELLOW}
                                />
                            ) : null}
                        </Flex>

                        <Text textStyle="ManropeSemiboldBody">{`****${lastFourDigits}`}</Text>
                    </Flex>

                    <Flex
                        gap={4}
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px"
                        borderColor="grey.light.10"
                        height="64px"
                        width="100%"
                    >
                        <Flex gap={4} alignItems="center">
                            <ReactIcon icon={MdLockOutline} size={18} />
                            <Text textStyle="ManropeMediumBodySmall">
                                {t(isLocked ? "netcoinsPay.unlockCard" : "netcoinsPay.lockCard")}
                            </Text>
                        </Flex>

                        <Switch
                            isChecked={isLocked}
                            onChange={() => {
                                handleCardLockToggle && handleCardLockToggle(type)
                            }}
                            colorScheme="green"
                            disabled={status === "pending"}
                        />
                    </Flex>

                    <Flex
                        gap={4}
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px"
                        borderColor="grey.light.10"
                        height="64px"
                        width="100%"
                        cursor={"pointer"}
                        onClick={openWalletInstModal}
                    >
                        <Flex gap={4} alignItems="center">
                            <ReactIcon icon={MdAdd} size={18} />
                            <Text textStyle="ManropeMediumBodySmall">{t("netcoinsPay.addToWallet")}</Text>
                        </Flex>

                        <ReactIcon icon={FaAngleRight} size={16} />
                    </Flex>
                </>
            )}
        </Flex>
    )
}
