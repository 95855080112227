import { extendTheme } from "@chakra-ui/react"
import { BreakPoints } from "./breakpoints"
import { Colors } from "./colors"
import { Components } from "./components"
import fonts from "./fonts"
import { TextStyles } from "./textStyles"
import { hostedPagesStyles } from "./hostedPagesStyles"

export * from "./colors"
export * from "./components"
export * from "./fonts"
export * from "./textStyles"

export default extendTheme({
    colors: Colors,
    fonts,
    textStyles: TextStyles,
    breakpoints: BreakPoints,
    components: Components,
    styles: {
        // Props injected by Chakra UI
        global: ({ colorMode }: { colorMode: "light" | "dark" }) => ({
            button: {
                display: "flex",
                padding: "0.75rem 1rem",
                borderRadius: "0.5rem",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
            },
            ".chakra-form__required-indicator": {
                color: "#3D65F6 !important",
            },
            div: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                    width: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#E5E5EA",
                    borderRadius: "4px",
                },
            },
            ...hostedPagesStyles,
        }),
    },
})
