import { API_PATH } from "./const"
import { StatementMonthsRes, StatementYearsRes } from "./types"
import { splitApi } from "@redux/splitApi"

export const statementsApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getStatements: builder.query<StatementYearsRes, void>({
            query: () => ({
                url: `${API_PATH.STATEMENTS}`,
                method: "GET",
            }),
        }),
        getStatementMonths: builder.query<StatementMonthsRes, string>({
            query: (year) => ({
                url: `${API_PATH.STATEMENTS}/${year}`,
                method: "GET",
            }),
        }),
        getKOHOStatements: builder.query<StatementYearsRes, void>({
            query: () => ({
                url: `${API_PATH.KOHO_STATEMENTS}`,
                method: "GET",
            }),
        }),
        getKOHOStatementMonths: builder.query<StatementMonthsRes, string>({
            query: (year) => ({
                url: `${API_PATH.KOHO_STATEMENTS}/${year}`,
                method: "GET",
            }),
        }),
        downloadStatement: builder.mutation<{ fileUrl: string }, string>({
            query: (url) => ({
                url,
                method: "GET",
                responseHandler: async (response) => {
                    const blob = await response.blob()
                    const fileUrl = URL.createObjectURL(blob)
                    return { fileUrl }
                },
            }),
        }),
    }),
})

export const {
    useLazyGetKOHOStatementMonthsQuery,
    useGetKOHOStatementsQuery,
    useLazyGetStatementMonthsQuery,
    useGetStatementsQuery,
    useDownloadStatementMutation,
} = statementsApi
