import { Flex, Text, Icon } from "@chakra-ui/react"
import { FaAngleRight } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import IconButton from "@components/ui/buttons/icon"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { MdChevronRight } from "react-icons/md"

type WalletLinkProps = {
    text: string
    onClick: () => void
}

const WalletLink = ({ onClick, text }: WalletLinkProps) => {
    return (
        <Flex
            gap={4}
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px"
            borderColor="grey.light.10"
            py={4}
            width="100%"
            cursor={"pointer"}
            onClick={onClick}
        >
            <Text textStyle="ManropeSemiboldBody">{text}</Text>

            <Flex alignItems="center">
                <Icon as={MdChevronRight} size={16} />
            </Flex>
        </Flex>
    )
}

type Props = {
    updateView: (value: number) => void
}

export default function Menu({ updateView }: Props) {
    const { t } = useTranslation("app")

    return (
        <Flex gap={6} flexDir="column">
            <Text textStyle="InterRegularBody">{t("netcoinsPay.addNetcoinsPayText")}</Text>

            <Flex flexDir="column">
                <WalletLink text={t("netcoinsPay.addToAppleWallet")} onClick={() => updateView(2)} />
                <WalletLink text={t("netcoinsPay.addToGoogleWallet")} onClick={() => updateView(3)} />
                <WalletLink text={t("netcoinsPay.addToSamsungWallet")} onClick={() => updateView(4)} />
            </Flex>
        </Flex>
    )
}
