import { appendVersion } from "@util/RTKApi"

export const ACCOUNT_TAG_TYPES = {
    accountDetails: "accountDetails",
    wallets: "wallets",
    notificationChannels: "notificationChannels",
    notificationsDetails: "notificationsDetails",
    priceAlerts: "priceAlerts",
    authDevices: "authDevices",
} as const

export const API_PATH = {
    accountDetails: appendVersion("account/account"),
    accountRestrictions: appendVersion("account/restrictions"),
    generateTFAKey: appendVersion("account/two-step-verification/generate"),
    saveTFAKey: appendVersion("account/two-step-verification"),
    wallets: appendVersion("account/wallets"),
    changePassword: appendVersion("account/password"),
    disableTFA: appendVersion("account/two-step-verification"),
    verifyPass: appendVersion("account/password/verify"),
    sendEmailCode: appendVersion("account/close/email"),
    sendOtpPassword: appendVersion("account/password/otp"),
    verifyEmailCode: appendVersion("account/close/code/verify"),
    closeSurvey: appendVersion("account/close/survey"),
    account: appendVersion("account"),
    transactionsEmail: appendVersion("account/transactions/email"),
    verify2FA: appendVersion("account/two-step-verification/verify"),
    koinlySso: appendVersion("account/koinly/sso"),
    notificationChannels: appendVersion("account/notifications/channels"),
    notificationDetails: appendVersion("account/notifications"),
    subscribeNotification: appendVersion("account/notifications/subscribe"),
    priceAlerts: appendVersion("account/price-alerts"),
    deleteBank: appendVersion("account/bank/external"),
    authorizedDevices: appendVersion("account/devices"),
    activity: appendVersion("account/activities"),
    sessions: appendVersion("account/sessions"),
    authorizedDevice: appendVersion("account/device"),
} as const
