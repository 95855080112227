import { Flex, Text } from "@chakra-ui/react"
import Select from "@components/ui/select"
import TextInput from "@components/ui/textInput"
import { Option } from "@components/ui/types"
import COUNTRIES_NO_OFAC_OPTIONS from "@json/countriesNoOFAC.json"
import REGIONS from "@json/regions.json"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DrawerWithdrawContext } from "../../drawerWithdraw/DrawerWithdrawContext"
import { AddBankProps } from "./addBank"

export default function BankAddressFields({
    control,
    watch,
    getValues,
    setValues,
    isCanadian,
}: AddBankProps & { isCanadian: boolean }) {
    const { t } = useTranslation(["transfer", "common"])
    const [regionOptions, setRegionOptions] = useState<Option<string>[]>([])
    const isCanadianBank = watch("type")?.value === "canadian"
    const country = watch("bank_country")

    useEffect(() => {
        const countryCode = country?.value
        if (countryCode) {
            const regions = REGIONS[countryCode as keyof typeof REGIONS]
            if (regions) {
                setRegionOptions(regions)
            }
            const currentRegion = getValues("bank_province")
            if (currentRegion && !regions.find((region) => region.value === currentRegion.value)) {
                setValues("bank_province", { value: "", label: "" })
            }
        }
    }, [country])

    useEffect(() => {
        if (isCanadian) {
            setValues("bank_country", { value: "CA", label: t("canada", { ns: "common" }) })
        } else if (!country || country.value === "CA") {
            setValues("bank_country", { value: "US", label: t("usa_full", { ns: "common" }) })
        }
    }, [isCanadian, setValues])

    return (
        <>
            <Text textStyle="ManropeSemiboldBody">{t("withdraw.wire.bankAddress")}</Text>
            <TextInput
                name="bank_address"
                control={control}
                rules={{ required: t("error.required", { ns: "common" }) }}
                label={t("withdraw.wire.bankAddress")}
                placeholder={t("withdraw.wire.bankAddressPlaceholder")}
            />
            <Select
                name="bank_country"
                options={COUNTRIES_NO_OFAC_OPTIONS}
                disabled={getValues("type")?.value === "canadian"}
                control={control}
                label={t("country", { ns: "common" })}
            />
            <Flex columnGap=".75rem">
                <TextInput
                    name="bank_city"
                    control={control}
                    rules={{ required: t("error.required", { ns: "common" }) }}
                    label={t("city", { ns: "common" })}
                    placeholder={
                        isCanadianBank
                            ? t("withdraw.wire.bankCityCAPlaceholder")
                            : t("withdraw.wire.bankCityUSPlaceholder")
                    }
                />
                <Select
                    name="bank_province"
                    defaultValue={undefined}
                    control={control}
                    options={regionOptions}
                    disabled={!regionOptions.length}
                    rules={{ required: regionOptions.length > 0 ? t("error.required", { ns: "common" }) : false }}
                    label={isCanadianBank ? t("province", { ns: "common" }) : t("region", { ns: "common" })}
                    placeholder={t("selectOne", { ns: "common" })}
                />
            </Flex>
            <TextInput
                name="bank_zip_code"
                control={control}
                rules={{ required: t("error.required", { ns: "common" }) }}
                label={isCanadianBank ? t("postalCode", { ns: "common" }) : t("zipCode", { ns: "common" })}
                placeholder={isCanadianBank ? "Ex. M2N 5X8" : "Ex. 80210"}
            />
        </>
    )
}
