import { Box, Text } from "@chakra-ui/layout"
import ScreenContainer from "@components/ui/containers/screen"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import tosCA from "@assets/tos/tosCA.html"
import tosUS from "@assets/tos/tosUS.html"

export default function TermsOfService() {
    const [searchParams] = useSearchParams()

    const { t } = useTranslation("common")
    const country = searchParams.get("c")?.toLocaleLowerCase()

    return (
        <ScreenContainer>
            <Box pb={8} borderBottomWidth={1} borderBottomColor="grey.light.10">
                <Text textStyle="ManropeMedium4xLarge">{t("termsOfService")}</Text>
            </Box>
            <Box
                dangerouslySetInnerHTML={{ __html: country === "us" ? tosUS : tosCA }}
                py={8}
                className="hosted-page"
            />
        </ScreenContainer>
    )
}
