import { Td, Text, Tr, Flex, Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { FiMoreVertical } from "react-icons/fi"
import { TextWithVisibility } from "./TextWithVisibility"
import { BankDetails, IntlBankDetails, USBankDetails } from "@redux/withdraw/types"
import IconMenu from "@components/ui/menus/icon"

type Props = {
    isCA: boolean
    handleViewDetails: (_: BankDetails | IntlBankDetails | USBankDetails) => void
    handleEditDetails: (_: BankDetails | IntlBankDetails) => void
    handleDeleteAccount: (_: BankDetails | IntlBankDetails | USBankDetails) => void
    bank: BankDetails | IntlBankDetails | USBankDetails
}

export default function FiatRow({ bank, handleViewDetails, handleEditDetails, handleDeleteAccount, isCA }: Props) {
    const { t } = useTranslation("settings")

    return (
        <>
            <Tr w="full" py={0} px={0} display={{ base: "table-row", md: "none" }}>
                <Td w="full" px={0} py={2} borderBottom="none">
                    <Text whiteSpace="normal" wordBreak="normal" textStyle="ManropeSemiboldBodySmall">
                        {bank.bank_name}
                    </Text>
                </Td>
            </Tr>

            <Tr w="full" py={3} px={2} display="table-row">
                <Td w="200px" alignItems="center" px={2} py={3} display={{ base: "none", md: "table-cell" }}>
                    <Text whiteSpace="normal" wordBreak="normal" textStyle="ManropeSemiboldBodySmall">
                        {bank.bank_name}
                    </Text>
                </Td>

                <Td
                    w={{ base: "calc(100% - 40px)", md: "calc(100% - 200px - 100px)" }}
                    px={0}
                    py={{ base: 0, md: 3 }}
                    pb={3}
                >
                    <Flex flexDir="column">
                        <TextWithVisibility textStyle="ManropeSemiboldBodySmall">
                            {bank.account_number}
                        </TextWithVisibility>
                        {"title" in bank && (
                            <Text
                                whiteSpace="normal"
                                wordBreak="normal"
                                textStyle="InterRegularXSmall"
                                color="grey.light.80"
                            >
                                {bank.title}
                            </Text>
                        )}
                    </Flex>
                </Td>

                <Td w={{ base: "40px", md: "100px" }} px={0} py={{ base: 0, md: 3 }} pb={3}>
                    <Flex justifyContent={"flex-end"}>
                        <IconMenu
                            items={[
                                { text: t("savedFiatAccount.viewDetails"), onClick: () => handleViewDetails(bank) },
                                ...(isCA
                                    ? [
                                          {
                                              text: t("savedFiatAccount.editDetails"),
                                              onClick: () => handleEditDetails(bank as BankDetails | IntlBankDetails),
                                          },
                                      ]
                                    : []),
                                { text: t("savedFiatAccount.deleteAccount"), onClick: () => handleDeleteAccount(bank) },
                            ]}
                        />
                    </Flex>
                </Td>
            </Tr>
        </>
    )
}
