import React, { useEffect, useRef, useState } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { useTranslation } from "react-i18next"

declare global {
    interface Window {
        simplex: {
            createForm: () => void
        }
    }
}

export interface DrawerProps {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
}

const BuyWithCreditCardDrawer: React.FC<DrawerProps> = ({ isOpen, toggleSideDrawerOpen }) => {
    const { t } = useTranslation(["settings", "common"])
    const isWidgetInitialized = useRef(false)

    const toggleDrawerOpen = () => {
        toggleSideDrawerOpen()
    }

    useEffect(() => {
        if (isOpen && !isWidgetInitialized.current) {
            isWidgetInitialized.current = true
            const script = document.createElement("script")
            script.src = "https://iframe.sandbox.test-simplexcc.com/form.js" //TODO: Change to production URL when ready
            script.type = "text/javascript"
            script.async = true
            document.body.appendChild(script)

            script.onload = () => {
                if (window.simplex) {
                    window.simplex.createForm()
                }
            }

            return () => {
                const existingScript = document.querySelector(
                    'script[src="https://iframe.sandbox.test-simplexcc.com/form.js"]' //TODO: Change to production URL when ready
                )
                if (existingScript) {
                    document.body.removeChild(existingScript)
                }
            }
        }
    }, [isOpen])

    return (
        <SideDrawer
            isOpen={isOpen}
            toggleSideDrawerOpen={toggleSideDrawerOpen}
            size="md"
            header={<StandardDrawerHeader onClose={toggleDrawerOpen} />}
        >
            <Box mt={7}>
                <Text textStyle={"ManropeSemiboldXLarge"}>{t("settings.buyWithCredit")}</Text>
                <Text my={7} textStyle={"InterRegularBody"}>
                    {t("settings.purchaseCryptoCredit")}
                </Text>
                <Flex
                    my={6}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"md"}
                    backgroundColor={"blue.10"}
                    p={4}
                >
                    <Text textStyle={"InterRegularBodySmall"} textAlign={"center"}>
                        {t("settings.thirdPartyFeature")}
                    </Text>
                </Flex>
                <div id="simplex-form" className="custom-simplex"></div>
                <style id="simplex-css">
                    {`
                    #simplex-iframe-form {
                        font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !important;
                        font-size: 0.9375rem !important;
                        font-weight: 400 !important;
                        line-height: 1.5 !important;
                        color: #495057 !important;
                        min-height: 300px !important;
                        overflow: hidden !important;
                        width: 100% !important;
                        height: auto !important;
                        border: none !important;
                    }

                    .form-control {
                        border-color: #A4A4A5 !important;
                    }

                    .dropdown-button {
                        border-top: 1px solid #A4A4A5 !important;
                        border-right: 1px solid #A4A4A5 !important;
                        border-bottom: 1px solid #A4A4A5 !important;
                        border-left: none !important;
                        border-top-left-radius: unset;
                        border-bottom-left-radius: unset;
                        border-top-right-radius: 4px !important;
                        border-bottom-right-radius: 4px !important;
                    }

                    #cryptoAddress {
                        border-radius: 4px !important;
                    }

                    #simplex-iframe-form .simplex-continue-button {
                        color: #fff !important;
                        background-color: #3D65F6 !important;
                        border-color: #3D65F6 !important;
                        border-radius: 8px !important;
                    }

                    .error-tooltip {
                        padding: 6px !important;
                    }

                    #simplex-iframe-form  .simplex-continue-button-container {
                        width: 100% !important;
                    }

                    #simplex-iframe-form  .simplex-continue-button {
                        width: 100% !important;
                    }

                    #simplex-iframe-form a {
                        color: #3D65F6 !important;
                    }

                    #simplex-iframe-form .powered-logo {
                        margin-top: 1rem !important;
                        display: flex !important;
                    }

                    .walletconnect-link {
                        display: none;
                    }

                    .wallet-url {
                        display: none;
                    }

                    span:has(.walletconnect-link) {
                        display: none;
                    }
                        
                    div:has(.address-disclaimer) {
                        flex-direction: column !important;
                        margin-left: 0 !important;     
                    }
                    .address-disclaimer {
                        width: 100% !important;
                        margin-bottom: 4px !important;
                    }
                    `}
                </style>
            </Box>
        </SideDrawer>
    )
}

export default BuyWithCreditCardDrawer
