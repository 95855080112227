import { Box, Flex, Icon, Link, Text } from "@chakra-ui/react"
import IconButton from "@components/ui/buttons/icon"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import useRoutingUtils from "@routing/useRoutingUtils"
import LogoFull from "assets/svgs/netcoins/logo-full"
import { NavButton, SIDE_BAR_ROUTES } from "components/sidebar"
import React, { FunctionComponent, useMemo, useState, Fragment } from "react"
import { NavLink } from "react-router-dom"
import {
    MdAccountCircle,
    MdArrowLeft,
    MdClose,
    MdHelp,
    MdHelpOutline,
    MdLogout,
    MdMenu,
    MdNotifications,
    MdOutlineAccountCircle,
    MdOutlineNotifications,
    MdOutlineSettings,
    MdSettings,
} from "react-icons/md"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import ChartUp from "@assets/svgs/chart-up"
import MenuSettings from "./menuSettings"
import MenuProfile from "./menuProfile"
import SidebarSettings from "./sidebarSettings"
import SidebarProfile from "./sidebarProfile"
import { FaArrowLeft } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { useTranslation } from "react-i18next"
import { IoMdHelpCircle, IoMdHelpCircleOutline } from "react-icons/io"
import useSupport from "@hooks/useSupport"

interface HeaderIconButton extends NavButton {
    label: "Notifications" | "FAQ" | "Settings" | "Profile"
    menuComponent?: FunctionComponent
}

const HEADER_ROUTES: HeaderIconButton[] = [
    // {
    //     label: "Notifications",
    //     path: ROUTES.NOTIFICATIONS,
    //     icon: MdNotifications,
    //     iconOutline: MdOutlineNotifications,
    //     menuComponent: () => <></>,
    // },
    {
        label: "FAQ",
        path: ROUTES.DASHBOARD_FAQ,
        icon: IoMdHelpCircle,
        iconOutline: IoMdHelpCircleOutline,
    },
    {
        label: "Settings",
        path: ROUTES.DASHBOARD_SETTINGS,
        icon: MdSettings,
        iconOutline: MdOutlineSettings,
        menuComponent: MenuSettings,
    },
    {
        label: "Profile",
        path: ROUTES.PROFILE,
        icon: MdAccountCircle,
        iconOutline: MdOutlineAccountCircle,
        menuComponent: MenuProfile,
    },
]

export const HEADER_HEIGHT = 80

export default function HeaderDashboard() {
    const { t } = useTranslation("common")
    const { isActive } = useRoutingUtils()
    const { navigate } = useRestrictedNavigation()
    const { data: userData } = useGetAccountDetailsQuery()
    const { faqLink } = useSupport()

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [subView, setSubView] = useState<"Notifications" | "FAQ" | "Settings" | "Profile" | null>(null)

    const CountryFilteredRoutes = useMemo(
        () =>
            SIDE_BAR_ROUTES.filter((route) => {
                if (route.isCAOnly) {
                    return userData?.country === "CA"
                }
                return true
            }),
        [userData]
    )

    function handleSidebarHeaderRouteClick(label: "Notifications" | "FAQ" | "Settings" | "Profile") {
        if (label === "FAQ") {
            window.open(faqLink, "_blank")
        } else {
            setSubView(label)
        }
    }

    function handleClose() {
        setDropdownOpen(false)
        setSubView(null)
    }

    const SideBarSubView = useMemo(() => {
        switch (subView) {
            case "Settings":
                return <SidebarSettings close={handleClose} />
            case "Profile":
                return <SidebarProfile close={handleClose} />
            default:
                return <></>
        }
    }, [subView])

    const HEADER_ROUTES_REVERSED = useMemo(() => HEADER_ROUTES.slice().reverse(), [])

    return (
        <Box pos="relative">
            <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                w="full"
                bg="bluePurple.100"
                height={HEADER_HEIGHT + "px"}
                px="1.5rem"
            >
                <Link as={NavLink} to={ROUTES.DASHBOARD}>
                    <LogoFull color="white" size={200} />
                </Link>
                <Box display={{ base: "none", md: "flex" }} zIndex={"dropdown"}>
                    {HEADER_ROUTES.map((route: HeaderIconButton) =>
                        route.menuComponent ? (
                            <Fragment key={route.label}>{route.menuComponent({})}</Fragment>
                        ) : (
                            <IconButton
                                key={route.label}
                                name={route.label}
                                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_SOLID}
                                onClick={() => {
                                    if (route.label === "FAQ") {
                                        window.open(faqLink, "_blank")
                                    } else {
                                        navigate(route.path)
                                    }
                                }}
                                size="lg"
                                icon={route.icon}
                                iconOutline={route.iconOutline}
                            />
                        )
                    )}
                </Box>
                <Box
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    display={{ base: "inline-block", md: "none" }}
                    cursor="pointer"
                >
                    {dropdownOpen ? <MdClose size={24} color="white" /> : <MdMenu size={24} color="white" />}
                </Box>
            </Box>
            <Box
                display={{ base: dropdownOpen ? "block" : "none", md: "none" }}
                h={`calc(100vh - ${HEADER_HEIGHT}px)`}
                bgColor="white"
                position="absolute"
                top={HEADER_HEIGHT + "px"}
                left="0"
                right="0"
                bottom="0"
                zIndex={99}
            >
                {subView ? (
                    <Box>
                        <Box
                            onClick={() => setSubView(null)}
                            mb="20px"
                            px="1rem"
                            mt="1.5rem"
                            cursor="pointer"
                            justifySelf={"flex-start"}
                        >
                            <Icon as={FiArrowLeft} h={"24px"} w={"24px"} color="black" />
                        </Box>
                        {SideBarSubView}
                    </Box>
                ) : (
                    <Box>
                        <Box
                            display="flex"
                            flexDir={"column"}
                            rowGap="0.5rem"
                            p="1.5rem 0.75rem 0.5rem 0.75rem"
                            borderBottom="1px solid #E5E5EA"
                        >
                            {CountryFilteredRoutes.map((route: NavButton) => (
                                <Link
                                    data-test-id={"sidebar-nav" + route.label}
                                    as={NavLink}
                                    end
                                    key={route.path}
                                    _hover={{
                                        bgColor: isActive(route.path) ? "bluePurple.100" : "blue.10",
                                    }}
                                    to={route.path}
                                    display="flex"
                                    alignItems="center"
                                    columnGap="1.5rem"
                                    p="0.5rem 0.75rem"
                                    borderRadius="4px"
                                    onClick={() => setDropdownOpen(false)}
                                    bgColor={isActive(route.path) ? "bluePurple.100" : "white"}
                                >
                                    {/* Exception required for ROUTES.DASHBOARD_TRADE here because the icon isn't part of the react-icons library */}
                                    {route.path === ROUTES.DASHBOARD_TRADE ? (
                                        <ChartUp color={isActive(route.path) ? "white" : "#07072E"} size={24} />
                                    ) : (
                                        <Icon
                                            as={isActive(route.path) ? route.icon : route.iconOutline}
                                            h="24px"
                                            w="24px"
                                            color={isActive(route.path) ? "white" : "#07072E"}
                                        />
                                    )}
                                    <Text
                                        textStyle="ManropeSemiboldBodySmall"
                                        fontWeight="600"
                                        color={isActive(route.path) ? "white" : "bluePurple.100"}
                                    >
                                        {route.label}
                                    </Text>
                                </Link>
                            ))}
                        </Box>
                        <Box
                            display="flex"
                            flexDir={"column"}
                            rowGap="0.5rem"
                            mb={"0.5rem"}
                            p="0.5rem 0.75rem"
                            borderBottom="1px solid #E5E5EA"
                        >
                            {HEADER_ROUTES_REVERSED.map((route: HeaderIconButton, i) => {
                                return (
                                    <Box
                                        data-test-id={"sidebar-nav" + route.label}
                                        key={route.path}
                                        _hover={{
                                            bgColor: "bluePurple.10",
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        columnGap="1.5rem"
                                        p="0.5rem 0.75rem"
                                        borderRadius="4px"
                                        cursor="pointer"
                                        onClick={() => handleSidebarHeaderRouteClick(route.label)}
                                    >
                                        <Text textStyle="ManropeSemiboldXSmall">{route.label}</Text>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Flex
                            _hover={{
                                bgColor: "bluePurple.10",
                            }}
                            columnGap="10px"
                            align="center"
                            py="0.75rem"
                            mx="0.75rem"
                            px="0.25rem"
                            cursor="pointer"
                            borderRadius="4px"
                        >
                            <ReactIcon icon={MdLogout} size={24} color="black" />
                            <Text textStyle="ManropeMediumBody">{t("logout")}</Text>
                        </Flex>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
