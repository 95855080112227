import React, { useMemo } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { USBankDetails } from "@redux/withdraw/types"

type Props = {
    focusedBank: USBankDetails
}

export default function AmericanBankDetails({ focusedBank }: Props) {
    const { t } = useTranslation("settings")
    const {
        id,
        bank_name,
        account_number,
        routing_number,
        name,
        category,
        beneficiary,
        swift_code,
        recipient_instructions,
    } = focusedBank

    const hasSubBank = useMemo(() => {
        return !!(focusedBank.correspondent_bank_name || focusedBank.intermediary_bank_name)
    }, [])

    return (
        <Flex rowGap="1.5rem" flexDir="column">
            <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal" textAlign="right">
                {name ?? bank_name}
            </Text>

            <Box borderBottomWidth={1} />

            <Flex gap={3} justifyContent="space-between" flexDir={{ base: "column", md: "row" }}>
                <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                    {t("savedFiatAccount.beneName")}
                </Text>

                <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal" textAlign="right">
                    {beneficiary.name}
                </Text>
            </Flex>

            <Box borderBottomWidth={1} />

            <Text textStyle="ManropeSemiboldBody" whiteSpace="normal" wordBreak="normal">
                {t("savedFiatAccount.bankDetails")}
            </Text>

            <Flex gap={1} flexDir="column">
                <Flex gap={3} justifyContent="space-between">
                    <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                        {t("savedFiatAccount.bankName")}:
                    </Text>

                    <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                        {focusedBank.bank_name}
                    </Text>
                </Flex>

                <Flex gap={3} justifyContent="space-between">
                    <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                        {t("savedFiatAccount.accountNumber")}:
                    </Text>

                    <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                        {account_number}
                    </Text>
                </Flex>

                <Flex gap={3} justifyContent="space-between">
                    <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                        {t("savedFiatAccount.swift")}:
                    </Text>

                    <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                        {swift_code}
                    </Text>
                </Flex>

                <Flex gap={3} justifyContent="space-between">
                    <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                        {t("savedFiatAccount.routing")}:
                    </Text>

                    <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                        {routing_number}
                    </Text>
                </Flex>
            </Flex>

            {hasSubBank && (
                <>
                    <Box borderBottomWidth={1} />

                    <Flex gap={1} flexDir="column">
                        <Flex gap={3} justifyContent="space-between" alignItems="center">
                            <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                                {t("savedFiatAccount.subType")}:
                            </Text>

                            <Box
                                display="inline-block"
                                py="0.25rem"
                                px="0.75rem"
                                borderRadius="0.25rem"
                                bgColor="darkBlue.10"
                            >
                                <Text textStyle="ManropeSemiboldXSmall" color="darkBlue.80">
                                    {focusedBank.correspondent_bank_name
                                        ? t("savedFiatAccount.correspondent")
                                        : t("savedFiatAccount.intermediary")}
                                </Text>
                            </Box>
                        </Flex>

                        <Flex gap={3} justifyContent="space-between">
                            <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                                {t("savedFiatAccount.subName")}:
                            </Text>

                            <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                                {focusedBank.correspondent_bank_name ?? focusedBank.intermediary_bank_name}
                            </Text>
                        </Flex>

                        <Flex gap={3} justifyContent="space-between">
                            <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                                {t("savedFiatAccount.accountNumber")}:
                            </Text>

                            <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                                {focusedBank.correspondent_bank_account_number ??
                                    focusedBank.intermediary_bank_account_number}
                            </Text>
                        </Flex>

                        <Flex gap={3} justifyContent="space-between">
                            <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                                {t("savedFiatAccount.subCode")}:
                            </Text>
                            <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                                {focusedBank.correspondent_bank_code ?? focusedBank.intermediary_bank_code}
                            </Text>
                        </Flex>
                    </Flex>
                </>
            )}
            {recipient_instructions && (
                <>
                    <Box borderBottomWidth={1} />

                    <Flex gap={3} justifyContent="space-between" flexDir={{ base: "column", md: "row" }}>
                        <Text textStyle="ManropeSemiboldBodySmall" whiteSpace="normal" wordBreak="normal">
                            {t("savedFiatAccount.recipientInstructions")}:
                        </Text>

                        <Text textStyle="InterRegularBodySmall" whiteSpace="normal" wordBreak="normal">
                            {recipient_instructions}
                        </Text>
                    </Flex>
                </>
            )}
        </Flex>
    )
}
