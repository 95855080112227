import { TCountryType, TCurrencyType } from "@/types"
import CountriesJSON from "../../json/countries.json"
import { AuthorizedDevice } from "@components/settings/authorizedDevice/authorizedDeviceTable"
import { TActivity } from "@screens/dashboard/settings/accountActivity"
interface Address {
    street_address: string
    building_address: string | null
    city: string
    region: string
    postal_code: string
    country: string
}

export enum IdentityCitizenship {
    US_CITIZEN = "us_citizen",
    US_RESIDENT = "us_resident",
    CA_CITIZEN = "citizen",
    CA_RESIDENT = "resident",
    PENDING = "pending",
    NONE = "na",
}

export interface Identity {
    first_name: string
    last_name: string
    dob: string
    occupation: string
    occupation_type: string
    citizenship: IdentityCitizenship | keyof typeof CountriesJSON
    id_number: boolean
    address: Partial<Address>
    phone: null | string
}

interface Business {
    legal_name: string
    alt_name: string
    corporate_role: string
    date_incorporated: Date
    is_money_service: boolean | null | 0 | 1
    government_id: string
    industry: string
    reg_type: string
    projected_quarter: {
        amount: number
        currency: TCurrencyType
    }
    website: string
    address: Address
}

interface Disclaimer {
    name: string
    content_key: string
    accepted_at: string | null | Date
}

interface Disclaimers {
    terms_of_service?: Disclaimer
    staking_terms_of_service?: Disclaimer
    risk_statement?: Disclaimer
    zero_hash_service?: Disclaimer
    zero_hash_privacy_and_regulatory?: Disclaimer
    zero_hash_risk?: Disclaimer
    privacy_policy?: Disclaimer
    overage_third_party?: Disclaimer
}

interface Survey {
    name: string
    attempts?: number
    passed: boolean
    created_at?: Date
    submitted_at?: Date
    failed_at?: Date
    must_retake: boolean
    error_messages: string[]
    error_code?: string
    error_header?: string
    support_emails?: Record<string, string>
}

interface Verification {
    verified: boolean
    phone: {
        number: boolean
        is_verified: boolean
        requires_sms_verification: boolean
    }
    email: boolean
    status: string
    ekyc: boolean
    screening: boolean
    identity: boolean
    address: boolean
    idv: null | boolean
    disclaimers: Disclaimers
    surveys: Survey[]
}
interface Features {
    interac: {
        secret_question: string
        secret_answer: string
    }
    ncpay: {
        accessible: boolean
        active: boolean
        must_migrate: boolean
        has_migrated: boolean
        supports_visa: boolean
        supports_mastercard: boolean
    }
    analytics: {
        disallow_tracking: boolean
    }
    push: {
        external_user_id: string
    }
}

export enum AccountType {
    INDIVIDUAL = "individual",
    BUSINESS = "business",
}

export enum TUserState {
    UNDER_REVIEW = "Under Review",
    CLOSED = "Closed",
    OPEN = "Open",
    VIEW_ONLY = "View Only",
    REJECTED = "Rejected",
    PENDING_BUSINESS_REVIEW = "Pending Business Review",
    PENDING_COMPLIANCE_REVIEW = "Pending Compliance Review",
    PRE_APPROVED = "Pre-Approved",
    //Not sure if these states exists anymore - but adding since registration is using them
    PENDING_EVALUATION = "Pending Evaluation",
    APPROVED = "Approved",
}

export interface IAccountDetails {
    account_number: string
    type: AccountType
    country: TCountryType
    region: string
    identity: Identity
    email: string
    registered_at: string
    verification: Verification
    business: Business
    disclaimers: Disclaimers
    surveys: Record<string, Survey>
    features: Features
    tfa: boolean
    onboarding_survey: string | null
    onboarding_survey_details: string | null
    currency: TCurrencyType
    userState: TUserState
    billpay_account_number: string
    timezone: string
}

export type IRestrictionsResponse = {
    restrictedCoins: Array<string> | undefined
    usage: number
    limit: number
}

export type ITfaKeyResponse = {
    qr: string
    secret: string
}

export type ISaveTFAKeyParams = {
    one_time_password: string
    google2fa_secret_key: string
}

export type IWalletsResponse = {
    data: Array<IWallet>
}

export type IWallet = {
    id: number
    coin: string
    address: string
    memo?: string
    label?: string
    override?: boolean
}

export type ICreateWalletParams = Omit<IWallet, "id">

export type ICreateWalletResponse = {
    wallet: IWallet
}

export type ChangePasswordParams = { current_password: string; password: string; one_time_password: string }
export type SometimesErrRes = {
    data: {
        errors: {
            [key: string]: string[]
        }
    }
}

export enum NotificationTypes {
    PRICE_ALERT = "price_alert",
    ANNOUNCEMENT = "announcement",
    LIMIT_ORDER = "limit_order",
    TRANSFERS = "transfers",
    STAKING = "staking",
}

export enum NotificationChannels {
    PUSH = "push",
    EMAIL = "email",
    SMS = "sms",
}

export type AvailableChannelsDetailsForType = {
    optional?: NotificationChannels[]
    required?: NotificationChannels[]
}

export type INotificationChannelsResponse = {
    announcement?: AvailableChannelsDetailsForType
    limit_loss?: AvailableChannelsDetailsForType
    limit_order?: AvailableChannelsDetailsForType
    price_alert?: AvailableChannelsDetailsForType
    staking?: AvailableChannelsDetailsForType
    transfers?: AvailableChannelsDetailsForType
}

export type SelectedChannelsDetails = {
    type: NotificationTypes
    sms: 0 | 1
    email: 0 | 1
    push: 0 | 1
}

export type INotificationsResponse = {
    announcement: SelectedChannelsDetails
    price_alert: SelectedChannelsDetails
    limit_order: SelectedChannelsDetails
    transfers: SelectedChannelsDetails
    staking: SelectedChannelsDetails
}

export interface ISubscribeNotificationParams {
    type: NotificationTypes
    flags?: Array<NotificationChannels>
}

export interface PriceAlert {
    id: number
    coin: string
    currency: TCurrencyType
    previous_price: number | null
    target_price: number | null
    frequency: number | null
    change_percentage: number | null
    time_window: number | null
}

export type IPriceAlertsResponse = {
    price_alerts: Array<PriceAlert>
}

export interface IPriceAlertParams {
    coin: string
    target_price: number | null
    currency: TCurrencyType
    frequency: number | null
    change_percentage: number | null
    time_window: number | null
}

export type AuthorizedDevicesRes = {
    devices: AuthorizedDevice[]
}

export type GetActivitiesRes = {
    data: Array<TActivity>
    links: {
        first: string
        last: string
        prev: string
        next: string
    }
    meta: {
        current_page: number
        from: number
        last_page: number
        links: Array<{
            url: string | null
            label: string
            active: boolean
        }>
        path: string
        per_page: number
        to: number
        total: number
    }
}
