import { Flex, Text, Image } from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useTranslation } from "react-i18next"
import NetcoinsPayCard from "@assets/images/netcoins/netcoins-pay-card.png"
import { ZENDESK_NETCOINS_PAY } from "@hooks/useSupport"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"

export default function NetcoinsPayStatements() {
    const { t } = useTranslation(["settings", "common"])
    const { navigate } = useRestrictedNavigation()

    function openLearnMoreWindow() {
        window.open(ZENDESK_NETCOINS_PAY, "_blank")
    }

    function openStatementWindow(url: string) {
        window.open(url, "_blank")
    }

    return (
        <Flex
            gap={6}
            flexDir={{ base: "column", md: "row" }}
            borderRadius="6px"
            bg="grey.light.5"
            w="100%"
            px={6}
            pb={6}
            mt={"0.75rem"}
        >
            <Flex justifyContent={"center"}>
                <Image src={NetcoinsPayCard} alt="netcoins-pay-card" zIndex={2} maxW={"300px"} />
            </Flex>

            <Flex flex={1} alignSelf={"center"} flexDir="column" gap={6} mt={{ base: 0, md: 6 }}>
                <Flex flexDir="column" gap={3}>
                    <Text textStyle="ManropeMedium3xLarge" maxW="250px">
                        {t("documents.earnFreeBitcoin")}
                    </Text>
                    <Text textStyle="InterRegularBody" maxW="300px">
                        {t("documents.getBackBitcoin")}
                    </Text>
                </Flex>

                <Flex alignItems="center" gap={3} flexDir={{ base: "column", lg: "row" }}>
                    <StandardButton
                        children={t("documents.register")}
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_SOLID}
                        color="white"
                        onClick={() => navigate(ROUTES.DASHBOARD_NETCOINS_PAY)}
                    />

                    <StandardButton
                        children={t("learnMore", { ns: "common" })}
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                        onClick={openLearnMoreWindow}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
