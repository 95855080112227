import { useTheme } from "@chakra-ui/react"
import { useEffect, useState } from "react"

export const useStableBreakpoint = () => {
    const theme = useTheme()

    const [variant, setVariant] = useState(() => {
        if (typeof window === "undefined") return "large"

        const width = window.innerWidth
        const breakpoints = {
            lg: parseInt(theme.breakpoints.lg),
            md: parseInt(theme.breakpoints.md),
            sm: parseInt(theme.breakpoints.sm),
        }

        if (width >= breakpoints.lg) return "large"
        if (width >= breakpoints.md) return "medium"
        if (width >= breakpoints.sm) return "small"
        return "extra-small"
    })

    useEffect(() => {
        const queries = {
            large: window.matchMedia(`(min-width: ${theme.breakpoints.lg})`),
            medium: window.matchMedia(`(min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`),
            small: window.matchMedia(`(min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.md})`),
            "extra-small": window.matchMedia(`(max-width: ${theme.breakpoints.sm})`),
        }

        const updateVariant = () => {
            if (queries.large.matches) setVariant("large")
            else if (queries.medium.matches) setVariant("medium")
            else if (queries.small.matches) setVariant("small")
            else setVariant("extra-small")
        }

        Object.values(queries).forEach((query) => {
            query.addListener(updateVariant)
        })

        return () => {
            Object.values(queries).forEach((query) => {
                query.removeListener(updateVariant)
            })
        }
    }, [theme.breakpoints])

    return variant
}
