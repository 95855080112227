import { Td, Text, Tr } from "@chakra-ui/react"
import { TActivity } from "@screens/dashboard/settings/accountActivity"
import { formatShortDateTime } from "@util/dateFormating"

type Props = {
    activity: TActivity
}

export default function AccountActivityRow({ activity }: Props) {
    const { created_at, message } = activity

    return (
        <Tr w="full" py={2} borderBottom="1px" borderColor="#EDF2F7" display={{ base: "grid", md: "table-row" }}>
            <Td width={{ base: "100%", md: "209px" }} py={"10px"} px={0} height={{ md: "56px" }} borderBottom="unset">
                <Text color="grey.light.50" textStyle="ManropeSemiboldXSmall">
                    {formatShortDateTime(new Date(created_at))}
                </Text>
            </Td>

            <Td py={2} height={{ md: "56px" }} px={0} w="100%" borderBottom="unset" mb={{ base: 1, md: 0 }}>
                <Text textStyle="ManropeSemiboldBodySmall">{message}</Text>
            </Td>
        </Tr>
    )
}
