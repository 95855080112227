import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { IoWarningOutline } from "react-icons/io5"

export default function CloseDisclaimer(): JSX.Element {
    const { t } = useTranslation("settings")

    return (
        <Flex flexDir={"column"} gap={6}>
            <Flex
                alignItems="center"
                width="96px"
                height="56px"
                justifyContent="center"
                bg="red.light.10"
                borderRadius={1}
            >
                <ReactIcon icon={IoWarningOutline} size={24} color="red.light.100" />
            </Flex>

            <Flex flexDir={"column"} gap={3}>
                <Text textStyle="ManropeSemiboldBody">{t("profile.areYouSure")}</Text>
                <Text textStyle="InterRegularBody">{t("profile.keepInMindText")}</Text>
            </Flex>
        </Flex>
    )
}
