import { Flex } from "@chakra-ui/react"
import Select from "@components/ui/select"
import TextArea from "@components/ui/textArea"
import TextInput from "@components/ui/textInput"
import { REGEX_NUMERICAL } from "@util/regexes"
import { useTranslation } from "react-i18next"
import { AddBankProps } from "."

export default function AddAmericanBank({ control, watch }: AddBankProps) {
    const { t } = useTranslation(["transfer", "common"])

    const isTypeSelected = !!watch("type")?.value
    const isInternationalType = watch("type")?.value === "international"

    return (
        <Flex flexDir="column" rowGap={"1.5rem"}>
            <Select
                key="us-type"
                name="type"
                control={control}
                options={[
                    { value: "domestic", label: t("withdraw.wire.domestic") },
                    { value: "international", label: t("withdraw.wire.international") },
                ]}
                label={t("withdraw.wire.bankType")}
                rules={{ required: t("error.requiredOption", { ns: "common" }) }}
            />
            {isTypeSelected && (
                <>
                    <TextInput
                        name="bank_name"
                        control={control}
                        label={t("withdraw.wire.bankName")}
                        placeholder="Ex. BigBank"
                        rules={{ required: t("error.required", { ns: "common" }) }}
                    />
                    <TextInput
                        key="us-beneficiary"
                        name="beneficiary"
                        control={control}
                        label={t("withdraw.wire.beneficiary")}
                        placeholder="Ex. John Doe"
                        maxLength={35}
                        rules={{ required: t("error.required", { ns: "common" }) }}
                    />
                    <TextInput
                        key="us-account"
                        name="account"
                        control={control}
                        label={t("withdraw.wire.accountNum")}
                        placeholder="Ex. 122105155"
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            pattern: { value: REGEX_NUMERICAL, message: t("common:error.numbersOnly") },
                        }}
                    />
                    {!isInternationalType && (
                        <TextInput
                            key="us-routing"
                            name="routing"
                            control={control}
                            label={t("withdraw.wire.routing")}
                            placeholder="Ex. 111122222"
                            maxLength={9}
                            rules={{
                                required: t("error.required", { ns: "common" }),
                                minLength: { value: 9, message: t("common:error.minLength", { len: 9 }) },
                                maxLength: { value: 9, message: t("common:error.maxLength", { len: 9 }) },
                            }}
                        />
                    )}
                    <TextInput
                        key="us-swift"
                        name="swift"
                        control={control}
                        label={t("withdraw.wire.swiftCode")}
                        placeholder="Ex. AAAA-BB-CC-123"
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            maxLength: { value: 11, message: t("common:error.maxLength", { len: 11 }) },
                        }}
                    />
                    <TextInput
                        key="us-nickname"
                        name="nickname"
                        control={control}
                        label={t("withdraw.wire.nickname")}
                        placeholder="Ex. Chase checking"
                        maxLength={35}
                        rules={{ required: false }}
                    />
                    {isInternationalType && (
                        <TextArea
                            key="us-"
                            currentLength={watch("instructions")?.length ?? 0}
                            maxLength={200}
                            name="instructions"
                            control={control}
                            label={t("withdraw.wire.instructions")}
                            placeholder="Ex. Memo #1234"
                        />
                    )}
                </>
            )}
        </Flex>
    )
}
