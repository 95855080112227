import { LimitOrderParams } from "@redux/orders/types"
import { API_PATH, TRANSACTIONS_TAG_TYPES } from "./const"
import { BALANCES_TAG_TYPES } from "@redux/balances/const"
import { CancelOrderParams, TransactionParams, TransactionsResponse, TransactionCsvParams } from "./types"
import { splitApi } from "@redux/splitApi"

export const transactionsApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getTransactions: builder.query<TransactionsResponse, TransactionParams>({
            query: ({
                types = "",
                page = 1,
                limit = 10,
                sort = "desc",
                statuses = "",
                assets = "",
                range = "",
            }: TransactionParams) => {
                return `${API_PATH.TRANSACTIONS}?sort=created_at,${sort}&limit=${limit}&page=${page}&types=${types}&statuses=${statuses}&assets=${assets}&range=${range}`
            },
            providesTags: (result) => (result ? [{ type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS }] : []),
        }),
        deleteOpenOrder: builder.mutation<LimitOrderParams, CancelOrderParams>({
            query: (params) => ({
                url: `${API_PATH.ORDERS}/${params.order_id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS }, { type: BALANCES_TAG_TYPES.BALANCES }],
        }),
        downloadTransactions: builder.mutation<Blob, TransactionCsvParams>({
            query: (params: TransactionCsvParams) => {
                let url = `${API_PATH.DOWNLOAD_TRANSACTIONS}?csv=true&limit=0&created_at,${params.sort}&range=${params.range}&assets=${params.assets}&types=${params.types}&type=${params.exportType}&statuses=Delivered`
                if (params.email) {
                    url += `&email=${params.email}`
                }

                return {
                    url: url,
                    headers: {
                        "Content-Type": "text/csv",
                        accept: "text/csv",
                    },
                    method: "GET",
                }
            },
        }),
    }),
})

export const {
    useGetTransactionsQuery,
    useDeleteOpenOrderMutation,
    useDownloadTransactionsMutation,
    useLazyGetTransactionsQuery,
} = transactionsApi
