import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { MdLogout, MdOutlineAccountCircle } from "react-icons/md"
import { HeaderSidebarProps } from "./sidebarSettings"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import useSupport from "@hooks/useSupport"
import { logout } from "@redux/auth/slice"
import DetailsTypeCard from "@components/ui/badge/detailsType"

export default function SidebarProfile({ close }: HeaderSidebarProps) {
    const { t } = useTranslation(["app", "common"])
    const { navigate } = useRestrictedNavigation()
    const account = useAppSelector(selectAccountDetails)
    const { isBusinessUser } = useSupport()
    const dispatch = useAppDispatch()

    async function handleLogout() {
        await dispatch(logout())
    }

    function handleNav(route: string) {
        navigate(route)
        close()
    }

    return (
        <Box>
            <Box px="0.75rem" borderBottomWidth={1} borderBottomColor={"grey.light.10"}>
                <Flex w="full" flexDir="column" p="0rem 1rem 20px 1rem">
                    <Text textStyle="ManropeMediumBody">
                        {isBusinessUser
                            ? `${account?.business.alt_name ? account.business.alt_name : account?.business.legal_name}`
                            : `${account?.identity.first_name} ${account?.identity.last_name}`}
                    </Text>
                    <Text textStyle="InterRegularBodySmall">{account?.email}</Text>
                    {isBusinessUser && (
                        <DetailsTypeCard alignSelf={"flex-start"} mt="0.5rem" isPersonal={false} mb={0} />
                    )}
                </Flex>
            </Box>
            <Box px="1rem" py={"0.5rem"} borderBottomWidth={1} borderBottomColor={"grey.light.10"}>
                <Flex
                    p="0.75rem 1rem"
                    cursor="pointer"
                    onClick={() => handleNav(ROUTES.PROFILE)}
                    align="center"
                    _hover={{
                        bgColor: "blue.10",
                    }}
                    borderRadius="4px"
                >
                    <Icon as={MdOutlineAccountCircle} color="bluePurple.100" mr="1.5rem" h="24px" w="24px" />
                    <Text color="bluePurple.100" textStyle="ManropeSemiboldBodySmall">
                        {t("nav.viewProfile")}
                    </Text>
                </Flex>
            </Box>
            <Box px="1rem" py={"0.5rem"}>
                <Flex
                    p="0.75rem 1rem"
                    cursor="pointer"
                    onClick={handleLogout}
                    align="center"
                    _hover={{
                        bgColor: "blue.10",
                    }}
                    borderRadius="4px"
                >
                    <Icon as={MdLogout} color="bluePurple.100" mr="1.5rem" h="24px" w="24px" />
                    <Text color="bluePurple.100" textStyle="ManropeSemiboldBodySmall">
                        {t("logout", { ns: "common" })}
                    </Text>
                </Flex>
            </Box>
        </Box>
    )
}
