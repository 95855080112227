import { Td, Tr, Text, Flex, Image, Box, useBreakpointValue } from "@chakra-ui/react"
import IconButton from "@components/ui/buttons/icon"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { MdOutlineEdit, MdEdit } from "react-icons/md"
import { FaRegTrashAlt, FaTrash } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { imageMap } from "@assets/svgs/coins"
import CopyComponent from "@components/ui/copyField"
import { ENUM_COPY_FIELD_VARIANTS } from "@components/ui/copyField/types"
import { IWallet } from "@redux/account/types"
import IconMenu from "@components/ui/menus/icon"
import { memo } from "react"

type Props = {
    wallet: IWallet
    onEditClick: (_: IWallet, __: string) => void
    onDeleteClick: (_: IWallet) => void
    coinName: string
}

function SavedWalletAddressRow({ wallet, onEditClick, onDeleteClick, coinName }: Props) {
    const { coin, label, memo, address } = wallet
    const { t } = useTranslation("settings")
    const image = imageMap[coin.toLowerCase() as keyof typeof imageMap]

    const isMobile = useBreakpointValue({ base: true, md: false })

    return (
        <Tr py={3} display="table-row">
            {isMobile ? (
                <Td py={3} px={2}>
                    <Flex alignItems="center" gap={2}>
                        <Box>
                            <Image
                                src={image}
                                alt={"coin" + coin.toLowerCase()}
                                height="28px"
                                width="28px"
                                borderRadius={"50%"}
                            />
                        </Box>

                        <Flex alignItems="center" flexDir="column" justifyContent={"flex-start"}>
                            <Text textStyle="ManropeSemiboldBodySmall">{coinName}</Text>
                            <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50" mr="auto">
                                {coin}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex flexDir="column" py={2}>
                        <Text textStyle="ManropeSemiboldBodySmall">{label}</Text>
                        <CopyComponent
                            text={address}
                            variant={ENUM_COPY_FIELD_VARIANTS.SECONDARY}
                            textStyle="InterRegularXSmall"
                        />
                    </Flex>

                    <Flex gap={2} alignItems="center" w="100%" justifyContent="space-between">
                        {memo ? (
                            <CopyComponent
                                text={memo ?? ""}
                                variant={ENUM_COPY_FIELD_VARIANTS.SECONDARY}
                                textStyle="ManropeSemiboldBodySmall"
                            />
                        ) : (
                            <Box />
                        )}

                        <IconMenu
                            items={[
                                { text: t("edit", { ns: "common" }), onClick: () => onEditClick(wallet, coinName) },
                                { text: t("delete", { ns: "common" }), onClick: () => onDeleteClick(wallet) },
                            ]}
                        />
                    </Flex>
                </Td>
            ) : (
                <>
                    <Td px={2}>
                        <Flex alignItems="center" gap={3}>
                            <Box>
                                <Image
                                    src={image}
                                    alt={"coin" + coin.toLowerCase()}
                                    height="42px"
                                    minHeight="42px"
                                    minWidth="42px"
                                    width="42px"
                                    borderRadius={"50%"}
                                />
                            </Box>

                            <Flex gap={2} alignItems="center">
                                <Text textStyle="ManropeSemiboldBodySmall">{coinName}</Text>
                                <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                                    {coin}
                                </Text>
                            </Flex>
                        </Flex>
                    </Td>
                    <Td>
                        <Flex flexDir="column">
                            <Text textStyle="ManropeSemiboldBodySmall">{label}</Text>
                            <CopyComponent
                                text={address}
                                variant={ENUM_COPY_FIELD_VARIANTS.SECONDARY}
                                textStyle="InterRegularXSmall"
                            />
                        </Flex>
                    </Td>
                    <Td>
                        {memo ? (
                            <CopyComponent
                                text={memo ?? ""}
                                variant={ENUM_COPY_FIELD_VARIANTS.SECONDARY}
                                textStyle="ManropeSemiboldBodySmall"
                            />
                        ) : (
                            <Box />
                        )}
                    </Td>
                    <Td width="150px" pr={0}>
                        <Flex alignItems="center" justifyContent="flex-end">
                            <IconButton
                                name="edit"
                                onClick={() => onEditClick(wallet, coinName)}
                                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_GHOST}
                                iconOutline={MdOutlineEdit}
                                icon={MdEdit}
                            />

                            <IconButton
                                name="delete"
                                onClick={() => onDeleteClick(wallet)}
                                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_GHOST}
                                iconOutline={FaRegTrashAlt}
                                icon={FaTrash}
                            />
                        </Flex>
                    </Td>
                </>
            )}
        </Tr>
    )
}

export default memo(SavedWalletAddressRow)
