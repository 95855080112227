import { Box, Flex, Icon, Spinner, Text } from "@chakra-ui/react"
import TextInput from "@components/ui/textInput"
import { useTranslation } from "react-i18next"
import { Control } from "react-hook-form"
import { IWallet } from "@redux/account/types"
import TooltipComp from "@components/tooltip"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { MdCheck, MdClose, MdInfoOutline, MdOutlineReport, MdQuestionMark } from "react-icons/md"
import { Option } from "@components/ui/types"
import { DepositAddressResponse } from "@redux/fund/types"
import { useMemo } from "react"
import Checkbox from "@components/ui/checkbox"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { IconType } from "react-icons"

type Props = {
    wallet?: IWallet
    selectedCoin?: Option<string>
    depositData?: DepositAddressResponse
    control: Control<EnterWalletDetailsFormData>
    isValidAddr?: boolean // undefined means it has not finished first fetch yet
    isValidatingAddr: boolean
    isValidatingFetching: boolean
    isWarningChecked: boolean
    setWarningChecked: (_: boolean) => void
}

export type EnterWalletDetailsFormData = {
    address: string
    label: string
    memo?: string
}

export default function EnterWalletDetails({
    wallet,
    depositData,
    control,
    isValidAddr,
    isValidatingAddr,
    isValidatingFetching,
    isWarningChecked,
    setWarningChecked,
}: Props) {
    const { t } = useTranslation("settings")

    const getIcon = useMemo(() => {
        let iconType: IconType | undefined
        let color: string = "black"
        if ((isValidAddr === undefined && !isValidatingAddr) || !control._getWatch("address")) {
            iconType = MdQuestionMark
        } else if (isValidatingAddr) {
            return <Spinner size="sm" />
        } else {
            iconType = isValidAddr ? MdCheck : MdClose
            color = isValidAddr ? "green.light.100" : "red.light.100"
        }

        return <Icon as={iconType} height="18px" width="18px" color={color} />
    }, [isValidatingAddr, isValidAddr, control._getWatch("address")])

    return (
        <form id="edit-wallet">
            <Flex gap={6} flexDir="column">
                <TextInput
                    label={t("savedWalletAddress.enterWalletAddress")}
                    control={control}
                    name="address"
                    rules={{ required: t("error.required", { ns: "common" }) }}
                    rightIcon={getIcon}
                />
                {isValidAddr === false && !isValidatingFetching && control._getWatch("address") && (
                    <>
                        <InfoAlert
                            icon={{
                                icon: MdOutlineReport,
                            }}
                            variant={ENUM_INFO_ALERT_VARIANT.RED}
                        >
                            {t("savedWalletAddress.destWarning")}
                        </InfoAlert>
                        <Checkbox
                            text={t("savedWalletAddress.destWarningConfirm")}
                            name="skipAddr"
                            isChecked={isWarningChecked}
                            setValue={setWarningChecked}
                        />
                    </>
                )}
                <TextInput
                    label={!wallet ? t("savedWalletAddress.newWalletLabel") : t("savedWalletAddress.walletLabel")}
                    control={control}
                    name="label"
                    rules={{ required: t("error.required", { ns: "common" }) }}
                />

                {depositData?.deposit_address?.tag && (
                    <Box>
                        <Flex dir="row" alignItems={"center"} w="fit-content" mb={1}>
                            <Text textStyle={"ManropeRegularBodySmall"} mr={0.5}>
                                {t("fund.destinationTag", { ns: "transfer" })}
                            </Text>
                            <TooltipComp
                                triggerComponent={<ReactIcon icon={MdInfoOutline} color="blue.100" size={16} />}
                                bodyText={t("fund.destinationTagDesc", { ns: "transfer" })}
                            />
                        </Flex>
                        <TextInput
                            control={control}
                            name="memo"
                            rules={{ required: t("error.required", { ns: "common" }) }}
                        />
                    </Box>
                )}
            </Flex>
        </form>
    )
}
