import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Flex, Text } from "@chakra-ui/react"
import PinInput from "@components/ui/pinInput"
import { WithInputProps } from "../types"
import { useTranslation } from "react-i18next"
import { CiMail } from "react-icons/ci"
import { useEffect } from "react"
import { useSendCloseAccountEmailCodeMutation } from "@redux/account/apiSlice"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"

export default function CloseConfirmEmail({
    value,
    handleChange,
    submitEmailCode,
}: WithInputProps & { submitEmailCode: (code: string) => void }): JSX.Element {
    const { t } = useTranslation("settings")
    const [sendEmailCode] = useSendCloseAccountEmailCodeMutation()
    const account = useAppSelector(selectAccountDetails)

    useEffect(() => {
        if (!account?.tfa) {
            sendEmailCode()
        }
    }, [])

    return (
        <Flex flexDir={"column"} gap={6}>
            <Flex
                alignItems="center"
                width="96px"
                height="56px"
                justifyContent="center"
                bg="green.light.10"
                borderRadius={1}
            >
                <ReactIcon icon={CiMail} size={24} color="green.light.100" />
            </Flex>

            <Flex flexDir="column" gap={2}>
                <Text textStyle="ManropeSemiboldBody">
                    {account?.tfa ? t("2faInfo", { ns: "common" }) : t("profile.checkYourEmail")}
                </Text>
                {!account?.tfa && <Text textStyle="InterRegularBody">{t("profile.sentCodeToEmail")}</Text>}
            </Flex>

            <PinInput
                baseColor="white"
                name="pin"
                numOfFields={6}
                value={value}
                onChange={(value) => handleChange("pin", value)}
                onComplete={submitEmailCode}
            />
        </Flex>
    )
}
