import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { BreakPoints } from "@theme/breakpoints"
import { SIDEBAR_WIDTH_LARGE } from "@components/sidebar"

type Props = {
    children: React.ReactNode
    [key: string]: any
}

export default function ScreenContainer({ children, ...rest }: Props) {
    return (
        <Box flex={1} overflowY={"auto"} pos="relative" {...rest}>
            <Flex
                maxW={`${parseFloat(BreakPoints["2xl"]) * 16 - SIDEBAR_WIDTH_LARGE}px`}
                flexDir={"column"}
                m="0 auto"
                px={{ base: "1rem", lg: "1.5rem" }}
                pt={{ base: "1rem", lg: "1.5rem" }}
                h="full"
            >
                {children}
            </Flex>
        </Box>
    )
}
