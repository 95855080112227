import { Box, Text } from "@chakra-ui/layout"
import ScreenContainer from "@components/ui/containers/screen"
import { useTranslation } from "react-i18next"
import relationshipDisclosure from "@assets/otherHostedPages/relationshipDisclosure.html"

export default function RelationshipDisclosure() {
    const { t } = useTranslation("common")

    return (
        <ScreenContainer>
            <Box pb={8} borderBottomWidth={1} borderBottomColor="grey.light.10">
                <Text textStyle="ManropeMedium4xLarge">{t("relationshipDisclosure")}</Text>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: relationshipDisclosure }} py={8} className="hosted-page" />
        </ScreenContainer>
    )
}
