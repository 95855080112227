import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useEffect, useMemo, useState } from "react"
import SideDrawer, { SIDEDRAWER_BUTTON_ID } from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { Box, Flex } from "@chakra-ui/react"
import EnterWalletDetails, { EnterWalletDetailsFormData } from "./enterWalletDetails"
import useGenericToast from "@hooks/useGenericToast"
import SelectCoin from "./selectCoin"
import { useCreateWalletMutation } from "@redux/account/apiSlice"
import { DEFAULT_BTC_COIN } from "."
import { DepositAddressResponse } from "@redux/fund/types"
import { RadioSelectOption } from "@components/ui/radio/radioSelect"
import ErrorMessage from "@components/ui/errorMessage"
import { useForm } from "react-hook-form"
import useValidateAddr from "./useValidateAddr"

const EMPTY_WALLET_DETAILS = {
    address: "",
    label: "",
    memo: "",
}

type Props = {
    coin: RadioSelectOption | null
    setCoin: (_: RadioSelectOption | null) => void
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    depositData?: DepositAddressResponse
}

export default function AddNewWalletModal({ coin, setCoin, isOpen, toggleSideDrawerOpen, depositData }: Props) {
    const { t } = useTranslation("settings")
    const { successToast } = useGenericToast()
    const {
        isValidatingAddr,
        isValidAddr,
        setIsValidAddr,
        debounceValidateAddr,
        isWarningChecked,
        setWarningChecked,
        isValidatingFetching,
    } = useValidateAddr()
    const [createWallet, { isLoading: createWalletLoading }] = useCreateWalletMutation()

    const [view, setView] = useState(1)
    const [err, setErr] = useState("")
    // TODO uncomment all of these when we re-add 2FA to this flow
    // const [pin, setPin] = useState("")

    const { trigger, control, getValues, reset, watch } = useForm<EnterWalletDetailsFormData>()

    useEffect(() => {
        const val = watch("address")
        if (val) debounceValidateAddr(val, coin!.symbol.toUpperCase())
    }, [watch("address")])

    const next = async () => {
        switch (view) {
            case 1:
                setErr("")
                if (coin) setView(2)
                else setErr(t("savedWalletAddress.error.selectCoin"))
                break
            case 2:
                setErr("")
                if (!trigger()) return

                if (isValidatingAddr || isValidatingFetching) {
                    setErr(t("savedWalletAddress.error.pleaseWait"))
                    return
                }

                if (!isValidAddr && !isWarningChecked) {
                    setErr(t("savedWalletAddress.error.confirmAddr"))
                    return
                }

                const newWalletDetails = {
                    address: getValues("address"),
                    label: getValues("label"),
                    ...(getValues("memo") ? { memo: getValues("memo") } : {}),
                }
                await createWallet({
                    ...newWalletDetails,
                    coin: coin?.symbol as string,
                })
                    .unwrap()
                    .then(() => {
                        closeSideDrawer()
                        successToast(t("savedWalletAddress.walletAddedSuccessfully"))
                        setCoin(DEFAULT_BTC_COIN)
                    })
                    .catch((e) => {
                        const err = e.data?.errors ? Object.values(e.data?.errors)[0] : undefined
                        setErr((err as string) ?? t("savedWalletAddress.error.addWallet"))
                    })
                break
            default:
                break
        }
    }

    const previous = () => {
        setErr("")
        setView((prev) => Math.max(prev - 1, 1))
    }

    const currentView = () => {
        switch (view) {
            case 1:
                return (
                    <SelectCoin
                        selectedOption={coin}
                        setSelectedOption={(option: RadioSelectOption | null) => {
                            setWarningChecked(false)
                            reset(EMPTY_WALLET_DETAILS)
                            setCoin(option)
                        }}
                    />
                )

            case 2:
                return (
                    <EnterWalletDetails
                        depositData={depositData}
                        control={control}
                        isValidatingAddr={isValidatingAddr}
                        isValidAddr={isValidAddr}
                        isWarningChecked={isWarningChecked}
                        setWarningChecked={setWarningChecked}
                        isValidatingFetching={isValidatingFetching}
                    />
                )
            // case 3:
            //     return (
            //         <TwoFACode
            //             description={t("savedWalletAddress.enterTwoFactorCode")}
            //             onChange={setPin}
            //             value={pin}
            //             showTFARecovery
            //             onComplete={clickSideDrawerButton}
            //         />
            //     )
            default:
                return <></>
        }
    }

    const closeSideDrawer = () => {
        toggleSideDrawerOpen()
        setView(1)
        setErr("")
        reset(EMPTY_WALLET_DETAILS)
        setIsValidAddr(undefined)
        setWarningChecked(false)
        // setPin("")
    }

    return (
        <SideDrawer
            isOpen={isOpen}
            toggleSideDrawerOpen={closeSideDrawer}
            header={
                <StandardDrawerHeader
                    title={t("savedWalletAddress.addNewWallet")}
                    onClose={closeSideDrawer}
                    {...(view > 1 && { onBack: previous })}
                />
            }
            extraFooter={
                err && (
                    <Box mb={"10px"}>
                        <ErrorMessage>{err}</ErrorMessage>
                    </Box>
                )
            }
            footerButton={{
                children: view === 2 ? t("savedWalletAddress.addWallet") : t("continue", { ns: "common" }),
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                onClick: next,
                id: SIDEDRAWER_BUTTON_ID,
                isLoading: createWalletLoading,
            }}
        >
            <Flex mt={7} flexDir="column" gap={6}>
                {currentView()}
            </Flex>
        </SideDrawer>
    )
}
