import { Flex, Text, Icon } from "@chakra-ui/react"
import { MdOpenInNew } from "react-icons/md"
import { useTranslation } from "react-i18next"

type Props = {
    title: string
    text: string
    onClick: () => void
    signedAt: string
}

export default function SignedDocumentRow({ text, title, onClick, signedAt }: Props) {
    const { t } = useTranslation(["common", "settings"])

    return (
        <Flex flexDir="column" gap={2}>
            <Text textStyle="ManropeSemiboldBody">{title}</Text>

            <Text textStyle="InterRegularBodySmall" color="grey.light.80" whiteSpace="normal">
                {t("documents.riskStatementAcknowledgedAt", { ns: "settings" })}: {signedAt}
            </Text>

            <Flex
                gap={{ base: 2, md: 6 }}
                alignItems="center"
                flexDir={{ base: "column", md: "row" }}
                justifyContent={"space-between"}
            >
                <Text textStyle="InterRegularBodySmall" whiteSpace="normal">
                    {text}
                </Text>

                <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    cursor="pointer"
                    gap={{
                        base: 1,
                        sm: 3,
                    }}
                    ml={{ base: "auto", md: "unset" }}
                    flex="0 0 auto"
                    whiteSpace="nowrap"
                    sx={{
                        "&:hover": {
                            textDecorationColor: "blue.100 !important",
                            textDecoration: "underline",
                        },
                        "&:active": {
                            textDecorationColor: "blue.70 !important",
                            textDecoration: "underline",
                        },
                    }}
                    onClick={onClick}
                >
                    <Text
                        color={"blue.100"}
                        textStyle={{
                            md: "ManropeSemiboldBody",
                            base: "ManropeSemiboldBodySmall",
                        }}
                    >
                        {t("common:view")}
                    </Text>
                    <Icon as={MdOpenInNew} h="24px" w="24px" color="blue.100" />
                </Flex>
            </Flex>
        </Flex>
    )
}
