import { Flex, Text, OrderedList, ListItem, UnorderedList } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

export default function GoogleWallet() {
    const { t } = useTranslation("app")

    return (
        <Flex flexDir="column" gap={6}>
            <Text textStyle="ManropeSemiboldBody">{t("netcoinsPay.googleWalletInstructions")}</Text>

            <OrderedList gap={3} display="flex" flexDir="column">
                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.googleWalletSteps.one")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.googleWalletSteps.two")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.googleWalletSteps.three")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.googleWalletSteps.four")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.googleWalletSteps.five")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.googleWalletSteps.six")}</Text>
                    <UnorderedList styleType="lower-alpha">
                        <ListItem>
                            <Text textStyle="InterRegularBody">{t("netcoinsPay.receivingOTP")}</Text>
                        </ListItem>

                        <ListItem>
                            <Text textStyle="InterRegularBody">{t("netcoinsPay.loggingToOnlineBanking")}</Text>
                        </ListItem>
                    </UnorderedList>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.googleWalletSteps.seven")}</Text>
                </ListItem>
            </OrderedList>
        </Flex>
    )
}
