import { Flex, Text, OrderedList, ListItem, UnorderedList } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

export default function SamsungWallet() {
    const { t } = useTranslation("app")

    return (
        <Flex flexDir="column" gap={6}>
            <Text textStyle="ManropeSemiboldBody">{t("netcoinsPay.samsungWalletInstructions")}</Text>

            <OrderedList gap={3} display="flex" flexDir="column">
                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.samsungWalletSteps.one")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.samsungWalletSteps.two")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.samsungWalletSteps.three")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.samsungWalletSteps.four")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.samsungWalletSteps.five")}</Text>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.samsungWalletSteps.six")}</Text>
                    <UnorderedList styleType="lower-alpha">
                        <ListItem>
                            <Text textStyle="InterRegularBody">{t("netcoinsPay.receivingOTP")}</Text>
                        </ListItem>

                        <ListItem>
                            <Text textStyle="InterRegularBody">{t("netcoinsPay.loggingToOnlineBanking")}</Text>
                        </ListItem>
                    </UnorderedList>
                </ListItem>

                <ListItem>
                    <Text textStyle="InterRegularBody">{t("netcoinsPay.samsungWalletSteps.seven")}</Text>
                </ListItem>
            </OrderedList>
        </Flex>
    )
}
