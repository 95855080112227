import { Box, Text, Flex } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import SettingsSection, { SETTINGS_SECTION_TITLE_MAXW } from "@components/settings/settingsSection"
import SettingsRow from "@components/settings/settingsRow"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import SettingsRowAdv from "@components/settings/settingsRowAdv"
import ZenLedgerSVg from "@assets/svgs/brands/zenledger.svg"
import KoinlySVg from "@assets/svgs/brands/koinly.svg"
import DashboardFooter from "@components/footer/dashboard"
import { useLocation, useNavigate } from "react-router-dom"
import BuyWithCreditCardDrawer from "./buyWithCredit"
import { useMemo, useState } from "react"
import { useGetAccountDetailsQuery, useGetKoinlySsoMutation } from "@redux/account/apiSlice"
import { ROUTES } from "@routing/routes"
import ChangePass from "./changePass"
import ScreenContainer from "@components/ui/containers/screen"
import { KOINLY_LEARN_MORE, ZEN_LEDGER_LEARN_MORE, ZEN_LEDGER_REFERRAL } from "@hooks/useSupport"
import useGenericToast from "@hooks/useGenericToast"
import { useGetNotificationChannelsQuery } from "@redux/account/apiSlice"
import { INotificationChannelsResponse, NotificationChannels } from "@redux/account/types"
import useRestrictedNavigation, { RESTRICTED_FLOWS } from "@hooks/useRestrictNavigation"
import TwoFADisable from "@screens/twoFASetup/twoFADisable"
import TwoFASetupDrawerHOC from "@screens/twoFASetup/drawerTwoFASetup"

export enum SETTINGS_DRAWERS {
    BUY_WITH_CREDIT_CARD = "BUY_WITH_CREDIT_CARD",
}

export default function Settings(): JSX.Element {
    const { t } = useTranslation("settings")
    const [koinlyLoading, setKoinlyLoading] = useState(false)
    const { data: accountData } = useGetAccountDetailsQuery()
    const navigate = useNavigate()
    const { navigate: restrictNavigation, evaluateRestriction } = useRestrictedNavigation()
    const location = useLocation()

    const openBuyWithCreditCard = () => {
        navigate(location.pathname, { state: { openDrawer: SETTINGS_DRAWERS.BUY_WITH_CREDIT_CARD } })
    }

    const closeDrawer = () => {
        navigate(location.pathname, { state: { openDrawer: null } })
    }

    const [getKoinlySso] = useGetKoinlySsoMutation()

    const openDrawer = location.state?.openDrawer

    const [isChangePassOpen, toggleChangePassOpen] = useState(false)

    const isTFASetup = useMemo(() => accountData?.tfa, [accountData])

    const openZenLedgerReferral = () => {
        window.open(ZEN_LEDGER_REFERRAL, "_blank")
    }

    const { serverErrorToast } = useGenericToast()

    const openKoinlyReferral = async () => {
        try {
            setKoinlyLoading(true)
            const { data } = await getKoinlySso().unwrap()
            window.open(data, "_blank")
        } catch (e) {
            serverErrorToast()
        } finally {
            setKoinlyLoading(false)
        }
    }

    const { data } = useGetNotificationChannelsQuery()

    const filteredSmsEmailNotificationChannels = useMemo(() => {
        if (!data) return {}

        return Object.entries(data).reduce((acc, [key, value]) => {
            // Skip 'success' as it's not an AvailableChannelsDetailsForType
            if (key === "success") return acc

            const optional = value.optional || []
            if (optional.includes(NotificationChannels.EMAIL) && optional.includes(NotificationChannels.SMS)) {
                acc[key as keyof INotificationChannelsResponse] = value
            }
            return acc
        }, {} as Partial<INotificationChannelsResponse>)
    }, [data])

    const [is2FASetupOpen, toggle2FASetupOpen] = useState(false)
    const [is2FADisableOpen, toggle2FADisableOpen] = useState(false)

    const openPriceAlerts = () => navigate(ROUTES.PRICE_ALERTS)

    return (
        <ScreenContainer>
            <Box>
                <Text textStyle="ManropeMedium4xLarge">{t("settings.title")}</Text>
                <Box my={{ base: 8, md: 9 }} borderBottomWidth={1} />

                <Box mx={{ base: 0, xl: "105px" }}>
                    <Flex flexDir="column" gap={{ base: 8, md: 12 }}>
                        <SettingsSection
                            title={t("settings.account")}
                            text={t("settings.accountSub")}
                            children={
                                <Flex flexDir="column" gap={{ base: 3, md: 6 }}>
                                    <SettingsRow
                                        title={t("settings.myProfile")}
                                        text={t("settings.profileSub")}
                                        link={{
                                            href: ROUTES.PROFILE,
                                            label: t("settings.viewDetails"),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("settings.myDocs")}
                                        text={t("settings.docsSub")}
                                        link={{
                                            href: ROUTES.DOCUMENTS,
                                            label: t("viewAll", { ns: "common" }),
                                        }}
                                    />
                                </Flex>
                            }
                        />

                        <Box borderBottomWidth={1} />

                        <SettingsSection
                            title={t("settings.security")}
                            text={t("settings.securitySub")}
                            children={
                                <Flex flexDir="column" gap={{ base: 3, md: 6 }}>
                                    <SettingsRow
                                        title={t("settings.2fa")}
                                        text={t("settings.2faSub")}
                                        button={{
                                            children: t(isTFASetup ? "settings.disable2fa" : "settings.setup2fa"),
                                            variant: ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE,
                                            size: "sm",
                                            onClick: () =>
                                                isTFASetup ? toggle2FADisableOpen(true) : toggle2FASetupOpen(true),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("settings.authDev")}
                                        text={t("settings.authDevSub")}
                                        link={{
                                            href: ROUTES.AUTHORIZED_DEVICES,
                                            label: t("settings.viewAllDevices"),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("settings.accActivity")}
                                        text={t("settings.accActivitySub")}
                                        link={{
                                            href: ROUTES.ACCOUNT_ACTIVITY,
                                            label: t("settings.viewActivity"),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("settings.myPass")}
                                        text={t("settings.myPassSub")}
                                        button={{
                                            children: t("settings.changePass"),
                                            variant: ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE,
                                            size: "sm",
                                            onClick: () => toggleChangePassOpen(true),
                                        }}
                                    />
                                </Flex>
                            }
                        />

                        <Box borderBottomWidth={1} />

                        <Flex
                            flexDir={{ base: "column", lg: "row" }}
                            justify={{ base: "flex-start", md: "space-between" }}
                            columnGap={"1.5rem"}
                            rowGap="1rem"
                        >
                            <Box w="full" maxW={SETTINGS_SECTION_TITLE_MAXW}>
                                <Text textStyle="ManropeSemiboldXLarge">{t("settings.notis")}</Text>
                            </Box>
                            {Object.keys(filteredSmsEmailNotificationChannels).includes("price_alert") && (
                                <SettingsRow
                                    title={t("settings.alerts")}
                                    text={t("settings.alertsSub")}
                                    link={{
                                        href: ROUTES.PRICE_ALERTS,
                                        action: openPriceAlerts,
                                        label: t("viewAll", { ns: "common" }),
                                    }}
                                />
                            )}
                        </Flex>

                        <Box borderBottomWidth={1} />

                        <SettingsSection
                            title={t("settings.savedAddresses")}
                            text={t("settings.savedAddressesSub")}
                            children={
                                <Flex flexDir="column" gap={{ base: 3, md: 6 }}>
                                    <SettingsRow
                                        title={t("crypto", { ns: "common" })}
                                        text={t("settings.cryptoSub")}
                                        link={{
                                            href: ROUTES.SAVED_ADDRESSES_CRYPTO,
                                            label: t("viewAll", { ns: "common" }),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("fiat", { ns: "common" })}
                                        text={t("settings.fiatSub")}
                                        link={{
                                            href: ROUTES.SAVED_ADDRESSES_FIAT,
                                            label: t("viewAll", { ns: "common" }),
                                        }}
                                    />
                                </Flex>
                            }
                        />

                        <Box borderBottomWidth={1} />

                        <SettingsSection
                            title={t("settings.tax")}
                            text={t("settings.taxSub")}
                            children={
                                <Flex flexDir="column" gap={{ base: 3, md: 6 }}>
                                    <SettingsRowAdv
                                        title="ZenLedger"
                                        text={t("settings.zenDesc")}
                                        button={{
                                            children: t("connect", { ns: "common" }),
                                            variant: ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE,
                                            size: "sm",
                                            onClick: openZenLedgerReferral,
                                        }}
                                        icon={ZenLedgerSVg}
                                        isRecommended={true}
                                        bg="#11D7A3"
                                        onLinkOpen={() => window.open(ZEN_LEDGER_LEARN_MORE, "_blank")}
                                    />
                                    <SettingsRowAdv
                                        title="Koinly"
                                        text={t("settings.koinlyDesc")}
                                        button={{
                                            children: t("connect", { ns: "common" }),
                                            variant: ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE,
                                            size: "sm",
                                            onClick: openKoinlyReferral,
                                            isLoading: koinlyLoading,
                                        }}
                                        icon={KoinlySVg}
                                        isRecommended={false}
                                        bg="#356AE6"
                                        onLinkOpen={() => window.open(KOINLY_LEARN_MORE, "_blank")}
                                    />
                                </Flex>
                            }
                        />

                        {accountData?.country === "CA" && (
                            <>
                                <Box borderBottomWidth={1} />

                                <Flex
                                    flexDir={{ base: "column", lg: "row" }}
                                    justify={{ base: "flex-start", md: "space-between" }}
                                    columnGap={"1.5rem"}
                                    rowGap="1rem"
                                >
                                    <Box w="full" maxW={SETTINGS_SECTION_TITLE_MAXW}>
                                        <Text textStyle="ManropeSemiboldXLarge">{t("settings.buyCredit")}</Text>
                                    </Box>
                                    <SettingsRow
                                        text={t("settings.buyCreditSub")}
                                        button={{
                                            children: t("settings.buyViaCredit"),
                                            variant: ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE,
                                            size: "sm",
                                            onClick: openBuyWithCreditCard,
                                        }}
                                    />
                                </Flex>
                            </>
                        )}
                        <Box />
                    </Flex>
                </Box>
            </Box>
            {openDrawer === SETTINGS_DRAWERS.BUY_WITH_CREDIT_CARD && (
                <BuyWithCreditCardDrawer
                    isOpen={openDrawer === SETTINGS_DRAWERS.BUY_WITH_CREDIT_CARD}
                    toggleSideDrawerOpen={closeDrawer}
                />
            )}

            <ChangePass isOpen={isChangePassOpen} toggleSideDrawerOpen={() => toggleChangePassOpen(false)} />

            <TwoFADisable
                isOpen={is2FADisableOpen}
                toggleOpen={() => toggle2FADisableOpen(false)}
                toggleOpenEnable2FA={() => toggle2FASetupOpen(true)}
            />

            <TwoFASetupDrawerHOC isOpen={is2FASetupOpen} toggleSideDrawerOpen={() => toggle2FASetupOpen(false)} />

            <DashboardFooter />
        </ScreenContainer>
    )
}
