import { useTranslation } from "react-i18next"
import TwoFACode from "@screens/twoFASetup/twoFACode"
import { DrawerTwoFASetupContext } from "@screens/twoFASetup/drawerTwoFASetup/DrawerTwoFAContext"
import { Box } from "@chakra-ui/layout"
import useGenericToast from "@hooks/useGenericToast"

export default function TwoFASetupVerify({ toggleSideDrawerOpen }: { toggleSideDrawerOpen: () => void }) {
    const { t } = useTranslation(["app", "common"])

    const { tfaCode, setTFACode, errorMessage, setErrorMessage, handleCodeVerification } =
        DrawerTwoFASetupContext.useContainer()

    const handleTFACodeChange = (value: string) => {
        setTFACode(value)
        setErrorMessage("")
    }

    const { successToast } = useGenericToast()

    const handleTFACodeVerification = async (code: string) => {
        const success = await handleCodeVerification(code)
        if (success) {
            toggleSideDrawerOpen()
            successToast(t("twoFASetup.tfaEnabled", { ns: "twoFA" }))
        }
        return
    }

    return (
        <Box mt={7}>
            <TwoFACode
                title={t("twoFASetup.setupTwoFA", { ns: "twoFA" })}
                description={t("twoFASetup.enterCode", { ns: "twoFA" })}
                error={errorMessage ? true : false}
                value={tfaCode}
                onChange={handleTFACodeChange}
                onComplete={(code) => handleTFACodeVerification(code)}
            />
        </Box>
    )
}
