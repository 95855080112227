import { appendVersion } from "@util/RTKApi"

export const NPAY_TAG_TYPES = {
    REGISTER_NPAY: "registerNPay",
    ADD_NPAY_PRODUCT: "addNPayProduct",
    NPAY_TRANSACTIONS: "nPayTransactions",
    NPAY_OVERVIEW: "nPayOverview",
    LOCK_NPAY_CARD: "lockNPayCard",
    VIEW_NPAY_CARD: "viewNPayCard",
} as const

export const API_PATH = {
    REGISTER_NPAY: appendVersion("pay/register"),
    ADD_NPAY_PRODUCT: appendVersion("pay/add-product"),
    NPAY_TRANSACTIONS: appendVersion("pay/transactions"),
    NPAY_OVERVIEW: appendVersion("pay"),
    LOCK_NPAY: appendVersion("pay/lock"),
    VIEW_NPAY_CARD: appendVersion("pay/view"),
} as const
