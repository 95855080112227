import { Flex, Text } from "@chakra-ui/react"
import TextArea from "@components/ui/textArea"
import { Control, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormData, WithInputProps } from "../types"

export default function CloseFeedback({ value, handleChange }: WithInputProps): JSX.Element {
    const { t } = useTranslation("settings")

    return (
        <Flex flexDir={"column"} gap={6}>
            <Flex flexDir={"column"} gap={2}>
                <Text textStyle="ManropeSemiboldBody">{t("profile.feedback.title")}</Text>
                <Text textStyle="InterRegularBody">{t("profile.feedback.text")}</Text>
            </Flex>

            <TextArea
                value={value}
                currentLength={value.length}
                onChange={(val: string) => handleChange("survey", val)}
                maxLength={175}
                placeholder={t("profile.feedback.thankYou")}
            />
        </Flex>
    )
}
