import { Box, Td, Text, Tr, Flex, Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react"
import IconButton from "@components/ui/buttons/icon"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { MdOutlineEdit, MdEdit } from "react-icons/md"
import { FaRegTrashAlt, FaTrash } from "react-icons/fa"
import { FiMoreVertical } from "react-icons/fi"
import { useTranslation } from "react-i18next"
import { AuthorizedDevice } from "../authorizedDeviceTable"
import { formatShortDateTime } from "@util/dateFormating"
import IconMenu from "@components/ui/menus/icon"

type Props = {
    authorizedDevice: AuthorizedDevice
    onEditClick: (_: AuthorizedDevice) => void
    onDeleteClick: (_: AuthorizedDevice) => void
}

export default function AuthorizedDeviceRow({ onEditClick, onDeleteClick, authorizedDevice }: Props): JSX.Element {
    const { t } = useTranslation("common")
    const { authorized_at, label, user_agent, deviceID } = authorizedDevice

    return (
        <Tr w="full">
            <Td
                w={{ base: "50%", md: "25%" }}
                pl={{ base: 0, md: "0.5rem" }}
                pr="0.5rem"
                py={{ base: "0.5rem", md: "18px" }}
                verticalAlign={"top"}
            >
                <Text whiteSpace="normal" wordBreak="normal" textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                    {formatShortDateTime(new Date(authorized_at))}
                </Text>
                <Box display={{ base: "flex", md: "none" }} h="63px" alignItems="center">
                    <Text whiteSpace="normal" wordBreak="normal" textStyle="ManropeSemiboldBodySmall">
                        {label}
                    </Text>
                </Box>
            </Td>

            <Td
                px={{ base: 0, md: "0.5rem" }}
                display={{ base: "none", md: "table-cell" }}
                py="1rem"
                verticalAlign={"top"}
            >
                <Text whiteSpace="normal" wordBreak="normal" textStyle="ManropeSemiboldBodySmall">
                    {label}
                </Text>
            </Td>
            <Td w="50%" pl={{ base: 0, md: "0.5rem" }} pr={0} pt="1rem" pb={{ base: 0, md: "1rem" }}>
                <Flex align="center" justify="space-between">
                    <Box>
                        <Flex flexDir="column">
                            <Text whiteSpace="normal" wordBreak="normal" textStyle="ManropeSemiboldBodySmall">
                                {user_agent}
                            </Text>
                            <Text
                                whiteSpace="normal"
                                color="grey.light.50"
                                wordBreak="normal"
                                textStyle="ManropeMediumXSmall"
                            >
                                {deviceID}
                            </Text>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex alignItems="center" justifyContent="flex-end" display={{ base: "none", md: "flex" }}>
                            <IconButton
                                name="edit"
                                onClick={() => onEditClick(authorizedDevice)}
                                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_GHOST}
                                iconOutline={MdOutlineEdit}
                                icon={MdEdit}
                            />

                            <IconButton
                                name="delete"
                                onClick={() => onDeleteClick(authorizedDevice)}
                                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_GHOST}
                                iconOutline={FaRegTrashAlt}
                                icon={FaTrash}
                            />
                        </Flex>
                        <Box display={{ base: "flex", md: "none" }}>
                            <IconMenu
                                items={[
                                    { text: t("edit"), onClick: () => onEditClick(authorizedDevice) },
                                    { text: t("delete"), onClick: () => onDeleteClick(authorizedDevice) },
                                ]}
                            />
                        </Box>
                    </Box>
                </Flex>
            </Td>
        </Tr>
    )
}
