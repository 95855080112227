import { Td, Text, Tr, Flex, Box } from "@chakra-ui/react"
import { INPayTransaction } from "@redux/netcoinsPay/types"
import { formatTimestamp } from "@util/dateFormating"
import { formatDollar } from "@util/stringFormatting"
import { capitalize } from "lodash"

type Props = {
    transaction: INPayTransaction
}

export default function NetcoinsPayTransactionRow({ transaction: { created_at, card, amount, metadata } }: Props) {
    const merchant = metadata?.find((meta) => meta.meta_key === "merchant_name")
    const merchantDetails = metadata?.find((meta) => meta.meta_key === "merchant_details")

    return (
        <Tr borderBottom={"1px solid"} borderColor="grey.light.10" justifyContent={"center"}>
            <Td display={{ base: "none", md: "table-cell" }} borderBottom={"none"} pl={2} py={3}>
                <Text textStyle="ManropeSemiboldXSmall" textColor={"grey.light.50"}>
                    {formatTimestamp(created_at)}
                </Text>
            </Td>
            <Td borderBottom={"none"} pl={{ base: 2, md: 0 }} whiteSpace={"normal"} py={3}>
                <Box w="full" display={"inline-block"}>
                    <Text
                        textStyle="ManropeSemiboldXSmall"
                        textColor={"grey.light.50"}
                        display={{ base: "block", md: "none" }}
                    >
                        {formatTimestamp(created_at)}
                    </Text>
                    <Text textStyle="ManropeSemiboldBodySmall">{merchant?.meta_value}</Text>
                    <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                        {merchantDetails?.meta_value}
                    </Text>
                    <Text textStyle="ManropeSemiboldBodySmall" display={{ base: "block", md: "none" }}>
                        {capitalize(card?.card_type)}
                    </Text>
                </Box>
            </Td>
            <Td display={{ base: "none", md: "table-cell" }} borderBottom={"none"} pl={0} py={3}>
                <Text textStyle="ManropeSemiboldBodySmall">{capitalize(card?.card_type)}</Text>
            </Td>
            <Td borderBottom={"none"} pl={0} py={3}>
                <Text textStyle="ManropeSemiboldBodySmall" float="right">
                    {formatDollar(amount)}
                </Text>
            </Td>
        </Tr>
    )
}
