import { TCurrencyType } from "@/types"
import { BALANCES_TAG_TYPES } from "@redux/balances/const"
import { splitApi } from "@redux/splitApi"
import { buildQueryString } from "@util/RTKApi"
import { API_PATH, WITHDRAW_TAG_TYPES } from "./const"
import {
    BanksByFIATResponse,
    PostInteracErrorResponse,
    TBoundaries,
    TCaWireWithdrawParams,
    TFeesFetched,
    TGetUSBanksResponse,
    TUsWireWithdrawParams,
    TValidateAddressParams,
    TValidateAddressResponse,
    TWireWithdrawResponse,
    TWithdrawCryptoParams,
    TWithdrawCryptoResponse,
    TWithdrawFiat,
    UpdateCABankAccountParams,
    CreateCABankAccountParams,
} from "./types"

export const withdrawApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getBoundaries: builder.query<TBoundaries, undefined>({
            query: () => ({
                url: API_PATH.BOUNDARIES,
                method: "GET",
            }),
        }),
        getFees: builder.query<TFeesFetched, undefined>({
            query: () => ({
                url: API_PATH.FEES,
                method: "GET",
            }),
        }),
        // empty array is a successful response for some reason
        postInterac: builder.mutation<[] | PostInteracErrorResponse, TWithdrawFiat>({
            query: (body) => ({
                url: API_PATH.WITHDRAW_INTERAC,
                method: "POST",
                body,
            }),
        }),
        getCABanks: builder.query<BanksByFIATResponse, TCurrencyType>({
            query: (arg) => ({
                url: `${API_PATH.GET_BANKS_CA}/${arg}?mask=0`,
                method: "GET",
            }),
            providesTags: [WITHDRAW_TAG_TYPES.BANKS_CA],
        }),
        createCABank: builder.mutation<void, CreateCABankAccountParams>({
            query: (body) => ({
                url: API_PATH.BANKS_CA,
                method: "POST",
                body,
                responseHandler: "text",
            }),
            invalidatesTags: [WITHDRAW_TAG_TYPES.BANKS_CA],
            transformResponse: () => undefined,
        }),
        deleteCABank: builder.mutation<void, { id: number; currency: "CAD" | "USD" }>({
            query: (params) => ({
                url: `${API_PATH.BANKS_CA}/${params.id}`,
                method: "DELETE",
                responseHandler: "text",
            }),
            transformResponse: () => undefined,
            invalidatesTags: [WITHDRAW_TAG_TYPES.BANKS_CA],
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    withdrawApi.util.updateQueryData("getCABanks", params.currency, (draft) => {
                        const numId = params.id
                        if (draft[numId]) {
                            delete draft[numId]
                        }
                    })
                )
                await queryFulfilled.catch(() => patchResult.undo())
            },
        }),
        updateCABank: builder.mutation<void, { id: number; body: UpdateCABankAccountParams; currency: "CAD" | "USD" }>({
            query: ({ id, body }) => ({
                url: `${API_PATH.BANKS_CA}/${id}`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: [WITHDRAW_TAG_TYPES.BANKS_CA],
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    withdrawApi.util.updateQueryData("getCABanks", params.currency, (draft) => {
                        const bank = draft[params.id]
                        if (bank) {
                            draft[params.id] = { ...bank, ...params.body }
                        }
                    })
                )
                await queryFulfilled.catch(() => patchResult.undo())
            },
        }),
        getUSBanks: builder.query<TGetUSBanksResponse, void>({
            query: () => ({
                url: `${API_PATH.BANKS_US}`,
                method: "GET",
            }),
            providesTags: [WITHDRAW_TAG_TYPES.BANKS_US],
        }),
        validateAddress: builder.query<TValidateAddressResponse, TValidateAddressParams>({
            query: (params) => ({
                url: `${API_PATH.VALIDATE_ADDRESS}${buildQueryString(params)}`,
                method: "GET",
            }),
        }),
        withdrawCrypto: builder.mutation<TWithdrawCryptoResponse, TWithdrawCryptoParams>({
            query: (params) => ({
                url: API_PATH.WITHDRAW_CRYPTO,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [BALANCES_TAG_TYPES.BALANCES],
        }),
        withdrawWireCA: builder.mutation<TWireWithdrawResponse, TCaWireWithdrawParams>({
            query: (body) => ({
                url: API_PATH.WIRE_WITHDRAW_CA,
                method: "POST",
                body,
            }),
            invalidatesTags: [WITHDRAW_TAG_TYPES.BANKS_CA, BALANCES_TAG_TYPES.BALANCES],
        }),
        withdrawWireUS: builder.mutation<TWireWithdrawResponse, TUsWireWithdrawParams>({
            query: (body) => ({
                url: API_PATH.WIRE_WITHDRAW_US,
                method: "POST",
                body,
            }),
            invalidatesTags: [WITHDRAW_TAG_TYPES.BANKS_US, BALANCES_TAG_TYPES.BALANCES],
        }),
    }),
})

export const {
    useGetBoundariesQuery,
    useGetFeesQuery,
    usePostInteracMutation,
    useGetCABanksQuery,
    useLazyGetCABanksQuery,
    useGetUSBanksQuery,
    useLazyGetUSBanksQuery,
    useLazyValidateAddressQuery,
    useWithdrawCryptoMutation,
    useWithdrawWireCAMutation,
    useWithdrawWireUSMutation,
    useCreateCABankMutation,
    useDeleteCABankMutation,
    useUpdateCABankMutation,
} = withdrawApi
