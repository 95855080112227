import profile from "./profile.json"
import settings from "./settings.json"
import notifications from "./notifications.json"
import documents from "./documents.json"
import authorizedDevices from "./authorizedDevices.json"
import savedFiatAccount from "./savedFiatAccount.json"
import savedWalletAddress from "./savedWalletAddress.json"
import activity from "./activity.json"

export default <const>{
    profile,
    settings,
    notifications,
    documents,
    authorizedDevices,
    savedWalletAddress,
    activity,
    savedFiatAccount,
}
