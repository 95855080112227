import { Text, Table, Tbody, Tr, Th, Thead, TableContainer, Td, Flex } from "@chakra-ui/react"
import NetcoinsPayTransactionsRow from "@components/netcoinsPay/netcoinsPayTransactionsRow"
import { INPayTransaction } from "@redux/netcoinsPay/types"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export interface Props {
    data?: INPayTransaction[]
    isLoading?: boolean
}

export default function NetcoinsPayTransactionsTable({ data, isLoading }: Props) {
    const { t } = useTranslation(["app", "common"])

    return (
        <TableContainer w="100%">
            <Table>
                <Thead bg="grey.light.5" borderBottom={"1px solid"} borderColor="grey.light.10">
                    <Tr>
                        <Th display={{ base: "none", md: "table-cell" }} borderBottom={"none"} pl={2} py={3}>
                            <Text textStyle="ManropeSemiboldXSmall" textTransform="none" color="grey.light.90">
                                {t("netcoinsPay.date")}
                            </Text>
                        </Th>

                        <Th borderBottom={"none"} pl={{ base: 2, md: 0 }} py={3}>
                            <Text textStyle="ManropeSemiboldXSmall" textTransform="none" color="grey.light.90">
                                {t("netcoinsPay.transaction")}
                            </Text>
                        </Th>

                        <Th display={{ base: "none", md: "table-cell" }} borderBottom={"none"} pl={0} py={3}>
                            <Text textStyle="ManropeSemiboldXSmall" textTransform="none" color="grey.light.90">
                                {t("netcoinsPay.cardType")}
                            </Text>
                        </Th>

                        <Th borderBottom={"none"} py={3} />
                    </Tr>
                </Thead>

                <Tbody>
                    {isLoading ? (
                        <Tr>
                            <Td colSpan={4} py={6} borderBottom={"none"}>
                                <Flex w="100%" justifyContent="center">
                                    <Text>{t("common:loading")}...</Text>
                                </Flex>
                            </Td>
                        </Tr>
                    ) : data && data?.length > 0 ? (
                        data.map((transaction, index) => (
                            <NetcoinsPayTransactionsRow key={index} transaction={transaction} />
                        ))
                    ) : (
                        <Tr>
                            <Td colSpan={4} py={6} borderBottom={"none"}>
                                <Flex w="100%" justifyContent="center">
                                    <Text>{t("common:noResults")}</Text>
                                </Flex>
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </TableContainer>
    )
}
