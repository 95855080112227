import React from "react"
import { Text } from "@chakra-ui/react"
import PlainModal from "@components/ui/modals/plain"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"

type Props = {
    isOpen: boolean
    toggleOpen: () => void
    isLoading: boolean
    onClick: () => void
    cancelRef: React.RefObject<HTMLButtonElement>
}

export default function DeleteWalletModal({ isOpen, toggleOpen, isLoading, onClick, cancelRef }: Props) {
    const { t } = useTranslation(["settings", "common"])

    return (
        <PlainModal
            title={t("areYouSure", { ns: "common" })}
            isOpen={isOpen}
            cancelRef={cancelRef}
            onClose={toggleOpen}
            buttons={[
                {
                    variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                    children: t("savedWalletAddress.deleteWalletButton"),
                    isLoading,
                    onClick,
                },
            ]}
            showErrIcon
        >
            <Text textStyle="InterRegularBody">{t("savedWalletAddress.deleteConfirmWallet")}</Text>
        </PlainModal>
    )
}
