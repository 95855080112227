import {
    Box,
    Grid,
    HStack,
    Text,
    VStack,
    Collapse,
    Button,
    IconButton,
    Popover,
    PopoverContent,
    PopoverTrigger,
    useDisclosure,
    Flex,
} from "@chakra-ui/react"
import React, { useState, useCallback, useEffect, useRef, useMemo } from "react"
import { useTranslation } from "react-i18next"
import StandardButton from "../buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "../buttons/standard/types"
import { MdChevronLeft, MdChevronRight, MdClose, MdOutlineCalendarToday } from "react-icons/md"
import { CalendarHeader, months } from "./components/calendarHeader"
import { YearMonthSelector } from "./components/yearMonthSelector"
import { DayGrid } from "./components/dayGrid"

interface DatePickerProps {
    label?: string
    onDateChange?: (date: Date | null) => void
    minDate?: Date | null
    maxDate?: Date | null
    selectedDate?: Date | null
}

const DatePicker: React.FC<DatePickerProps> = ({ label = "Date", onDateChange, minDate, maxDate, selectedDate }) => {
    const [date, setDate] = useState<Date | null>(selectedDate || null)
    const [displayDate, setDisplayDate] = useState<Date>(new Date(selectedDate || new Date()) || "")
    const [expandedYear, setExpandedYear] = useState<number | null>(null)
    const [isYearMonthView, setIsYearMonthView] = useState(false)
    const { t } = useTranslation(["common"])

    const popover = useDisclosure()
    const minYear = minDate ? minDate.getFullYear() : 1901
    const maxYear = maxDate ? maxDate.getFullYear() : new Date().getFullYear()
    const years = Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i)

    const yearRefs = useRef<Map<number, HTMLDivElement>>(new Map())
    const scrollRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (selectedDate) {
            setDate(selectedDate)
            setDisplayDate(selectedDate)
        }
        if (selectedDate === null) {
            setDate(null)
            onDateChange?.(null)
            popover.onClose()
        }
    }, [selectedDate])

    const isDateDisabled = useCallback(
        (date: Date) => {
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            if (date > today) return true
            if (minDate && date < minDate) return true
            if (maxDate && date > maxDate) return true

            return false
        },
        [minDate, maxDate]
    )

    const handleDateSelect = (selectedDate: Date) => {
        setDate(selectedDate)
        onDateChange?.(selectedDate)
        setDisplayDate(selectedDate)
        setIsYearMonthView(false)
        popover.onClose()
    }

    const handleYearClick = (year: number) => {
        const scrollTop = scrollRef.current ? scrollRef.current.scrollTop : 0

        if (expandedYear === year) {
            setExpandedYear(null)
        } else {
            setExpandedYear(year)
            setDisplayDate(new Date(year, displayDate.getMonth(), 1))
        }

        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollTop
            }
        }, 0)
    }

    const handleMonthSelect = (monthIndex: number, year: number) => {
        setDisplayDate(new Date(year, monthIndex, 1))
        setIsYearMonthView(false)
        setExpandedYear(null)
    }

    const handleClear = () => {
        setDate(null)
        onDateChange?.(null)
        popover.onClose()
    }

    const handleToday = () => {
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        setDate(today)
        onDateChange?.(today)
        setDisplayDate(today)
        setIsYearMonthView(false)
        popover.onClose()
    }

    const handlePrevMonth = () => {
        setDisplayDate(new Date(displayDate.getFullYear(), displayDate.getMonth() - 1))
    }

    const handleNextMonth = () => {
        setDisplayDate(new Date(displayDate.getFullYear(), displayDate.getMonth() + 1))
    }

    useEffect(() => {
        const container = yearRefs.current.get(expandedYear || displayDate.getFullYear())

        if (container) {
            container.scrollIntoView({
                behavior: "auto",
                block: "center",
            })
        }
    }, [isYearMonthView])

    return (
        <Box>
            <Popover isOpen={popover.isOpen} onClose={popover.onClose} onOpen={popover.onOpen} placement="bottom-start">
                <PopoverTrigger>
                    <Button variant="outline" rightIcon={<MdOutlineCalendarToday size={18} />}>
                        <Text textStyle={"ManropeMediumBodySmall"}>{date ? date.toLocaleDateString() : label}</Text>
                    </Button>
                </PopoverTrigger>
                <PopoverContent width="fit-content" py={6} px={{ base: 0, sm: 6 }}>
                    <VStack spacing={isYearMonthView ? 2 : 3} align="stretch">
                        <CalendarHeader
                            displayDate={displayDate}
                            isYearMonthView={isYearMonthView}
                            setIsYearMonthView={setIsYearMonthView}
                            handlePrevMonth={handlePrevMonth}
                            handleNextMonth={handleNextMonth}
                        />
                        {isYearMonthView ? (
                            <YearMonthSelector
                                displayDate={displayDate}
                                expandedYear={expandedYear}
                                handleYearClick={handleYearClick}
                                handleMonthSelect={handleMonthSelect}
                                years={years}
                                months={months}
                            />
                        ) : (
                            <>
                                <DayGrid
                                    displayDate={displayDate}
                                    selectedDate={date}
                                    isDateDisabled={isDateDisabled}
                                    handleDateSelect={handleDateSelect}
                                />
                                <HStack justify="space-between">
                                    <StandardButton
                                        size="sm"
                                        onClick={handleClear}
                                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_GHOST}
                                        leftIcon={MdClose}
                                    >
                                        {t("common:clear")}
                                    </StandardButton>
                                    <StandardButton
                                        size="sm"
                                        onClick={handleToday}
                                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_GHOST}
                                    >
                                        {t("common:today")}
                                    </StandardButton>
                                </HStack>
                            </>
                        )}
                    </VStack>
                </PopoverContent>
            </Popover>
        </Box>
    )
}

export default DatePicker
