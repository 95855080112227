import { useAppSelector } from "@/store/hooks"
import { Box, Flex, Text } from "@chakra-ui/react"
import DocumentTable from "@components/settings/documents/documentTable"
import SignedDocumentRow from "@components/settings/documents/signedDocumentRow"
import { selectAccountDetails } from "@redux/account/selectors"
import { ROUTES } from "@routing/routes"
import { format } from "date-fns"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

export default function DocumentsPage({ selectedIndex = 0 }: { selectedIndex?: 0 | 1 }) {
    const { t } = useTranslation("settings")
    const account = useAppSelector(selectAccountDetails)

    const riskSignedAt = useMemo(() => {
        const dateAcceptedAt = account?.disclaimers.risk_statement?.accepted_at

        if (!dateAcceptedAt) {
            return ""
        }

        const formattedDate = format(new Date(dateAcceptedAt), "yyyy-MM-dd")
        return formattedDate
    }, [account])

    const openRiskStatement = useCallback(() => {
        const link = ROUTES.RISK_STATEMENT
        window.open(link, "_blank")
    }, [])

    return (
        <Flex flexDir="column" w="100%" maxWidth={{ md: "813px" }} mx={{ md: "auto" }} pb={8}>
            {account?.country === "CA" && (
                <SignedDocumentRow
                    onClick={openRiskStatement}
                    title={t("documents.riskStatement")}
                    text={t("documents.riskStatementText")}
                    signedAt={riskSignedAt}
                />
            )}

            <Flex
                mt={6}
                mb={4}
                px={4}
                py={4}
                borderRadius="4px"
                bg="grey.light.2"
                border="1px"
                borderColor="grey.light.10"
            >
                <Text textStyle="InterRegularBody" color="grey.light.80">
                    {t("documents.yourStatement")}
                </Text>
            </Flex>
            <DocumentTable selectedIndex={selectedIndex} />
        </Flex>
    )
}
