import React from "react"
import { IconType, IconBaseProps } from "react-icons"
import { Box, Flex, Icon } from "@chakra-ui/react"

export type ReactIconWrapperProps = {
    icon: IconType
    size?: number
    color?: string
} & Omit<IconBaseProps, "size" | "color">

const ReactIcon: React.FC<ReactIconWrapperProps> = ({ icon, size = 14, color, ...props }) => {
    return (
        <Flex h={`${size}px`} w={`${size}px`} justify={"center"} align="center">
            <Icon h={`${size - 4}px`} w={`${size - 4}px`} as={icon} color={color ?? "currentcolor"} />
        </Flex>
    )
}

export default ReactIcon
