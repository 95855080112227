import { Box, Text, useTheme, VStack } from "@chakra-ui/react"
import React, { useContext } from "react"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import ErrorMessage from "components/ui/errorMessage"
import PinInput from "components/ui/pinInput"
import AmplitudeClient from "sdks/amplitude"
import { LOGIN_SCREENS, REGISTRATION_STEP } from "@screens/onboarding/types"
import useFetchWrapper, { Method } from "hooks/useFetchWrapper"
import { useTranslation } from "react-i18next"
import { SMALL_SCREEN_WIDTH } from "theme/consts"
import NetcoinsLogo from "assets/svgs/netcoins/netcoins-logo"
import NetcoinsLogoText from "assets/svgs/netcoins/netcoins-logo-text"

type Props = {
    email: string
    sendVerificationEmail: () => void
    handleSuccess: () => void
}

export default function EmailVerify({ email, sendVerificationEmail, handleSuccess }: Props) {
    const { t } = useTranslation(["login", "common"])
    const theme = useTheme()
    const { fetchWrapper } = useFetchWrapper()

    const [code, setCode] = React.useState("")
    const [isFailed, setIsFailed] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [buttonText, setButtonText] = React.useState(t("emailAuthentication.resend"))
    const [isLoggingOut, setIsLoggingOut] = React.useState(false)

    async function confirmEmailVerification(value?: string) {
        setIsLoading(true)
        const finalCode = parseInt(value ?? code) // this accounts for when onComplete finishes before setCode does
        fetchWrapper("/email/verification", Method.POST, { code: finalCode }, false, {
            "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? "",
        })
            .then((res) => {
                AmplitudeClient.logRegistrationEvent(LOGIN_SCREENS.EmailVerification, REGISTRATION_STEP.VERIFY_EMAIL)
                handleSuccess()
            })
            .catch(() => setIsFailed(true))
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <Box
                columnGap="1rem"
                paddingY="2rem"
                justifyContent={"center"}
                alignItems="center"
                w="full"
                maxW={SMALL_SCREEN_WIDTH}
                display={{ base: "flex", xl: "none" }}
                m="auto"
            >
                <NetcoinsLogo color={`${theme.colors.darkBlue[100]}`} width={31} />
                <NetcoinsLogoText color={theme.colors.darkBlue[100]} width={123} />
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="center" mt={{ base: 0, sm: "6rem" }}>
                <Box
                    flexDir={"column"}
                    display="flex"
                    maxW={SMALL_SCREEN_WIDTH}
                    flexGrow={1}
                    w="full"
                    justifyContent="flex-start"
                    mt={{ base: 0, xl: "4.5rem" }}
                >
                    <Text textStyle="ManropeMedium5xLarge" alignSelf={"flex-start"} mb="1.5rem">
                        {t("emailAuthentication.title")}
                    </Text>
                    <Text textStyle="InterRegularBody" mb="1.5rem">
                        {t("emailAuthentication.notice", { email })}
                    </Text>
                    <PinInput
                        name="email-code"
                        baseColor="white"
                        error={isFailed}
                        value={code}
                        onChange={setCode}
                        numOfFields={6}
                        size="lg"
                        onComplete={confirmEmailVerification}
                        otp
                    />
                    {isFailed && <ErrorMessage>{t("emailAuthentication.codeIncorrect")}</ErrorMessage>}
                    <Text textStyle="InterRegularBody" my="1.5rem">
                        {t("emailAuthentication.maybeSpam")}
                    </Text>
                    <StandardButton
                        data-testid="resend-email-button"
                        onClick={() => {
                            setButtonText(t("emailAuthentication.sent"))
                            setTimeout(() => {
                                setButtonText(t("emailAuthentication.resend"))
                            }, 10000)
                            sendVerificationEmail()
                        }}
                        disabled={buttonText === t("emailAuthentication.sent")}
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_OUTLINE}
                        type="button"
                        isLoading={isLoading || isLoggingOut}
                        mb="0.75rem"
                    >
                        {buttonText}
                    </StandardButton>
                    <StandardButton
                        data-testid="logout-button"
                        onClick={() => window.open("/logout", "_self")}
                        disabled={isLoading || isLoggingOut}
                        mt="0.75rem"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_GHOST}
                        type="button"
                        isLoading={isLoggingOut}
                    >
                        {t("logout", { ns: "common" })}
                    </StandardButton>
                </Box>
            </Box>
        </>
    )
}
