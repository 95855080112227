import { Box, Spinner } from "@chakra-ui/react"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import HeaderDashboard, { HEADER_HEIGHT } from "components/header/dashboard"
import Sidebar from "components/sidebar"
import { Outlet } from "react-router-dom"

export default function Dashboard() {
    const { data } = useGetAccountDetailsQuery()

    return (
        <Box h="100vh" display="flex" flexDir="column">
            {data ? (
                <>
                    <HeaderDashboard />
                    <Box display="flex" height={`calc(100vh - ${HEADER_HEIGHT}px)`} pos="relative">
                        <Sidebar />
                        <Outlet />
                    </Box>
                </>
            ) : (
                <Box flex="1" display="flex" alignItems="center" justifyContent="center">
                    <Spinner size="xl" />
                </Box>
            )}
        </Box>
    )
}
