import { Flex, Text } from "@chakra-ui/react"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { useTranslation } from "react-i18next"
import { RiErrorWarningLine } from "react-icons/ri"

export default function ClosePermanent(): JSX.Element {
    const { t } = useTranslation("settings")

    return (
        <Flex flexDir={"column"} gap={6}>
            <Flex flexDir={"column"} gap={3}>
                <Text textStyle="ManropeSemiboldBody">{t("profile.lastChance")}</Text>
                <Text textStyle="InterRegularBody">{t("profile.hateToGo")}</Text>
            </Flex>

            <InfoAlert
                variant={ENUM_INFO_ALERT_VARIANT.RED}
                children={t("profile.thisAction")}
                icon={{
                    icon: RiErrorWarningLine,
                }}
            />
        </Flex>
    )
}
