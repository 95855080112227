import React, { useCallback, useMemo } from "react"
import { Box, Grid, Text, Button } from "@chakra-ui/react"

interface DayGridProps {
    displayDate: Date
    selectedDate: Date | null
    isDateDisabled: (date: Date) => boolean
    handleDateSelect: (date: Date) => void
}

export const DayGrid = React.memo<DayGridProps>(
    ({ displayDate, selectedDate, isDateDisabled, handleDateSelect }) => {
        const getDaysArray = useCallback(() => {
            const daysArray = []
            const daysInMonth = new Date(displayDate.getFullYear(), displayDate.getMonth() + 1, 0).getDate()
            const firstDay = new Date(displayDate.getFullYear(), displayDate.getMonth(), 1).getDay()

            // Empty cells for days before the first day of the month
            for (let i = 0; i < firstDay; i++) {
                daysArray.push(<Box key={`empty-${i}`} h="32px" />)
            }

            // Days of the month
            for (let day = 1; day <= daysInMonth; day++) {
                const currentDay = new Date(displayDate.getFullYear(), displayDate.getMonth(), day)
                const isSelected = selectedDate?.getTime() === currentDay.getTime()
                const isDisabled = isDateDisabled(currentDay)

                daysArray.push(
                    <Button
                        key={`day-${day}`}
                        size="sm"
                        variant={isSelected ? "solid" : "ghost"}
                        onClick={() => !isDisabled && handleDateSelect(currentDay)}
                        isDisabled={isDisabled}
                        bg={isSelected ? "blue.100" : undefined}
                        color={isSelected ? "white" : undefined}
                        opacity={isDisabled ? 0.5 : 1}
                        w="40px"
                        h="40px"
                        p={0}
                    >
                        <Text textStyle={"ManropeMediumBody"} color="currentcolor">
                            {day}
                        </Text>
                    </Button>
                )
            }

            return daysArray
        }, [displayDate, selectedDate, isDateDisabled, handleDateSelect])

        const days = useMemo(() => getDaysArray(), [getDaysArray])

        return (
            <Grid templateColumns="repeat(7, 1fr)" gap={1}>
                {days}
            </Grid>
        )
    },
    (prevProps, nextProps) => {
        return (
            prevProps.displayDate.getTime() === nextProps.displayDate.getTime() &&
            prevProps.selectedDate?.getTime() === nextProps.selectedDate?.getTime() &&
            prevProps.isDateDisabled === nextProps.isDateDisabled &&
            prevProps.handleDateSelect === nextProps.handleDateSelect
        )
    }
)
