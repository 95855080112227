import { useDisclosure } from "@chakra-ui/react"
import { useGetTFAKeyQuery, useSaveTFAKeyMutation } from "@redux/account/apiSlice"
import { createContainer } from "@util/UnstatedContext"
import { isString } from "lodash"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const useTwoFASetupContext = () => {
    const [view, setView] = useState<number>(0)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [accept2FARisk, setAccept2FARisk] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorDialog, setErrorDialog] = useState<string>("")
    const [saveTFAKey, saveTFAKeyData] = useSaveTFAKeyMutation()

    const { t } = useTranslation(["app", "common"])

    const { data: keyData } = useGetTFAKeyQuery()
    const { secret, qr } = keyData || {}
    const [tfaCode, setTFACode] = useState("")

    const handleCodeVerification = async (code?: string) => {
        setErrorMessage("")
        const codeToVerify = code || tfaCode

        if (!codeToVerify || codeToVerify.length < 6) {
            setErrorMessage(t("twoFASetup.enterValidTfa", { ns: "twoFA" }))
            return false
        }

        setLoading(true)

        try {
            await saveTFAKey({ one_time_password: codeToVerify, google2fa_secret_key: secret || "" }).unwrap()
            return true
        } catch (error: any) {
            const message = error.data?.message || error.message || error.data?.error
            setErrorMessage(isString(message) ? message : t("common:error.message"))
            return false
        } finally {
            setLoading(false)
        }
    }

    return {
        view,
        setView,

        errorMessage,
        setErrorMessage,

        accept2FARisk,
        setAccept2FARisk,

        qr,
        secret,

        tfaCode,
        setTFACode,

        handleCodeVerification,

        loading,
        setLoading,

        errorDialog,
        setErrorDialog,
    }
}

export const DrawerTwoFASetupContext = createContainer(useTwoFASetupContext)
