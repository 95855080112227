import { useAppSelector } from "@/store/hooks"
import { Box, Flex, Image, Skeleton, Text, useDisclosure } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import MarketChange from "@components/ui/marketChange"
import { ErrorModal } from "@components/ui/modals/errorModal"
import useImage from "@hooks/useImage"
import useRestrictedNavigation, { RESTRICTED_FLOWS } from "@hooks/useRestrictNavigation"
import useSupport from "@hooks/useSupport"
import { selectAccountDetails } from "@redux/account/selectors"
import { TUserState } from "@redux/account/types"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { IOrder } from "@redux/orders/types"
import { Transaction } from "@redux/transactions/types"
import { ROUTES } from "@routing/routes"
import { addCommasToNumber, removeTrailingZeros } from "@util/stringFormatting"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdAdd, MdRemove } from "react-icons/md"
import { useParams } from "react-router"
import DrawerOpenOrder from "../openOrders/drawerOpenOrders"
import DrawerCoinTrade, { BUYSELL_TAB, SIDE_DRAWER_MARKET_DETAILS_WIDTH } from "../trade/drawerCoinTrade"
import DrawerTransactionDetails from "../transactions/drawerTransactionDetails"
import AssetStats from "./assetStats"
import MarketDetailsGraph from "./graph"
import { imageMap } from "@assets/svgs/coins"
import ScreenContainer from "@components/ui/containers/screen"
import { SIDEBAR_WIDTH_LARGE } from "@components/sidebar"
import { BreakPoints } from "@theme/breakpoints"

export default function MarketDetails() {
    const { t } = useTranslation(["marketDetails", "common", "app"])
    const { asset } = useParams()
    const { navigate, evaluateRestriction, isEvaluationState, userState } = useRestrictedNavigation()
    const { data: assetDetailsData, isLoading } = useGetAssetsDetailsQuery(undefined)
    const accountCurrency = useAppSelector(selectAccountDetails)?.currency
    const image = imageMap[asset?.toLowerCase() as keyof typeof imageMap]

    const [isDrawerOpen, toggleDrawerOpen] = useState(false)
    const [tab, setTab] = useState<BUYSELL_TAB>(BUYSELL_TAB.BUY)
    const [selectedOrder, setSelectedOrder] = useState<Transaction | null>(null)
    const [selectedTx, setSelectedTx] = useState<Transaction | null>(null)
    const [dialogError, setDialogError] = useState<{ title: string; description: string } | undefined>(undefined)
    const { isOpen: isModalOpen, onOpen, onClose: onModalClose } = useDisclosure()
    const { onbSupportEmail } = useSupport()

    const openDrawer = (tab: BUYSELL_TAB) => {
        const isRestricted = evaluateRestriction(RESTRICTED_FLOWS.TRADE, undefined, { returnBoolean: true })
        if (isRestricted) {
            return popupError()
        }
        setTab(tab)
        toggleDrawerOpen(true)
    }

    const closeDrawer = () => {
        toggleDrawerOpen(false)
    }

    const popupError = () => {
        if (isEvaluationState) {
            setDialogError({
                title: t("app:buysell.error.evaluation.title"),
                description: t("app:buysell.error.evaluation.message", { email: onbSupportEmail }),
            })
        } else if (userState === TUserState.VIEW_ONLY) {
            setDialogError({
                title: t("app:buysell.error.viewOnly.title"),
                description: t("app:buysell.error.viewOnly.message"),
            })
        }
        onOpen()
    }
    const closePopup = () => {
        setDialogError(undefined)
        onModalClose()
    }

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const MarketColor = useMemo(() => {
        const val: number | undefined = data?.["24h_percent"]
        if (val === undefined || val === 0) return "#636366"

        return val > 0 ? "#1B881F" : "#C62828"
    }, [data])

    return (
        <Flex h="full" w="full">
            <Flex
                flex={1}
                overflowY="auto"
                className="hide-scroll"
                px={{ base: "1rem", lg: "1.5rem" }}
                mt={{ base: "1rem", lg: "1.5rem" }}
                maxW={`${parseFloat(BreakPoints["2xl"]) * 16 - SIDEBAR_WIDTH_LARGE}px`}
                m="0 auto"
            >
                <Flex flexDir="column" flex={1}>
                    <Flex flexDir="column" mb={{ base: "1rem", lg: "1.5rem" }} flex={1}>
                        <BreadCrumbGroup
                            mb="0.5rem"
                            breadCrumbs={[
                                { text: "Trade", onClick: () => navigate(ROUTES.DASHBOARD_TRADE) },
                                { text: data?.name ?? "" },
                            ]}
                        />
                        <Box
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems={{ base: undefined, md: "flex-end" }}
                            flexDir={{ base: "column", md: "row" }}
                            pb={"2rem"}
                            rowGap="1rem"
                            position={{ base: "relative", lg: "sticky" }}
                            top={0}
                            zIndex={2}
                            bgColor="white"
                        >
                            <Box display="flex" flex={1}>
                                {image && (
                                    <Image mr="0.75rem" src={image} alt={"coin-" + asset} height="60px" width="60px" />
                                )}
                                <Box display="flex" flexDir={"column"}>
                                    <Text textStyle="ManropeSemiboldBody" whiteSpace={"nowrap"}>
                                        {data?.name}&nbsp;
                                        <Text textStyle="ManropeSemiboldBodySmall" as="span" color="grey.dark.50">
                                            {data?.symbol}
                                        </Text>
                                    </Text>
                                    <Skeleton
                                        isLoaded={!isLoading}
                                        display="flex"
                                        alignItems="baseline"
                                        flexWrap={"wrap"}
                                    >
                                        <Text textStyle="ManropeMedium2xLarge" mr="0.75rem">
                                            ${addCommasToNumber(removeTrailingZeros(data?.price ?? "0"))}&nbsp;
                                            <Text as="span" textStyle="ManropeBoldBody">
                                                {accountCurrency}
                                            </Text>
                                        </Text>
                                        <Flex alignItems="center">
                                            <MarketChange
                                                hideBackground
                                                percent={data?.["24h_percent"] ?? 0}
                                                size="lg"
                                            />
                                            <Text
                                                ml="0.25rem"
                                                as="span"
                                                textStyle={"ManropeSemiboldBodySmall"}
                                                color={MarketColor}
                                            >{`(24H)`}</Text>
                                        </Flex>
                                    </Skeleton>
                                </Box>
                            </Box>
                            <Box
                                display={isDrawerOpen ? "none" : "flex"}
                                flex={1}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                                columnGap="0.5rem"
                            >
                                <StandardButton
                                    flexGrow={1}
                                    type="button"
                                    leftIcon={MdAdd}
                                    variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                                    onClick={() => {
                                        openDrawer(BUYSELL_TAB.BUY)
                                    }}
                                    alignSelf="flex-end"
                                    size="xl"
                                >
                                    {t("buy", { ns: "common" })}
                                </StandardButton>
                                <StandardButton
                                    flexGrow={1}
                                    type="button"
                                    leftIcon={MdRemove}
                                    variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                                    onClick={() => {
                                        openDrawer(BUYSELL_TAB.SELL)
                                    }}
                                    alignSelf="flex-end"
                                    size="xl"
                                >
                                    {t("sell", { ns: "common" })}
                                </StandardButton>
                            </Box>
                        </Box>
                        <MarketDetailsGraph asset={asset} />
                        {data && (
                            <AssetStats
                                isDrawerOpen={isDrawerOpen}
                                asset={data}
                                setSelectedTx={setSelectedTx}
                                setSelectedOrder={setSelectedOrder}
                            />
                        )}
                    </Flex>
                    <DashboardFooter />
                </Flex>
            </Flex>
            {/* shoves over the main content by the width of the open drawer (open drawer is pos absolute) */}
            {isDrawerOpen && <Box h="full" w={isDrawerOpen ? `${SIDE_DRAWER_MARKET_DETAILS_WIDTH}px` : 0}></Box>}
            {data && (
                <DrawerCoinTrade
                    initialAsset={data}
                    isOpen={isDrawerOpen}
                    toggleSideDrawerOpen={closeDrawer}
                    tab={tab}
                    setTab={setTab}
                />
            )}
            <DrawerTransactionDetails
                transaction={selectedTx}
                isOpen={!!selectedTx}
                toggleSideDrawerOpen={() => setSelectedTx(null)}
            />
            <DrawerOpenOrder
                order={selectedOrder}
                isOpen={!!selectedOrder}
                toggleSideDrawerOpen={() => setSelectedOrder(null)}
            />
            <ErrorModal
                title={dialogError?.title}
                description={dialogError?.description}
                isOpen={isModalOpen}
                onClose={closePopup}
            />
        </Flex>
    )
}
