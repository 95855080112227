import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

type Props = {
    asset?: string
}

export default function MarketDetailsGraph({}: Props) {
    return (
        <Box
            py="1.5rem"
            borderBottomWidth={1}
            borderTopWidth={1}
            borderColor="grey.light.10"
            h={{ base: "250px", lg: "450px" }}
            mb="3rem"
            mt="1rem"
        >
            <Box w="full" h="full" bgColor="bluePurple.10" pos="relative">
                <Text
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%,-50%)"
                    textStyle="ManropeSemiboldBody"
                >
                    Graph will go here
                </Text>
            </Box>
        </Box>
    )
}
