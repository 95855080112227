import { ColorKeys, TextStyleKeys } from "./chakra"

export const hostedPagesStyles: Record<
    string,
    {
        textStyle?: TextStyleKeys
        color?: ColorKeys
        [key: string]: any
    }
> = {
    ".hosted-page h2": {
        textStyle: "ManropeSemiboldXLarge",
    },
    ".hosted-page h4": {
        textStyle: "ManropeSemiboldBody",
    },
    ".hosted-page h3": {
        textStyle: "ManropeSemiboldBody",
    },
    ".hosted-page p": {
        textStyle: "InterRegularBodySmall",
    },
    ".hosted-page": {
        display: "flex",
        flexDirection: "column",
        gap: "24px",
    },
    ".hosted-page ul": {
        padding: "0 0 0 20px",
    },
    ".hosted-page ul li": {
        padding: "0 0 10px 0",
    },
    ".hosted-page ol": {
        padding: "0 0 0 20px",
    },
    ".hosted-page ol li": {
        padding: "0 0 10px 0",
    },
    ".hosted-page table th": {
        padding: "10px",
        border: "1px solid #ccc",
    },
    ".hosted-page table td": {
        padding: "10px",
        border: "1px solid #ccc",
    },
    ".hosted-page a": {
        textDecoration: "underline",
        color: "blue.100",
    },
}
