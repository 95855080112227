import { Box, Flex, Link, SimpleGrid, Skeleton, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useMemo } from "react"
import { AssetStatsTable } from "./table"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { useTranslation } from "react-i18next"
import { shortenLargeNumber } from "@util/stringFormatting"
import { Transaction, TransactionAction } from "@redux/transactions/types"
import { useGetStakingInfoQuery } from "@redux/stake/apiSlice"
import { useGetTransactionsQuery } from "@redux/transactions/apiSlice"
import { useGetBalancesQuery } from "@redux/balances/apiSlice"
import useSupport from "@hooks/useSupport"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { ROUTES } from "@routing/routes"
import { buildRouteWithParams } from "@routing/route-utils"

interface StatItemProps {
    label: string
    value: string | number
}

const StatItem: React.FC<StatItemProps> = ({ label, value }) => (
    <VStack align="flex-start" spacing={1}>
        <Text textStyle="ManropeSemiboldBodySmall" color="grey.dark.60">
            {label}
        </Text>
        <Text textStyle="ManropeSemiboldBodySmall">{value}</Text>
    </VStack>
)

interface AssetStatsProps {
    asset: AssetDetailsFull
    setSelectedTx: (_: Transaction) => void
    setSelectedOrder: (_: Transaction) => void
    isDrawerOpen: boolean
}

const SKELETON_HEIGHT = "168px"

const AboutSection: React.FC<{ asset: AssetDetailsFull; aboutAsset: string }> = ({ asset, aboutAsset }) => {
    const { t } = useTranslation(["app", "common"])
    const accountDetails = useAppSelector(selectAccountDetails)

    const learnMoreLink = useMemo(() => {
        const route = buildRouteWithParams(ROUTES.ASSET_STATEMENT, { asset: asset.symbol.toLowerCase() })
        return route
    }, [asset])

    return (
        <Box>
            <Text mb={4} textStyle="ManropeBoldBody">
                {t("marketDetails.about", { asset: asset.name })}
            </Text>
            <Text textStyle="InterRegularBodySmall">{aboutAsset}</Text>
            {accountDetails?.country === "CA" && (
                <Link
                    textStyle="ManropeSemiboldBodySmall"
                    color="blue.100"
                    href={learnMoreLink}
                    target="_blank"
                    mt={2}
                    display="inline-block"
                >
                    {t("marketDetails.learn", { asset: asset.name })}
                </Link>
            )}
        </Box>
    )
}

const AssetStats: React.FC<AssetStatsProps> = ({ asset, setSelectedTx, setSelectedOrder, isDrawerOpen }) => {
    const { t } = useTranslation(["app", "assets"])

    const { data: stakingInfo } = useGetStakingInfoQuery(asset.symbol.toLowerCase(), {
        skip: !asset.restrictions.can_stake,
    })
    const { data: activityData, isLoading: isActivityLoading } = useGetTransactionsQuery({
        assets: asset.symbol,
        types: "",
        page: 1,
        statuses: "",
        limit: 4,
        sort: "desc",
    })
    const { data: balancesData } = useGetBalancesQuery(undefined, {
        refetchOnMountOrArgChange: true,
    })

    const { data: openOrdersData } = useGetTransactionsQuery({
        assets: asset.symbol,
        types: "",
        page: 1,
        statuses: "open,cancelling",
        limit: 4,
        sort: "desc",
    })

    // market_cap_cad is a misnomer and functions as usd as well when applicable
    const MarketCap = useMemo<number>(() => asset.statistics?.market_cap_cad, [asset])

    const Staked = useMemo<number>(() => {
        return balancesData?.stakes?.[asset.symbol.toUpperCase()]
            ? Number(balancesData.stakes[asset.symbol.toUpperCase()])
            : 0
    }, [balancesData, asset])

    const OpenOrdersTotalQuantitySells = useMemo<number>(() => {
        return openOrdersData
            ? openOrdersData?.transactions?.reduce((acc: number, order: Transaction) => {
                  return order.action === TransactionAction.LimitSell ? acc + Number(order.decrease_amount) : acc
              }, 0)
            : 0
    }, [openOrdersData])

    const OpenOrderTotalAmountSells = useMemo<number>(() => {
        return OpenOrdersTotalQuantitySells * Number(asset.price)
    }, [openOrdersData])

    const availableQuantity = useMemo<number>(() => {
        const startingBal = balancesData?.balances?.[asset.symbol.toUpperCase()]
            ? Number(balancesData.balances[asset.symbol.toUpperCase()])
            : 0
        return startingBal
    }, [balancesData, asset])

    const availableAmount = useMemo(() => Number(asset.price) * availableQuantity, [availableQuantity, asset.price])

    const FrozenQuantity = useMemo(() => {
        const frozenBal = balancesData?.frozen?.[asset.symbol.toUpperCase()]
            ? Number(balancesData.frozen[asset.symbol.toUpperCase()])
            : 0
        return frozenBal
    }, [balancesData, asset])

    const FrozenAmount = useMemo(() => Number(asset.price) * FrozenQuantity, [FrozenQuantity, asset.price])

    const isShowingTable = useMemo(
        () => activityData?.total || openOrdersData?.transactions.length,
        [activityData, openOrdersData]
    )

    const aboutAsset = useMemo(() => t(`${asset.symbol}.about`, { ns: "assets" }), [asset.symbol])

    return (
        <Box maxWidth="100%">
            <Flex
                direction={{ base: "column", lg: isDrawerOpen ? "column" : "row" }}
                justifyContent="space-between"
                gap="1rem"
            >
                <Flex direction="column" flex={1} gap="1rem">
                    <Box>
                        <Text mb={4} textStyle="ManropeBoldBody">
                            {asset?.name} {t("marketDetails.marketStatistics")}
                        </Text>
                        <SimpleGrid columns={[2, 2, 2, 3]} spacing={4}>
                            <StatItem
                                label={t("marketDetails.24HVolume")}
                                value={`$${shortenLargeNumber(asset.statistics.volume)}`}
                            />
                            <StatItem
                                label={t("marketDetails.24HLow")}
                                value={`$${shortenLargeNumber(asset.statistics["24h_low"])}`}
                            />
                            <StatItem
                                label={t("marketDetails.24HHigh")}
                                value={`$${shortenLargeNumber(asset.statistics["24h_high"])}`}
                            />
                            <StatItem
                                label={t("marketDetails.marketCap")}
                                value={`$${shortenLargeNumber(MarketCap)}`}
                            />
                            <StatItem
                                label={t("marketDetails.maxSupply")}
                                value={`${shortenLargeNumber(asset.statistics.max_supply)} ${asset.symbol.toUpperCase()}`}
                            />
                            <StatItem
                                label={t("marketDetails.circulatingSupply")}
                                value={`$${shortenLargeNumber(asset.statistics.circulating_supply)}`}
                            />
                        </SimpleGrid>
                    </Box>
                    <Skeleton
                        display={{ base: "none", lg: "block" }}
                        h={!!aboutAsset ? "fit-content" : SKELETON_HEIGHT}
                        isLoaded={!!aboutAsset}
                    >
                        {isShowingTable ? <AboutSection asset={asset} aboutAsset={aboutAsset} /> : null}
                    </Skeleton>

                    <Box display={{ base: "flex", lg: "none" }} flexDir="column" rowGap="1.5rem">
                        <Skeleton h={!!aboutAsset ? "fit-content" : SKELETON_HEIGHT} isLoaded={!!aboutAsset}>
                            <AboutSection asset={asset} aboutAsset={aboutAsset} />
                        </Skeleton>

                        {isShowingTable ? (
                            <AssetStatsTable
                                asset={asset}
                                isDrawerOpen={isDrawerOpen}
                                availableAmount={availableAmount}
                                availableQuantity={availableQuantity}
                                openOrderTotalAmountSells={OpenOrderTotalAmountSells}
                                openOrdersTotalQuantitySells={OpenOrdersTotalQuantitySells}
                                frozenAmount={FrozenAmount}
                                frozenQuantity={FrozenQuantity}
                                staked={Staked}
                                stakingInfo={stakingInfo}
                                openOrders={openOrdersData?.transactions ?? []}
                                activity={activityData?.transactions ?? []}
                                setSelectedOrder={setSelectedOrder}
                                setSelectedTx={setSelectedTx}
                            />
                        ) : null}
                    </Box>
                </Flex>
                <Box flex={1} display={{ base: "none", lg: "block" }}>
                    {isShowingTable ? (
                        <Skeleton h={!isActivityLoading ? "full" : "fit-content"} isLoaded={!isActivityLoading}>
                            <AssetStatsTable
                                asset={asset}
                                isDrawerOpen={isDrawerOpen}
                                availableAmount={availableAmount}
                                availableQuantity={availableQuantity}
                                openOrderTotalAmountSells={OpenOrderTotalAmountSells}
                                openOrdersTotalQuantitySells={OpenOrdersTotalQuantitySells}
                                frozenAmount={FrozenAmount}
                                frozenQuantity={FrozenQuantity}
                                staked={Staked}
                                stakingInfo={stakingInfo}
                                openOrders={openOrdersData?.transactions ?? []}
                                activity={activityData?.transactions ?? []}
                                setSelectedOrder={setSelectedOrder}
                                setSelectedTx={setSelectedTx}
                            />
                        </Skeleton>
                    ) : (
                        <Skeleton
                            h={!aboutAsset || !isActivityLoading ? "full" : "fit-content"}
                            isLoaded={!!aboutAsset && !isActivityLoading}
                            fadeDuration={2}
                        >
                            <AboutSection asset={asset} aboutAsset={aboutAsset} />
                        </Skeleton>
                    )}
                </Box>
            </Flex>
        </Box>
    )
}

export default AssetStats
