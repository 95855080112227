import { splitApi } from "@redux/splitApi"
import { ACCOUNT_TAG_TYPES, API_PATH } from "./const"
import {
    ChangePasswordParams,
    AuthorizedDevicesRes,
    IAccountDetails,
    ICreateWalletParams,
    ICreateWalletResponse,
    INotificationChannelsResponse,
    INotificationsResponse,
    IPriceAlertParams,
    IPriceAlertsResponse,
    IRestrictionsResponse,
    ISaveTFAKeyParams,
    ISubscribeNotificationParams,
    ITfaKeyResponse,
    IWallet,
    IWalletsResponse,
    SometimesErrRes,
    GetActivitiesRes,
} from "./types"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { WITHDRAW_TAG_TYPES } from "@redux/withdraw/const"
import { AuthorizedDevice } from "@components/settings/authorizedDevice/authorizedDeviceTable"
import { TActivity } from "@screens/dashboard/settings/accountActivity"
import { withdrawApi } from "@redux/withdraw/apiSlice"

export const accountApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getAccountDetails: builder.query<IAccountDetails, void>({
            query: () => API_PATH.accountDetails,
            transformResponse: (response: { data: IAccountDetails }) => response.data,
            providesTags: () => [{ type: ACCOUNT_TAG_TYPES.accountDetails }],
        }),
        updateAccount: builder.mutation<IAccountDetails, Partial<IAccountDetails>>({
            query: (body) => ({
                url: API_PATH.account,
                method: "POST",
                body,
            }),
            invalidatesTags: [ACCOUNT_TAG_TYPES.accountDetails],
            transformResponse: (response: { data: IAccountDetails }) => response.data,
        }),
        getAccountBalanceRestrictions: builder.query<IRestrictionsResponse, void>({
            query: () => API_PATH.accountRestrictions,
        }),
        getTFAKey: builder.query<ITfaKeyResponse, void>({
            query: () => API_PATH.generateTFAKey,
        }),
        saveTFAKey: builder.mutation<void, ISaveTFAKeyParams>({
            query: (params) => ({
                url: API_PATH.saveTFAKey,
                method: "POST",
                params,
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.accountDetails }],
        }),
        disableTFA: builder.mutation<void, { code: string; password: string }>({
            query: (body) => ({
                url: API_PATH.disableTFA,
                method: "DELETE",
                body,
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.accountDetails }],
        }),
        getWallets: builder.query<IWalletsResponse, void>({
            query: () => API_PATH.wallets,
            providesTags: () => [{ type: ACCOUNT_TAG_TYPES.wallets }],
        }),
        createWallet: builder.mutation<ICreateWalletResponse, ICreateWalletParams>({
            query: (params) => ({
                url: API_PATH.wallets,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.wallets }],
        }),
        changePassword: builder.mutation<void, ChangePasswordParams>({
            query: (body) => ({
                url: API_PATH.changePassword,
                method: "POST",
                body,
            }),
        }),
        updateWallet: builder.mutation<ICreateWalletResponse, IWallet>({
            query: (params) => ({
                url: `${API_PATH.wallets}/${params.id}`,
                method: "PUT",
                body: params,
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.wallets }],
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    accountApi.util.updateQueryData("getWallets", undefined, (draft) => {
                        const wallet = draft.data.find((wallet) => wallet.id === params.id)
                        if (wallet) {
                            wallet.address = params.address
                            wallet.label = params.label
                        }
                    })
                )
                await queryFulfilled.catch(() => patchResult.undo())
            },
        }),
        deleteWallet: builder.mutation<ICreateWalletResponse, number>({
            query: (id) => ({
                url: `${API_PATH.wallets}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.wallets }],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    accountApi.util.updateQueryData("getWallets", undefined, (draft) => {
                        draft.data = draft.data.filter((wallet) => wallet.id !== id)
                    })
                )
                await queryFulfilled.catch(() => patchResult.undo())
            },
        }),
        verifyPassword: builder.mutation<[], { password: string }>({
            query: (body) => ({
                url: API_PATH.verifyPass,
                method: "POST",
                body,
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => {
                const errorData = response as SometimesErrRes
                throw new Error(errorData.data.errors?.password?.[0] || "Password verification failed")
            },
        }),
        emailTransactions: builder.mutation<void, void>({
            query: () => ({
                url: `${API_PATH.transactionsEmail}`,
                method: "POST",
            }),
        }),
        sendCloseAccountEmailCode: builder.mutation<void, void>({
            query: () => ({
                url: API_PATH.sendEmailCode,
                method: "POST",
            }),
        }),
        sendPasswordChangeOtp: builder.mutation<void, void>({
            query: () => ({
                url: API_PATH.sendOtpPassword,
                method: "GET",
            }),
        }),
        verifyEmailCode: builder.mutation<[], { code: string }>({
            query: (body) => ({
                url: API_PATH.verifyEmailCode,
                method: "POST",
                body,
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => {
                const errorData = response as SometimesErrRes
                throw new Error(errorData.data.errors?.code?.[0] || "Code verification failed")
            },
        }),
        verify2FACode: builder.mutation<{ tfa: boolean }, { code: string }>({
            query: (body) => ({
                url: API_PATH.verify2FA,
                method: "POST",
                body,
            }),
        }),
        closeAccount: builder.mutation<void, void>({
            query: () => ({
                url: API_PATH.account,
                method: "DELETE",
                responseHandler: "text",
            }),
            transformErrorResponse: () => undefined,
        }),
        sendCloseSurvey: builder.mutation<void, { survey: string }>({
            query: (body) => ({
                url: API_PATH.closeSurvey,
                method: "POST",
                body,
            }),
        }),
        getKoinlySso: builder.mutation<{ data: string }, void>({
            query: (body) => ({
                url: `${API_PATH.koinlySso}?json=1`,
                method: "GET",
                body,
            }),
        }),
        getNotificationChannels: builder.query<INotificationChannelsResponse, void>({
            query: () => API_PATH.notificationChannels,
            providesTags: () => [{ type: ACCOUNT_TAG_TYPES.notificationChannels }],
        }),
        getNotificationDetails: builder.query<INotificationsResponse, void>({
            query: () => API_PATH.notificationDetails,
            providesTags: () => [{ type: ACCOUNT_TAG_TYPES.notificationsDetails }],
        }),
        subscribeNotification: builder.mutation<void | null, ISubscribeNotificationParams>({
            query: (params) => ({
                url: API_PATH.subscribeNotification,
                method: "POST",
                body: params,
                responseHandler: "text",
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.notificationsDetails }],
        }),
        getPriceAlerts: builder.query<IPriceAlertsResponse, void>({
            query: () => API_PATH.priceAlerts,
            providesTags: () => [{ type: ACCOUNT_TAG_TYPES.priceAlerts }],
        }),
        deletePriceAlert: builder.mutation<void, { id: number }>({
            query: (params) => ({
                url: `${API_PATH.priceAlerts}/${params.id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.priceAlerts }],
        }),
        createPriceAlert: builder.mutation<void, IPriceAlertParams>({
            query: (params) => ({
                url: API_PATH.priceAlerts,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.priceAlerts }],
        }),
        editPriceAlert: builder.mutation<void, IPriceAlertParams & { alertId: string }>({
            query: (params) => ({
                url: `${API_PATH.priceAlerts}/${params.alertId}`,
                method: "PUT",
                body: params,
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.priceAlerts }],
        }),
        deleteUSBank: builder.mutation<void, { account_id: string }>({
            query: (body) => ({
                url: `${API_PATH.deleteBank}`,
                body,
                method: "DELETE",
                responseHandler: "text",
            }),
            transformResponse: () => undefined,
            invalidatesTags: [{ type: WITHDRAW_TAG_TYPES.BANKS_US }],
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    withdrawApi.util.updateQueryData("getUSBanks", undefined, (draft) => {
                        draft.accounts = draft.accounts.filter((account) => account.id !== body.account_id)
                    })
                )
                await queryFulfilled.catch(() => patchResult.undo())
            },
        }),
        getAuthorizedDevices: builder.query<AuthorizedDevicesRes, void>({
            query: () => API_PATH.authorizedDevices,
            providesTags: () => [{ type: ACCOUNT_TAG_TYPES.authDevices }],
            transformResponse: (response: AuthorizedDevicesRes) => {
                response.devices.reverse()
                return response
            },
        }),
        updateAuthorizedDevices: builder.mutation<void, { id: string; label: string }>({
            query: (params) => ({
                url: `${API_PATH.authorizedDevice}/${params.id}?label=${params.label}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.authDevices }],
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    accountApi.util.updateQueryData("getAuthorizedDevices", undefined, (draft) => {
                        const device = draft.devices.find((device) => device.deviceID === params.id)
                        if (device) {
                            device.label = params.label
                        }
                    })
                )
                await queryFulfilled.catch(() => patchResult.undo())
            },
        }),
        deleteAuthorizedDevice: builder.mutation<void, string>({
            query: (id) => ({
                url: `${API_PATH.authorizedDevice}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.authDevices }],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    accountApi.util.updateQueryData("getAuthorizedDevices", undefined, (draft) => {
                        draft.devices = draft.devices.filter((device) => device.deviceID !== id)
                    })
                )
                await queryFulfilled.catch(() => patchResult.undo())
            },
        }),
        getActivity: builder.query<GetActivitiesRes, number>({
            query: (page) => `${API_PATH.activity}?page=${page}&limit=10`,
        }),
    }),
})

export const {
    useGetAccountDetailsQuery,
    useGetAccountBalanceRestrictionsQuery,
    useGetTFAKeyQuery,
    useSaveTFAKeyMutation,
    useGetWalletsQuery,
    useCreateWalletMutation,
    useChangePasswordMutation,
    useDisableTFAMutation,
    useVerifyPasswordMutation,
    useSendCloseAccountEmailCodeMutation,
    useSendPasswordChangeOtpMutation,
    useVerifyEmailCodeMutation,
    useCloseAccountMutation,
    useSendCloseSurveyMutation,
    useEmailTransactionsMutation,
    useVerify2FACodeMutation,
    useUpdateAccountMutation,
    useGetKoinlySsoMutation,
    useGetNotificationChannelsQuery,
    useGetNotificationDetailsQuery,
    useSubscribeNotificationMutation,
    useGetPriceAlertsQuery,
    useDeletePriceAlertMutation,
    useCreatePriceAlertMutation,
    useEditPriceAlertMutation,
    useUpdateWalletMutation,
    useDeleteWalletMutation,
    useDeleteUSBankMutation,
    useGetAuthorizedDevicesQuery,
    useUpdateAuthorizedDevicesMutation,
    useDeleteAuthorizedDeviceMutation,
    useGetActivityQuery,
} = accountApi
export const { getAccountDetails } = accountApi.endpoints
