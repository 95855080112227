import { Box, Text } from "@chakra-ui/layout"
import ScreenContainer from "@components/ui/containers/screen"
import { normalizeSymlink } from "@util/stringFormatting"
import { useTranslation } from "react-i18next"

export default function MassAssetStatement() {
    const { t } = useTranslation("common")

    const htmlFiles = require.context("../../../assets/assetStatements", false, /\.html$/)
    const htmlContents = Object.values(
        htmlFiles.keys().reduce(
            (acc, file) => {
                const normalizedFile = normalizeSymlink(file)
                if (!acc[normalizedFile]) {
                    acc[normalizedFile] = (htmlFiles(file) as { default: string }).default
                }
                return acc
            },
            {} as Record<string, string>
        )
    )

    return (
        <ScreenContainer>
            <Box pb={8} borderBottomWidth={1} borderBottomColor="grey.light.10">
                <Text textStyle="ManropeMedium4xLarge">{t("cryptoAssetStatement")}</Text>
            </Box>
            {htmlContents.map((htmlContent) => (
                <Box
                    key={htmlContent}
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                    py={8}
                    className="hosted-page"
                />
            ))}
        </ScreenContainer>
    )
}
