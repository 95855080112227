import { Box, Flex, Text } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import DocumentsPage from "@components/settings/documents"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import ScreenContainer from "@components/ui/containers/screen"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { useTranslation } from "react-i18next"

export default function NPayStatementsPage() {
    const { t } = useTranslation("app")
    const { navigate } = useRestrictedNavigation()

    return (
        <ScreenContainer>
            <Box>
                <Flex flexDir="column" gap={2}>
                    <BreadCrumbGroup
                        breadCrumbs={[
                            {
                                text: t("netcoinsPay.netcoinsPay"),
                                hasAfter: true,
                                onClick: () => navigate(ROUTES.DASHBOARD_NETCOINS_PAY),
                            },
                            {
                                text: t("netcoinsPay.netcoinsPayTransactions"),
                                onClick: () => navigate(ROUTES.NPAY_TRANSACTIONS),
                            },
                            {
                                text: t("netcoinsPay.myDocuments"),
                                hasAfter: false,
                            },
                        ]}
                        py={2}
                    />

                    <Text textStyle="ManropeMedium4xLarge">{t("netcoinsPay.myDocuments")}</Text>
                </Flex>

                <Box my={8} borderBottomWidth={1} />

                <DocumentsPage selectedIndex={1} />
            </Box>
            <DashboardFooter />
        </ScreenContainer>
    )
}
