import { useTranslation } from "react-i18next"
import { useMemo } from "react"

export const filterOptions = () => {
    const { t } = useTranslation("app")

    return useMemo(
        () => [
            {
                label: t("netcoinsPay.allCards"),
                value: "all",
            },
            {
                label: t("netcoinsPay.virtualCardOnly"),
                value: "virtual",
            },
            {
                label: t("netcoinsPay.physicalCardOnly"),
                value: "physical",
            },
        ],
        [t]
    )
}

export const initialState = {
    start_date: null,
    end_date: null,
}
