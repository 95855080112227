import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { MdChevronRight, MdPayment } from "react-icons/md"
import { SETTINGS_DRAWERS } from "@screens/dashboard/settings"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"

export type HeaderSidebarProps = {
    close: () => void
}

export default function SidebarSettings({ close }: HeaderSidebarProps) {
    const { t } = useTranslation(["app", "common"])
    const { navigate } = useRestrictedNavigation()
    const account = useAppSelector(selectAccountDetails)

    function handleNav(route: string) {
        navigate(route)
        close()
    }

    return (
        <Box>
            <Box mx="0.75rem">
                <Flex
                    onClick={() => handleNav(ROUTES.DASHBOARD_SETTINGS)}
                    p="0.75rem 1rem 0.75rem 0.75rem"
                    justifyContent={"space-between"}
                    align="center"
                    cursor="pointer"
                    borderRadius={"4px"}
                    _hover={{
                        bgColor: "blue.10",
                    }}
                >
                    <Text color="bluePurple.100" textStyle="ManropeSemiboldBodySmall">
                        {t("nav.viewAll")}
                    </Text>
                    <Icon as={MdChevronRight} color="bluePurple.100" h="24px" w="24px" />
                </Flex>
            </Box>
            <Box
                borderBottomWidth={account?.country === "CA" ? 1 : 0}
                borderTopWidth={1}
                borderColor="grey.light.10"
                pb="0.5rem"
                my="0.5rem"
            >
                <Box mt="0.5rem" px="1rem" py="0.5rem">
                    <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.80">
                        {t("nav.savedGroup")}
                    </Text>
                </Box>
                <Box mx="0.75rem">
                    <Box
                        _hover={{
                            bgColor: "blue.10",
                        }}
                        p="0.75rem 1rem"
                        onClick={() => handleNav(ROUTES.SAVED_ADDRESSES_CRYPTO)}
                        cursor="pointer"
                        borderRadius="4px"
                    >
                        <Text color="bluePurple.100" textStyle="ManropeSemiboldBodySmall">
                            {t("crypto", { ns: "common" })}
                        </Text>
                    </Box>
                </Box>
                <Box mx="0.75rem">
                    <Box
                        _hover={{
                            bgColor: "blue.10",
                        }}
                        p="0.75rem 1rem"
                        onClick={() => handleNav(ROUTES.SAVED_ADDRESSES_FIAT)}
                        cursor="pointer"
                        borderRadius="4px"
                    >
                        <Text color="bluePurple.100" textStyle="ManropeSemiboldBodySmall">
                            {t("fiat", { ns: "common" })}
                        </Text>
                    </Box>
                </Box>
            </Box>
            {account?.country === "CA" && (
                <Box mx="0.75rem">
                    <Flex
                        align="center"
                        px="1rem"
                        py="0.75rem"
                        cursor="pointer"
                        onClick={() =>
                            navigate(ROUTES.DASHBOARD_SETTINGS, {
                                state: { openDrawer: SETTINGS_DRAWERS.BUY_WITH_CREDIT_CARD },
                            })
                        }
                        _hover={{
                            bgColor: "blue.10",
                        }}
                        borderRadius="4px"
                    >
                        <Icon as={MdPayment} color="bluePurple.100" mr="1.5rem" h="24px" w="24px" />
                        <Text color="bluePurple.100" textStyle="ManropeSemiboldBodySmall">
                            {t("nav.buyWithCredit")}
                        </Text>
                    </Flex>
                </Box>
            )}
        </Box>
    )
}
