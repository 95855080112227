import { PayloadAction, createSlice } from "@reduxjs/toolkit"

/*
    Tracks the Toasts that are on screen an ensures that only MAX_TOASTS are shown at any time
*/

export const MAX_TOASTS = 3

const initialState: string[] = [] as string[]

export const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        add: (state, action: PayloadAction<string>) => {
            const updatedToasts = [...state, action.payload]
            const len = updatedToasts.length
            if (len >= MAX_TOASTS) {
                state.splice(0, state.length, ...updatedToasts.slice(len - MAX_TOASTS, len))
            } else {
                state.push(action.payload)
            }
        },
        remove: (state, action: PayloadAction<string>) => {
            state = state.filter((id: string) => id !== action.payload)
        },
    },
})

export const { add, remove } = toastSlice.actions
