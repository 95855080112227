import { Text, Flex, Table, Tbody, Tr, Th, Thead, useBreakpointValue, Spinner, TableContainer } from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import SingleSelect from "@components/ui/select/singleSelect"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDeleteWalletMutation, useGetWalletsQuery, useUpdateWalletMutation } from "@redux/account/apiSlice"
import { IoAdd } from "react-icons/io5"
import { Option } from "@components/ui/types"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { IoMdInformationCircleOutline } from "react-icons/io"
import SavedWalletAddressRow from "../savedWalletAddressRow"
import { IWallet } from "@redux/account/types"
import useGenericToast from "@hooks/useGenericToast"
import DeleteWalletModal from "./deleteWalletModal"
import EditCryptoWalletModal from "./editWalletModal"
import { EnterWalletDetailsFormData } from "./enterWalletDetails"
import AddNewWalletModal from "./addWalletModal"
import { useLazyGetDepositAddressQuery } from "@redux/fund/apiSlice"
import { RadioSelectOption } from "@components/ui/radio/radioSelect"
import btcSvg from "@assets/svgs/coins/btc.svg"
import { imageMap } from "@assets/svgs/coins"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import TooltipComp from "@components/tooltip"
import { useLazyValidateAddressQuery } from "@redux/withdraw/apiSlice"

export const DEFAULT_BTC_COIN: RadioSelectOption = {
    symbol: "BTC",
    name: "Bitcoin",
    imageUrl: btcSvg,
}

export default function SavedWalletAddressTable() {
    const { t } = useTranslation(["settings", "common"])
    const { successToast, errorToast } = useGenericToast()

    const cancelRef = useRef<HTMLButtonElement>(null)

    const { data, isLoading: getWalletsLoading } = useGetWalletsQuery()
    const [updateWallet, { isLoading: updateWalletLoading }] = useUpdateWalletMutation()
    const [deleteWallet, { isLoading: deleteWalletLoading }] = useDeleteWalletMutation()
    const [getDepositAddress, { data: depositData }] = useLazyGetDepositAddressQuery()
    const { data: assetDetailsData, isLoading: isLoadingAssets } = useGetAssetsDetailsQuery(undefined)

    const [filter, setFilter] = useState<Option<string> | null>(null)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [focusedWallet, setFocusedWallet] = useState<IWallet | null>(null)

    const [coin, setCoin] = useState<RadioSelectOption | null>(DEFAULT_BTC_COIN)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)

    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [editError, setEditError] = useState("")

    useEffect(() => {
        if (coin?.symbol) {
            getDepositAddress({ currency: coin.symbol })
        }
    }, [coin])

    const filteredData = useMemo(() => {
        if (!filter) return data?.data ?? []
        return data?.data.filter((row) => row.coin === filter.value) ?? []
    }, [data, filter])

    const isMobile = useBreakpointValue({ base: true, md: false })

    const coinOptions = useMemo(() => {
        if (!data?.data || !assetDetailsData) return []

        const coinHashMap: Record<string, Option<string>> = {}
        data?.data.forEach((row) => {
            if (!coinHashMap[row.coin]) {
                coinHashMap[row.coin] = {
                    value: row.coin,
                    label: row.coin,
                    desc: assetDetailsData?.[row.coin].name ?? "",
                }
            }
        })

        return Object.values(coinHashMap)
    }, [data, assetDetailsData])

    async function handleEditWallet(values: EnterWalletDetailsFormData) {
        setEditError("")
        if (!focusedWallet) return

        const updatedWallet: IWallet = {
            ...focusedWallet,
            address: values.address,
            label: values.label,
            ...(values.memo ? { memo: values.memo } : {}),
        }
        await updateWallet(updatedWallet)
            .unwrap()
            .then(() => {
                setIsEditModalOpen(false)
                successToast(t("savedWalletAddress.updatedWallet"))
            })
            .catch(() => setEditError(t("savedWalletAddress.updateError")))
    }

    async function handleDeleteWallet() {
        setIsDeleteModalOpen(false)
        if (!focusedWallet) return

        await deleteWallet(focusedWallet.id)
            .unwrap()
            .then(() => successToast(t("savedWalletAddress.deletedWallet")))
            .catch(() => errorToast(t("savedWalletAddress.deleteError")))
    }

    const openDeleteModal = useCallback((wallet: IWallet) => {
        setFocusedWallet(wallet)
        setIsDeleteModalOpen(true)
    }, [])

    const openEditModal = useCallback(
        (wallet: IWallet, coinName: string) => {
            setFocusedWallet(wallet)
            setCoin({
                symbol: wallet.coin,
                name: coinName,
                imageUrl: imageMap[wallet.coin.toLowerCase() as keyof typeof imageMap],
            })
            setIsEditModalOpen(true)
        },
        [imageMap]
    )

    const isLoading = useMemo(() => isLoadingAssets || getWalletsLoading, [isLoadingAssets, getWalletsLoading])

    return (
        <Flex flexDir="column" gap={3} flex={1}>
            <Flex alignItems="center" justifyContent="space-between" w="full">
                <Flex alignItems="center" gap={6} py={3}>
                    <Text
                        textStyle="ManropeMediumBodySmall"
                        color="bluePurple.100"
                        display={{ base: "none", md: "flex" }}
                    >
                        {t("savedWalletAddress.filterBy")}
                    </Text>
                    <SingleSelect
                        placeholder={t("coin", { ns: "common" })}
                        options={coinOptions}
                        onSelect={(option) => setFilter(option)}
                        cancellable
                        categoryName="Coin"
                        isDisabled={coinOptions.length === 0}
                    />
                </Flex>

                <StandardButton
                    children={t("savedWalletAddress.addNewWallet")}
                    variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                    leftIcon={IoAdd}
                    size="sm"
                    alignSelf="center"
                    onClick={() => setIsAddModalOpen(true)}
                />
            </Flex>

            <TableContainer flex={1} minHeight="300px">
                <Table>
                    <Thead bg="grey.light.5">
                        <Tr px={2} py={"6px"}>
                            {!isMobile && (
                                <Th pl={2}>
                                    <Text color="grey.light.90" textStyle="ManropeSemiboldXSmall" textTransform="none">
                                        {t("savedWalletAddress.cryptocurrency")}
                                    </Text>
                                </Th>
                            )}

                            <Th pl={{ base: 2, md: 6 }}>
                                <Text color="grey.light.90" textStyle="ManropeSemiboldXSmall" textTransform="none">
                                    {t("savedWalletAddress.walletAddress")}
                                </Text>
                            </Th>
                            {!isMobile && (
                                <Th>
                                    <Flex gap={2} alignItems="center">
                                        <Text
                                            color="grey.light.90"
                                            textStyle="ManropeSemiboldXSmall"
                                            textTransform="none"
                                        >
                                            {t("savedWalletAddress.destinationTag")}
                                        </Text>

                                        <TooltipComp
                                            usePortal
                                            bodyText={t("savedWalletAddress.destinationTagTooltip")}
                                            triggerComponent={
                                                <ReactIcon
                                                    icon={IoMdInformationCircleOutline}
                                                    size={16}
                                                    color="blue.100"
                                                />
                                            }
                                        />
                                    </Flex>
                                </Th>
                            )}

                            {!isMobile && <Th />}
                        </Tr>
                    </Thead>
                    {!isLoading && (
                        <Tbody>
                            {filteredData.map((row, index) => (
                                <SavedWalletAddressRow
                                    key={index}
                                    wallet={row}
                                    onDeleteClick={openDeleteModal}
                                    onEditClick={openEditModal}
                                    coinName={assetDetailsData?.[row.coin].name ?? ""}
                                />
                            ))}
                        </Tbody>
                    )}
                </Table>
                {(isLoading || data?.data.length === 0) && (
                    <Flex h="full" justify="center" align="center" minHeight="300px">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <Text color="grey.light.50" textStyle="ManropeSemiboldBodySmall">
                                {t("savedWalletAddress.noWallets")}
                            </Text>
                        )}
                    </Flex>
                )}
            </TableContainer>
            <AddNewWalletModal
                isOpen={isAddModalOpen}
                toggleSideDrawerOpen={() => {
                    setIsAddModalOpen(false)
                    setCoin(DEFAULT_BTC_COIN)
                }}
                setCoin={setCoin}
                coin={coin}
                depositData={depositData}
            />
            {focusedWallet && isEditModalOpen && (
                <EditCryptoWalletModal
                    isOpen={isEditModalOpen}
                    toggleOpen={() => {
                        setIsEditModalOpen(false)
                        setCoin(DEFAULT_BTC_COIN)
                    }}
                    wallet={focusedWallet}
                    onSubmit={handleEditWallet}
                    isLoading={updateWalletLoading}
                    err={editError}
                    setErr={setEditError}
                    depositData={depositData}
                />
            )}
            <DeleteWalletModal
                isOpen={isDeleteModalOpen}
                toggleOpen={() => setIsDeleteModalOpen(false)}
                isLoading={deleteWalletLoading}
                onClick={handleDeleteWallet}
                cancelRef={cancelRef}
            />
        </Flex>
    )
}
