import { Td, Tr, Text, Flex, Icon } from "@chakra-ui/react"
import { MdOpenInNew } from "react-icons/md"
import { useTranslation } from "react-i18next"
import { Statement } from "@redux/statements/types"
import { memo } from "react"

type Props = {
    statement: Statement
    year: string
    onClick: (_: string) => void
    isDownloading: boolean
    isAnyDownloading: boolean
}

function DocumentRow({ statement, year, onClick, isDownloading, isAnyDownloading }: Props) {
    const { t } = useTranslation("settings")
    const { label, download_path } = statement

    return (
        <Tr w="full" py={3}>
            <Td p={2}>
                <Text whiteSpace="normal" wordBreak="normal" textStyle="ManropeSemiboldBodySmall">
                    {year} {label} {t("documents.statement")}
                </Text>
            </Td>
            <Td
                width={{
                    base: "150px",
                    md: "200px",
                }}
            >
                <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    cursor={isAnyDownloading ? "auto" : "pointer"}
                    gap={{
                        base: 1,
                        sm: 3,
                    }}
                    sx={{
                        "&:hover": {
                            textDecorationColor: "blue.100 !important",
                            textDecoration: isAnyDownloading ? "none" : "underline",
                        },
                        "&:active": {
                            textDecorationColor: "blue.70 !important",
                            textDecoration: isAnyDownloading ? "none" : "underline",
                        },
                    }}
                    onClick={() => (!isAnyDownloading ? onClick(download_path) : {})}
                >
                    <Text color={"blue.100"} textStyle="ManropeSemiboldBodySmall">
                        {isDownloading ? `${t("downloading", { ns: "common" })}...` : t("documents.viewPDF")}
                    </Text>
                    {!isDownloading && <Icon as={MdOpenInNew} size={24} color="blue.100" />}
                </Flex>
            </Td>
        </Tr>
    )
}

export default memo(DocumentRow)
