// Centralized route segments for easier lookup and debugging
export const ROUTE_SEGMENTS = {
    ROOT: "/",
    DASHBOARD: "dashboard",
    LANDING: "landing",
    TWO_FA: "twofa",
    TRADE: "trade",
    TRANSFER: "transfer",
    NETCOINS_PAY: "netcoins-pay",
    ONBOARDING: "onboarding",
    TRANSACTIONS: "transactions",
    OPEN_ORDERS: "open-orders",
    PROFILE: "profile",
    DOCUMENTS: "documents",
    SAVED_ADDRESSES_FIAT: "saved-fiat",
    SAVED_ADDRESSES_CRYPTO: "saved-crypto",
    NOTIFICATIONS: "notifications",
    PRICE_ALERTS: "price-alerts",
    ACCOUNT_ACTIVITY: "activity",
    AUTHORIZED_DEVICES: "authorized-devices",
    SETTINGS: "settings",
    FAQ: "faq",
    HOSTED_PAGE: "hosted-page",
    ASSET_STATEMENT: "asset-statement",
    PRIVACY_POLICY: "privacy-policy",
    RISK_STATEMENT: "risk-statement",
    MC_CARDHOLDER_AGREEMENT: "mc-cardholder-agreement",
    MC_REWARDS_TERMS: "mc-rewards-terms",
    TERMS_OF_SERVICE: "terms-of-service",
    DISPUTE_RESOLUTION_SERVICES: "dispute-resolution-services",
    RELATIONSHIP_DISCLOSURE: "relationship-disclosure",
    NPAY_TRANSACTIONS: "npay-transactions",
    NPAY_STATEMENTS: "npay-statements",
}

export enum RouteParams {
    ASSET = "asset",
    TRANSACTION_ID = "transactionId",
}

// Compose routes using segments:
const DASHBOARD = `/${ROUTE_SEGMENTS.DASHBOARD}`
const LANDING = `/${ROUTE_SEGMENTS.LANDING}`
const TWO_FA = `/${ROUTE_SEGMENTS.TWO_FA}`
const ONBOARDING = `/${ROUTE_SEGMENTS.ONBOARDING}`
const DASHBOARD_TRADE = `${DASHBOARD}/${ROUTE_SEGMENTS.TRADE}`
const DASHBOARD_TRANSFER = `${DASHBOARD}/${ROUTE_SEGMENTS.TRANSFER}`
const DASHBOARD_NETCOINS_PAY = `${DASHBOARD}/${ROUTE_SEGMENTS.NETCOINS_PAY}`
const NPAY_TRANSACTIONS = `${DASHBOARD_NETCOINS_PAY}/${ROUTE_SEGMENTS.NPAY_TRANSACTIONS}`
const NPAY_STATEMENTS = `${NPAY_TRANSACTIONS}/${ROUTE_SEGMENTS.NPAY_STATEMENTS}`
const PROFILE = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.PROFILE}`
const DOCUMENTS = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.DOCUMENTS}`
const SAVED_ADDRESSES_FIAT = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.SAVED_ADDRESSES_FIAT}`
const SAVED_ADDRESSES_CRYPTO = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.SAVED_ADDRESSES_CRYPTO}`
const ACCOUNT_ACTIVITY = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.ACCOUNT_ACTIVITY}`
const AUTHORIZED_DEVICES = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.AUTHORIZED_DEVICES}`
const PRICE_ALERTS = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.PRICE_ALERTS}`
const DASHBOARD_SETTINGS = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}`
const DASHBOARD_FAQ = `${DASHBOARD}/${ROUTE_SEGMENTS.FAQ}`
const MARKET_DETAILS = `${DASHBOARD}/${ROUTE_SEGMENTS.TRADE}/:${RouteParams.ASSET}`
const ASSET_TRANSACTIONS = `${MARKET_DETAILS}/${ROUTE_SEGMENTS.TRANSACTIONS}`
const ASSET_OPEN_ORDERS = `${MARKET_DETAILS}/${ROUTE_SEGMENTS.OPEN_ORDERS}`
const HOSTED_PAGE = `/${ROUTE_SEGMENTS.HOSTED_PAGE}`
const ASSET_STATEMENT = `${HOSTED_PAGE}/${ROUTE_SEGMENTS.ASSET_STATEMENT}/:${RouteParams.ASSET}`
const MASS_ASSET_STATEMENT = `${HOSTED_PAGE}/${ROUTE_SEGMENTS.ASSET_STATEMENT}`
const PRIVACY_POLICY = `${HOSTED_PAGE}/${ROUTE_SEGMENTS.PRIVACY_POLICY}`
const RISK_STATEMENT = `${HOSTED_PAGE}/${ROUTE_SEGMENTS.RISK_STATEMENT}`
const MC_CARDHOLDER_AGREEMENT = `${HOSTED_PAGE}/${ROUTE_SEGMENTS.MC_CARDHOLDER_AGREEMENT}`
const MC_REWARDS_TERMS = `${HOSTED_PAGE}/${ROUTE_SEGMENTS.MC_REWARDS_TERMS}`
const TERMS_OF_SERVICE = `${HOSTED_PAGE}/${ROUTE_SEGMENTS.TERMS_OF_SERVICE}`
const RELATIONSHIP_DISCLOSURE = `${HOSTED_PAGE}/${ROUTE_SEGMENTS.RELATIONSHIP_DISCLOSURE}`
const DISPUTE_RESOLUTION_SERVICES = `/${ROUTE_SEGMENTS.DISPUTE_RESOLUTION_SERVICES}`

// Export routes for use in components
export const ROUTES = {
    DASHBOARD,
    LANDING,
    TWO_FA,
    ONBOARDING,
    DASHBOARD_TRADE,
    DASHBOARD_TRANSFER,
    DASHBOARD_NETCOINS_PAY,
    DASHBOARD_SETTINGS,
    MARKET_DETAILS,
    ASSET_TRANSACTIONS,
    ASSET_OPEN_ORDERS,
    PROFILE,
    PRICE_ALERTS,
    DASHBOARD_FAQ,
    DOCUMENTS,
    SAVED_ADDRESSES_CRYPTO,
    SAVED_ADDRESSES_FIAT,
    ACCOUNT_ACTIVITY,
    AUTHORIZED_DEVICES,
    ASSET_STATEMENT,
    MASS_ASSET_STATEMENT,
    PRIVACY_POLICY,
    RISK_STATEMENT,
    MC_CARDHOLDER_AGREEMENT,
    MC_REWARDS_TERMS,
    TERMS_OF_SERVICE,
    DISPUTE_RESOLUTION_SERVICES,
    RELATIONSHIP_DISCLOSURE,
    NPAY_TRANSACTIONS,
    NPAY_STATEMENTS,
}
