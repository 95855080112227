import React, { useCallback } from "react"
import { HStack, Text, IconButton, Flex } from "@chakra-ui/react"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

interface CalendarHeaderProps {
    displayDate: Date
    isYearMonthView: boolean
    setIsYearMonthView: (value: boolean) => void
    handlePrevMonth: () => void
    handleNextMonth: () => void
}

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const CalendarHeader = React.memo<CalendarHeaderProps>(
    ({ displayDate, isYearMonthView, setIsYearMonthView, handlePrevMonth, handleNextMonth }) => {
        const handleYearMonthClick = useCallback(() => {
            setIsYearMonthView(true)
        }, [setIsYearMonthView])

        return (
            <HStack justify="space-between">
                <Flex
                    onClick={handleYearMonthClick}
                    minH={"48px"}
                    backgroundColor={isYearMonthView ? "grey.light.5" : "transparent"}
                    cursor={isYearMonthView ? "default" : "pointer"}
                    alignItems={"center"}
                    px={4}
                    borderRadius={"md"}
                    borderWidth={isYearMonthView ? 1 : 0}
                    borderColor={"grey.light.10"}
                >
                    <Text textStyle={"ManropeMediumBody"} color="currentColor" mr={2}>
                        {months[displayDate.getMonth()]} {displayDate.getFullYear()}
                    </Text>
                    <MdChevronRight
                        color="black"
                        size={18}
                        style={{ transform: `rotate(${isYearMonthView ? 270 : 90}deg)` }}
                    />
                </Flex>
                {!isYearMonthView && (
                    <HStack>
                        <IconButton
                            icon={<MdChevronLeft size={16} />}
                            onClick={handlePrevMonth}
                            variant="ghost"
                            size="sm"
                            aria-label="Previous month"
                        />
                        <IconButton
                            icon={<MdChevronRight size={16} />}
                            onClick={handleNextMonth}
                            variant="ghost"
                            size="sm"
                            aria-label="Next month"
                        />
                    </HStack>
                )}
            </HStack>
        )
    },
    (prevProps, nextProps) => {
        return (
            prevProps.displayDate.getTime() === nextProps.displayDate.getTime() &&
            prevProps.isYearMonthView === nextProps.isYearMonthView &&
            prevProps.handlePrevMonth === nextProps.handlePrevMonth &&
            prevProps.handleNextMonth === nextProps.handleNextMonth
        )
    }
)
