import authReducer from "@redux/auth/slice"
import { combineReducers } from "@reduxjs/toolkit"
import { splitApi } from "@redux/splitApi"
import { toastSlice } from "@redux/toasts"

const appReducer = combineReducers({
    [splitApi.reducerPath]: splitApi.reducer,
    auth: authReducer,
    toast: toastSlice.reducer,
})

export type RootState = ReturnType<typeof appReducer>

export default appReducer
