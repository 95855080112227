import { Box, ListItem, Text, UnorderedList, VStack } from "@chakra-ui/layout"
import { useTheme } from "@chakra-ui/system"
import React from "react"
import { useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import TextInput from "components/ui/textInput"
import NetcoinsLogo from "assets/svgs/netcoins/netcoins-logo"
import NetcoinsLogoText from "assets/svgs/netcoins/netcoins-logo-text"
import { useTranslation } from "react-i18next"
import { useToast } from "@chakra-ui/react"
import usePasswordValidation from "hooks/usePasswordValidation"
import { MdArrowBack, MdLockOutline, MdOutlineEmail } from "react-icons/md"
import useGenericToast from "@hooks/useGenericToast"

type FormData = {
    email: string
    password: string
    repeatPassword: string
}

type Props = {
    setIsCreateNewPassword: React.Dispatch<React.SetStateAction<boolean>>
    setWasNewPasswordCreated: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CreateNewPassword({ setIsCreateNewPassword, setWasNewPasswordCreated }: Props) {
    const { handleSubmit, control, setError, clearErrors } = useForm<FormData>()
    const theme = useTheme()
    const { t } = useTranslation(["login", "common"])
    const { errorToast } = useGenericToast()
    const passwordValidation = usePasswordValidation()

    const [isLoading, toggleLoading] = React.useState(false)
    const [passwordErr, setPasswordErr] = React.useState<string>()

    async function onSubmit(values: FormData) {
        clearErrors("repeatPassword")
        const { email, password, repeatPassword } = values

        // check for matching passwords
        if (password !== repeatPassword) {
            setError("repeatPassword", {
                type: "custom",
                message: t("createNewPassword.noMatch"),
            })
            return
        }

        // additional validation check for passwords
        const err = passwordValidation(password)
        if (err) {
            setError("repeatPassword", { type: "custom", message: err })
            return
        }

        // success path below
        toggleLoading(true)
        const token = window.location.pathname.split("/").pop()
        fetch("/password/reset", {
            method: "POST",
            headers: {
                "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? "",
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                token,
                email: email.trim(),
                password: password.trim(),
                password_confirmation: repeatPassword.trim(),
            }),
        })
            .then(async (res) => {
                if (!res.ok) {
                    const data = await res.json()
                    const description = data?.errors?.password ? data.errors.password.join(" ") : (data.message ?? "")

                    if (description === "") {
                        throw data
                    }
                    errorToast(description)
                    return
                }
                setWasNewPasswordCreated(true)
                setIsCreateNewPassword(false)
            })
            .catch(() => {
                errorToast(t("error.server"), t("error.formDesc"))
            })
            .finally(() => toggleLoading(false))
    }

    return (
        <>
            <VStack spacing="1.5rem" display="flex" flexGrow={1} w="full">
                <VStack py="1.5rem" spacing="1.5rem">
                    <NetcoinsLogo color="white" width={50} />
                    <NetcoinsLogoText color="white" width={200} />
                </VStack>
                <Text textStyle="InterSemiboldBodySmall" color="white" alignSelf="flex-start">
                    {t("createNewPassword.createNew")}
                </Text>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                    <Box display="flex" flexDir="column" w="full" alignItems={"start"} rowGap="0.75rem">
                        <TextInput
                            baseColor="bluePurple.80"
                            control={control}
                            name="email"
                            rules={{ required: t("error.required", { ns: "common" }) }}
                            placeholder={t("login.emailPlaceholder")}
                            size="lg"
                            leftIcon={<MdOutlineEmail color={theme.colors.grey[20]} width={20} />}
                        />
                        <TextInput
                            baseColor="bluePurple.80"
                            control={control}
                            name="password"
                            placeholder={t("login.newPasswordPlaceholder")}
                            maxLength={32}
                            rules={{
                                required: t("error.required", { ns: "common" }),
                                minLength: {
                                    value: 10,
                                    message: t("signUp.error.min"),
                                },
                                maxLength: {
                                    value: 32,
                                    message: t("signUp.error.max"),
                                },
                            }}
                            size="lg"
                            leftIcon={<MdLockOutline color={theme.colors.grey[20]} size={20} />}
                            type="password"
                        />
                        <UnorderedList
                            textStyle="InterRegularNano"
                            color="white"
                            alignSelf={"flex-start"}
                            listStylePos={"inside"}
                        >
                            <ListItem>{t("signUp.passMinimum")}</ListItem>
                            <ListItem>{t("signUp.passCapital")}</ListItem>
                            <ListItem>{t("signUp.passLower")}</ListItem>
                            <ListItem>{t("signUp.passNumber")}</ListItem>
                            <ListItem>{t("signUp.passSpecial")}</ListItem>
                        </UnorderedList>
                        <TextInput
                            baseColor="bluePurple.80"
                            control={control}
                            maxLength={32}
                            name="repeatPassword"
                            placeholder="Confirm new password"
                            rules={{
                                required: t("error.required", { ns: "common" }),
                                minLength: {
                                    value: 10,
                                    message: t("signUp.error.min"),
                                },
                                maxLength: {
                                    value: 32,
                                    message: t("signUp.error.max"),
                                },
                            }}
                            size="lg"
                            leftIcon={<MdLockOutline color={theme.colors.grey[20]} size={20} />}
                            type="password"
                        />
                        <StandardButton
                            data-testid="reset-button"
                            mt="1.5rem"
                            type="submit"
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                            isLoading={isLoading}
                        >
                            {t("createNewPassword.reset")}
                        </StandardButton>
                    </Box>
                </form>
                <Box
                    cursor="pointer"
                    w="full"
                    display="flex"
                    columnGap="0.75rem"
                    onClick={() => setIsCreateNewPassword(false)}
                >
                    <MdArrowBack size={24} color={theme.colors.blue[70]} />
                    <Text color="blue.70" textStyle="InterRegularBody">
                        {t("twoFa.returnToSignIn")}
                    </Text>
                </Box>
            </VStack>
        </>
    )
}
