import { Navigate, Outlet } from "react-router-dom"
import { ROUTES } from "./routes"
import { useAuth } from "@hooks/useAuth"
import AuthProvider from "@util/AuthProvider"

const PrivateRoutes = () => {
    return (
        <AuthProvider>
            <Outlet />
        </AuthProvider>
    )
}

export default PrivateRoutes
