import React, { useCallback, useRef, useEffect } from "react"
import { Box, Grid, HStack, Text, VStack, Collapse, Button } from "@chakra-ui/react"

interface YearMonthSelectorProps {
    years: number[]
    months: string[]
    displayDate: Date
    expandedYear: number | null
    handleYearClick: (year: number) => void
    handleMonthSelect: (monthIndex: number, year: number) => void
}

export const YearMonthSelector = React.memo<YearMonthSelectorProps>(
    ({ years, months, displayDate, expandedYear, handleYearClick, handleMonthSelect }) => {
        const yearRefs = useRef<Map<number, HTMLDivElement>>(new Map())
        const scrollRef = useRef<HTMLDivElement>(null)

        useEffect(() => {
            const container = yearRefs.current.get(expandedYear || displayDate.getFullYear())
            if (container) {
                container.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                })
            }
        }, [expandedYear, displayDate])

        const renderMonthButton = useCallback(
            (month: string, index: number, year: number) => {
                const isSelected = displayDate.getMonth() === index && displayDate.getFullYear() === year
                return (
                    <Button
                        p={0}
                        py={3}
                        px={5}
                        key={`${year}-${month}`}
                        backgroundColor={isSelected ? "blue.100" : undefined}
                        textColor={isSelected ? "white" : undefined}
                        variant={isSelected ? "solid" : "ghost"}
                        onClick={() => handleMonthSelect(index, year)}
                    >
                        <Text textStyle={"ManropeMediumBody"} color="currentcolor">
                            {month}
                        </Text>
                    </Button>
                )
            },
            [displayDate, handleMonthSelect]
        )

        return (
            <Box maxH="300px" overflowY="auto" ref={scrollRef} minW="300px">
                <VStack align="stretch" spacing={0}>
                    {years.map((year) => (
                        <Box
                            key={year}
                            ref={(el) => {
                                if (el) yearRefs.current.set(year, el)
                            }}
                        >
                            <Box
                                w="100%"
                                m={0}
                                onClick={() => handleYearClick(year)}
                                justifyContent="space-between"
                                _hover={{
                                    cursor: "pointer",
                                }}
                                p={2}
                                bg={"grey.light.5"}
                                borderBottomWidth={1}
                                borderBottomColor={"grey.light.10"}
                            >
                                <Text textStyle={"ManropeMediumBody"}>{year}</Text>
                            </Box>
                            <Collapse in={expandedYear === year || displayDate.getFullYear() === year} unmountOnExit>
                                <Grid templateColumns="repeat(3, 1fr)" my={2}>
                                    {months.map((month, index) => renderMonthButton(month, index, year))}
                                </Grid>
                            </Collapse>
                        </Box>
                    ))}
                </VStack>
            </Box>
        )
    },
    (prevProps, nextProps) => {
        return (
            prevProps.displayDate.getTime() === nextProps.displayDate.getTime() &&
            prevProps.expandedYear === nextProps.expandedYear &&
            prevProps.handleYearClick === nextProps.handleYearClick &&
            prevProps.handleMonthSelect === nextProps.handleMonthSelect &&
            prevProps.years.length === nextProps.years.length &&
            prevProps.months.length === nextProps.months.length
        )
    }
)
