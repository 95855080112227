import React, { useState } from "react"
import {
    Box,
    VStack,
    Input,
    Text,
    Image,
    HStack,
    IconButton,
    InputGroup,
    InputLeftElement,
    Flex,
} from "@chakra-ui/react"
import { MdCheck, MdClose, MdSearch } from "react-icons/md"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { useTranslation } from "react-i18next"

export interface RadioSelectOption {
    name: string
    symbol: string
    imageUrl: string
}

interface RadioSelectorProps {
    options: RadioSelectOption[]
    onSelect?: (option: RadioSelectOption | null) => void
    selectedOption?: RadioSelectOption | null
    placeholder?: string
    inheritOverflow?: boolean
}

const RadioSelect: React.FC<RadioSelectorProps> = ({
    options,
    onSelect,
    selectedOption,
    placeholder = "Search",
    inheritOverflow,
}) => {
    const { t } = useTranslation(["app", "common"])

    const [searchTerm, setSearchTerm] = useState("")

    const filteredOptions = options.filter(
        (option) =>
            option.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            option.symbol.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const handleSelect = (option: RadioSelectOption) => {
        onSelect?.(option)
        setSearchTerm("")
    }

    const handleClearSelection = () => {
        onSelect && onSelect(null)
        setSearchTerm("")
    }

    const isOptionSelected = (option: RadioSelectOption) =>
        selectedOption?.symbol.toLowerCase() === option.symbol.toLowerCase()

    const handleClearSearchTerm = () => {
        setSearchTerm("")
    }

    return (
        <Box display={"flex"} flexDir={"column"} h="full">
            <VStack align="stretch" height="100%" spacing={3}>
                <Box py={3} borderBottom={"1px solid"} borderBottomColor={"grey.light.10"}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <MdSearch size={18} color="black" />
                        </InputLeftElement>
                        <Input
                            placeholder={placeholder}
                            _placeholder={{ textStyle: "ManropeMedium", color: "grey.light.40" }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            border={"none"}
                            borderColor="gray.200"
                            borderRadius="md"
                            _focus={{ boxShadow: "none", border: "none" }}
                        />
                        {searchTerm && (
                            <Flex align={"center"} onClick={handleClearSearchTerm} _hover={{ cursor: "pointer" }}>
                                <ReactIcon icon={MdClose} size={18} />
                            </Flex>
                        )}
                    </InputGroup>
                </Box>

                {selectedOption && (
                    <HStack
                        py={2}
                        px={4}
                        borderWidth="1px"
                        borderRadius="md"
                        cursor="pointer"
                        bg={"grey.light.2"}
                        borderColor={"grey.light.10"}
                    >
                        <Image src={selectedOption.imageUrl} alt={selectedOption.name} boxSize="42px" mr={1} />
                        <VStack align="flex-start" spacing={0} flex={1}>
                            <Text textStyle={"ManropeSemiboldBody"}>{selectedOption.name}</Text>
                            <Text textStyle={"ManropeSemiboldBodySmall"} color="grey.light.50">
                                {selectedOption.symbol}
                            </Text>
                        </VStack>
                        <VStack spacing={0}>
                            <IconButton
                                aria-label="Clear selection"
                                icon={<MdClose size={20} color="black" />}
                                size="xs"
                                variant="ghost"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleClearSelection()
                                }}
                            />
                        </VStack>
                    </HStack>
                )}

                <Box flex={1} overflowY={inheritOverflow ? "inherit" : "auto"} pb={2}>
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <HStack
                                key={index}
                                py={2}
                                px={4}
                                cursor="pointer"
                                _hover={{ bg: "blue.100", borderRadius: "md", color: "white" }}
                                onClick={() => handleSelect(option)}
                                role="group"
                            >
                                <Image src={option.imageUrl} alt={option.name} boxSize="42px" mr={1} />
                                <VStack align="flex-start" spacing={0} flex={1}>
                                    <Text textStyle={"ManropeSemiboldBody"} _groupHover={{ color: "white" }}>
                                        {option.name}
                                    </Text>
                                    <Text
                                        textStyle={"ManropeSemiboldBodySmall"}
                                        color="grey.light.50"
                                        _groupHover={{ color: "white" }}
                                    >
                                        {option.symbol}
                                    </Text>
                                </VStack>
                                {isOptionSelected(option) && (
                                    <VStack>
                                        <ReactIcon icon={MdCheck} size={24} />
                                    </VStack>
                                )}
                            </HStack>
                        ))
                    ) : (
                        <Flex justify={"center"} p={4}>
                            <Text>{t("common:noResults")}</Text>
                        </Flex>
                    )}
                </Box>
            </VStack>
        </Box>
    )
}

export default RadioSelect
