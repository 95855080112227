import { Box, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { MdOutlineWarningAmber } from "react-icons/md"

type Props = {}

export default function DisableDisclaimer({}: Props) {
    const { t } = useTranslation(["twoFA"])

    return (
        <Box mt={"1.725rem"}>
            <Flex
                justifyContent={"center"}
                align="center"
                w="96px"
                py={"1rem"}
                borderRadius={"md"}
                bgColor={"red.light.10"}
                mb="1.5rem"
            >
                <ReactIcon icon={MdOutlineWarningAmber} color="red.light.100" size={24} />
            </Flex>
            <Text textStyle={"ManropeSemiboldBody"} mb={"0.5rem"}>
                {t("twoFASetup.disabledWish")}
            </Text>
            <Text textStyle={"InterRegularBodySmall"} mb={"1.725rem"}>
                {t("twoFASetup.disabledRisk")}
            </Text>
        </Box>
    )
}
