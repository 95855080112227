import React, { memo } from "react"
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    PlacementWithLogical,
    Text,
    Box,
    Portal,
} from "@chakra-ui/react"

type TooltipProps = {
    triggerComponent: React.ReactNode
    bodyText: string
    placement?: PlacementWithLogical
    usePortal?: boolean
}

const MemoizedPopoverContent = memo(({ bodyText }: { bodyText: string }) => (
    <PopoverContent backgroundColor={"grey.light.10"} w="fit-content" p={0} borderRadius={4} maxW={"240px"}>
        <PopoverArrow backgroundColor={"grey.light.10"} />
        <PopoverBody p={2}>
            <Text textStyle={"InterRegularXSmall"}>{bodyText}</Text>
        </PopoverBody>
    </PopoverContent>
))

const TooltipComp: React.FC<TooltipProps> = ({
    triggerComponent,
    bodyText,
    placement = "right",
    usePortal = false,
}) => {
    return (
        <Popover
            placement={placement}
            closeOnBlur
            modifiers={[
                {
                    name: "preventOverflow",
                    options: {
                        boundary: "viewport", // Ensures the tooltip stays within the viewport
                    },
                },
                {
                    name: "flip",
                    options: {
                        fallbackPlacements: ["top", "bottom", "left", "right"], // Enables automatic flipping to available placements
                    },
                },
            ]}
        >
            <PopoverTrigger>
                <Box cursor={"pointer"} w="fit-content">
                    {triggerComponent}
                </Box>
            </PopoverTrigger>
            {usePortal ? (
                <Portal>
                    <MemoizedPopoverContent bodyText={bodyText} />
                </Portal>
            ) : (
                <MemoizedPopoverContent bodyText={bodyText} />
            )}
        </Popover>
    )
}

export default TooltipComp
