import { Box, Text, Flex, Radio, RadioGroup, Button } from "@chakra-ui/react"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import { useTranslation } from "react-i18next"
import DashboardFooter from "@components/footer/dashboard"
import NetcoinsPayTransactionsTable from "@components/netcoinsPay/netcoinsPayTransactionsTable"
import { useEffect, useMemo, useState } from "react"
import { FaAngleRight } from "react-icons/fa6"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import MobileFilterDrawer from "./MobileFilterDrawer"
import { filterOptions, initialState } from "./constants"
import { DateRange } from "./types"
import DatePicker from "@components/ui/datePicker"
import ScreenContainer from "@components/ui/containers/screen"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "@routing/routes"
import { useNPayTransactionsQuery } from "@redux/netcoinsPay/apiSlice"
import TablePaginator from "@components/ui/paginator/tablePaginator"
import { formatRangeDate } from "@util/dateFormating"

export default function NetcoinsPayTransactionsPage(): JSX.Element {
    const { t } = useTranslation("app")
    const { t: ct } = useTranslation("common")

    const filterOptionsValues = filterOptions()

    const [cardType, setCardType] = useState(filterOptionsValues[0].value)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)

    const rangeFilter = useMemo(() => {
        if (startDate && !endDate) {
            const startFormattedDate = formatRangeDate(startDate)
            const endFormattedDate = formatRangeDate(new Date())
            return `${startFormattedDate},${endFormattedDate}`
        }
        if (!startDate && endDate) {
            const startFormattedDate = formatRangeDate(new Date(1999))
            const endFormattedDate = formatRangeDate(endDate)
            return `${startFormattedDate},${endFormattedDate}`
        }
        if (startDate && endDate) {
            const startFormattedDate = formatRangeDate(startDate)
            const endFormattedDate = formatRangeDate(endDate)
            return `${startFormattedDate},${endFormattedDate}`
        }
        return ""
    }, [startDate, endDate])

    const cardFilter = useMemo(() => {
        if (cardType === "all") {
            return ""
        }
        if (cardType === "virtual") {
            return "virtual,mc-virtual"
        }
        return "physical,mc-physical"
    }, [cardType])

    const { data: transactionsData, isLoading: isTransactionsLoading } = useNPayTransactionsQuery({
        page: currentPage,
        limit: 10,
        range: rangeFilter,
        cardTypes: cardFilter,
    })

    useEffect(() => {
        if (transactionsData) {
            setTotalPages(Math.ceil(transactionsData.total / 10))
        }
    }, [transactionsData])

    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(1, prev - 1))
    }

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(totalPages, prev + 1))
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [rangeFilter, cardType])

    const navigate = useNavigate()

    return (
        <ScreenContainer>
            <Flex flexDir="column" gap={6} flex={1}>
                <Flex flexDir="column" gap={2}>
                    <BreadCrumbGroup
                        breadCrumbs={[
                            {
                                text: t("netcoinsPay.netcoinsPay"),
                                hasAfter: true,
                                onClick: () => navigate(ROUTES.DASHBOARD_NETCOINS_PAY),
                            },
                            {
                                text: t("netcoinsPay.netcoinsPayTransactions"),
                                hasAfter: false,
                            },
                        ]}
                        py={2}
                    />

                    <Flex justify="start" flexDir={{ base: "column", md: "row" }} w="100%" gap={6}>
                        <Flex flex={1} flexDir="column" gap={2}>
                            <Text textStyle="ManropeMedium4xLarge">{t("netcoinsPay.netcoinsPayTransactions")}</Text>
                            <Text textStyle="InterRegularBodySmall">{t("netcoinsPay.text")}</Text>
                        </Flex>
                    </Flex>
                </Flex>

                <Box flexDir="column" w="100%" alignItems={{ md: "center" }} gap={3} px={{ base: 0, xl: "8rem" }}>
                    <Flex gap={6} w="full" py={3} mb={3}>
                        <Flex height="40px" alignItems="center" display={{ base: "none", md: "flex" }}>
                            <Text color="bluePurple.100" textStyle="ManropeMediumBodySmall" width="80px">
                                {ct("filterBy")}
                            </Text>
                        </Flex>

                        <Flex flexDir="column" gap={3} w="100%">
                            <RadioGroup onChange={setCardType} value={cardType} display={{ base: "none", md: "flex" }}>
                                <Flex gap={4} alignItems="center" height="40px">
                                    {filterOptionsValues.map(({ label, value }, index) => (
                                        <Radio value={value} key={index} gap={1}>
                                            <Text textStyle="ManropeMediumBodySmall">{label}</Text>
                                        </Radio>
                                    ))}
                                </Flex>
                            </RadioGroup>

                            <Flex w="100%" justifyContent="space-between" gap={6} alignItems="center">
                                <Flex gap={2} alignItems="center" display={{ base: "none", md: "flex" }}>
                                    <DatePicker
                                        selectedDate={startDate}
                                        label={ct("startDate")}
                                        maxDate={endDate}
                                        onDateChange={(date) => setStartDate(date)}
                                    />

                                    <Text textStyle="ManropeMediumBodySmall">-</Text>

                                    <DatePicker
                                        selectedDate={endDate}
                                        minDate={startDate}
                                        label={ct("endDate")}
                                        onDateChange={(date) => setEndDate(date)}
                                    />
                                </Flex>

                                <MobileFilterDrawer
                                    cardType={cardType}
                                    setCardType={setCardType}
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />

                                <StandardButton
                                    variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                                    size="md"
                                    rightIcon={FaAngleRight}
                                    onClick={() => navigate(ROUTES.NPAY_STATEMENTS)}
                                >
                                    {t("netcoinsPay.viewStatements")}
                                </StandardButton>
                            </Flex>
                        </Flex>
                    </Flex>

                    <NetcoinsPayTransactionsTable
                        data={transactionsData?.transactions}
                        isLoading={isTransactionsLoading}
                    />

                    <TablePaginator
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPreviousPage={handlePreviousPage}
                        onNextPage={handleNextPage}
                    />
                </Box>
            </Flex>

            <DashboardFooter />
        </ScreenContainer>
    )
}
