import React from "react"
import { Box, Text } from "@chakra-ui/react"
import PinInput from "components/ui/pinInput"
import StandardButton from "components/ui/buttons/standard"
import useFetchWrapper, { Method } from "hooks/useFetchWrapper"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import ErrorMessage from "components/ui/errorMessage"
import { REGISTRATION_STEP, REG_SCREENS } from "@screens/onboarding/types"
import AmplitudeClient from "sdks/amplitude"
import { SubScreenProps } from "screens/onboarding"
import { useTranslation } from "react-i18next"

export default function ConfirmPin({ userObj, advancePhase, setUserObj }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])
    const { fetchWrapper } = useFetchWrapper()

    const [code, setCode] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState<string>()
    const [resendText, setResendText] = React.useState<string>(t("resendCode", { ns: "common" }))

    async function verifyPhone(val?: string) {
        setIsLoading(true)
        const finalCode = parseInt(val ?? code) // this accounts for when onComplete finishes before setCode does
        fetchWrapper("/api/v2/account/identity/phone/verification", Method.POST, {
            code: finalCode,
        })
            .then(() => {
                setUserObj((prev) => {
                    if (!prev) return prev
                    return {
                        ...prev,
                        verification: {
                            ...prev.verification,
                            phone: {
                                ...prev.verification.phone,
                                is_verified: true,
                            },
                        },
                    }
                })
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.WhatPhone, REGISTRATION_STEP.PHONE_VERIFICATION)
                advancePhase()
            })
            .catch(() => setError(t("invalidCode", { ns: "common" })))
            .finally(() => setIsLoading(false))
    }

    async function resendCode() {
        setResendText(t("whatPhone.sent"))
        fetchWrapper("/api/v2/account/identity/phone/verification", Method.GET)
            .then(() => {
                setTimeout(() => {
                    setResendText(t("resendCode", { ns: "common" }))
                }, 10000)
            })
            .catch(() => {
                setResendText(t("errorSendingCode", { ns: "common" }))
            })
    }

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("whatPhone.ensure")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                {t("whatPhone.sentTo", { phone: userObj?.identity?.phone })}
            </Text>
            <PinInput
                numOfFields={6}
                baseColor="white"
                name="pin"
                value={code}
                onChange={setCode}
                size="lg"
                onComplete={verifyPhone}
                error={!!error}
                otp
            />
            <ErrorMessage>{error}</ErrorMessage>
            <StandardButton
                data-testid="continue-button"
                onClick={() => verifyPhone()}
                type="button"
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                mt="2rem"
                isLoading={isLoading}
                mb="0.75rem"
                w="full"
                disabled={code.length !== 4 || isLoading}
            >
                {t("continue", { ns: "common" })}
            </StandardButton>
            <StandardButton
                data-testid="resend-button"
                onClick={resendCode}
                type="button"
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_GHOST}
                w="full"
                disabled={resendText === t("whatPhone.sent")}
            >
                {resendText}
            </StandardButton>
        </Box>
    )
}
