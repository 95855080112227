import { Box, Spinner, Text, useToast } from "@chakra-ui/react"
import { SCREEN_MAPPING } from "@screens/onboarding/mappings"
import { REG_SCREENS } from "@screens/onboarding/types"
import Header from "components/header"
import useFetchWrapper, { Method } from "hooks/useFetchWrapper"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import WhereReside from "screens/onboarding/whereReside"
import AmplitudeClient from "sdks/amplitude"
import { LARGE_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from "theme/consts"
import { establishBusinessOnboardingSteps, establishOnboardingSteps } from "util/verificationSteps"
import AccountType from "./accountType"
import OnMailingList from "./onMailingList"
import PendingApproval from "./pendingApproval"
import Surveys from "./surveys"
import NoMoreAttempts from "./surveys/noMoreAttempts"
import ThirdParty from "./surveys/thirdParty"
import { SurveyData } from "./surveys/types"
import whereIncorporated from "./whereIncorporated"
import NotNA from "./whereIncorporated/notNA"
import NotCanadian from "./whereReside/notCanadian"
import { IAccountDetails, AccountType as AccountTypeEnum, TUserState } from "@redux/account/types"
import useGenericToast from "@hooks/useGenericToast"

const BIG_MAX_WIDTH_SCREENS = [REG_SCREENS.Surveys, REG_SCREENS.RiskStatement]
const NO_BACK_BUTTON_SCREENS_BOTH = [REG_SCREENS.HowDidYouHear, REG_SCREENS.RiskStatement]
const NO_BACK_BUTTON_SCREENS_INDIVIDUAL = [REG_SCREENS.WhereReside, REG_SCREENS.WhereLive]
const NO_BACK_BUTTON_SCREENS_BUSINESS = [REG_SCREENS.BeInTouch, REG_SCREENS.WhereIncorporated]

export type SubScreenProps = {
    advancePhase: () => void
    submitSubForm: (
        formName: string,
        values: Record<string, any>,
        analyticsEvent?: () => void,
        httpMethod?: Method
    ) => Promise<void>
    isLoading: boolean
    userObj?: IAccountDetails
    setIsBackVisible: React.Dispatch<React.SetStateAction<boolean>>
    setUserObj: React.Dispatch<React.SetStateAction<IAccountDetails | undefined>>
}

export default function OnBoarding() {
    const { serverErrorToast } = useGenericToast()
    const { fetchWrapper } = useFetchWrapper()
    const { t } = useTranslation("common")

    const initialLoadTimeout = React.useRef<NodeJS.Timeout>()

    const [phases, setPhases] = React.useState<REG_SCREENS[]>([])
    const [currentPhase, setCurrentPhase] = React.useState<{
        name?: REG_SCREENS
        num?: number
    }>({
        name: undefined,
        num: undefined,
    })
    const [surveys, setSurveys] = React.useState<SurveyData[]>([])
    const [surveyNames, setSurveyNames] = React.useState<string[]>([])
    const [isInitialLoading, setInitialLoading] = React.useState(false)
    const [surveyErrorCode, setSurveyErrorCode] = React.useState<"501" | "502">()
    const [isLoading, setIsLoading] = React.useState(false)
    const [userObj, setUserObj] = React.useState<IAccountDetails>()
    const [isBackVisible, setIsBackVisible] = React.useState(false)

    // retrieve all required data
    // would be way cooler to do this beforehand and put it in global state. Do that in standalone app
    React.useEffect(() => {
        initialLoadTimeout.current = setTimeout(() => {
            setInitialLoading(true)
        }, 2000)

        getAllOnboardingData()

        return () => {
            if (initialLoadTimeout.current) {
                clearTimeout(initialLoadTimeout.current)
            }
        }
    }, [])

    // analytics
    React.useEffect(() => {
        if (currentPhase.name) {
            AmplitudeClient.logScreenEvent(currentPhase.name)
        }
    }, [currentPhase])

    const NO_BACK_BUTTON_SCREENS = React.useMemo(() => {
        if (userObj?.type === AccountTypeEnum.INDIVIDUAL) {
            return NO_BACK_BUTTON_SCREENS_BOTH.concat(NO_BACK_BUTTON_SCREENS_INDIVIDUAL)
        } else if (userObj?.type === AccountTypeEnum.BUSINESS) {
            return NO_BACK_BUTTON_SCREENS_BOTH.concat(NO_BACK_BUTTON_SCREENS_BUSINESS)
        } else {
            return NO_BACK_BUTTON_SCREENS_BOTH
        }
    }, [userObj?.type])

    // certain screens are a point-of-no-return
    React.useEffect(() => {
        if (currentPhase?.name && currentPhase.num !== 0) {
            setIsBackVisible(!NO_BACK_BUTTON_SCREENS.includes(currentPhase.name))
        }
    }, [currentPhase])

    async function getAllOnboardingData() {
        fetchWrapper("/api/v2/account/account", Method.GET)
            .then(async (data: { data: IAccountDetails }) => {
                const _data = data.data as IAccountDetails
                // First time going to screen
                if (!_data.type) {
                    setPhases([REG_SCREENS.AccountType])
                    setCurrentPhase({ name: REG_SCREENS.AccountType, num: 0 })
                    // Returning to onboarding and only completed the REG_SCREENS.AccountType screen
                } else if (!_data.country || !_data.region) {
                    const countryScreen =
                        _data.type === "individual" ? REG_SCREENS.WhereReside : REG_SCREENS.WhereIncorporated
                    setPhases([countryScreen])
                    setCurrentPhase({ name: countryScreen, num: 0 })
                    // Anything else
                } else {
                    const _surveys = await doSurveyData(_data)
                    const surveyCount = countSurveyScreens(_surveys)
                    const phases: REG_SCREENS[] =
                        _data.type === "individual"
                            ? establishOnboardingSteps(_data, surveyCount)
                            : establishBusinessOnboardingSteps(_data, surveyCount)
                    setPhases(phases)
                    setCurrentPhase({ name: phases[0], num: 0 })
                }
                setUserObj(_data)
            })
            .catch(serverErrorToast)
            .finally(() => {
                if (initialLoadTimeout.current) {
                    clearTimeout(initialLoadTimeout.current)
                }
                setInitialLoading(false)
            })
    }

    async function doSurveyData(_data: IAccountDetails): Promise<SurveyData[]> {
        const surveyFetches = _data.verification.surveys.reduce((arr, surveyData, i) => {
            if (surveyData.passed === false) {
                arr.push(fetchWrapper(`/api/v2/survey?type=${surveyData.name}`, Method.GET))
            }
            return arr
        }, [] as Promise<SurveyData>[])

        let _surveys: SurveyData[] = []

        await Promise.all(surveyFetches)
            .then((surveys: SurveyData[]) => {
                _surveys = surveys
                // only gathers the names of the surveys that haven't been passed yet
                const requiredSurveyNames = _data.verification.surveys.reduce((arr: string[], survey) => {
                    if (!survey.passed) {
                        arr.push(survey.name)
                    }
                    return arr
                }, [] as string[])
                setSurveyNames(requiredSurveyNames)
                setSurveys(surveys)
            })
            .catch(serverErrorToast)
        return _surveys
    }

    function countSurveyScreens(surveys: SurveyData[]): number {
        let counter = 0
        for (let x = 0; x < surveys.length; x++) {
            counter += Object.keys(surveys[x].categories).length
        }
        return counter
    }

    // HOC for passing onboarding props to each subform
    function withOnboardingProps<T>(Component: FunctionComponent<T & SubScreenProps>) {
        return (props: T) => (
            <Component
                {...props}
                isLoading={isLoading}
                advancePhase={advancePhase}
                submitSubForm={submitSubForm}
                userObj={userObj}
                setUserObj={setUserObj}
                setIsBackVisible={setIsBackVisible}
            />
        )
    }

    function advancePhase() {
        // onboarding is completed. navigate to dashboard
        if (currentPhase.num === phases.length - 1) {
            window.open("/dashboard", "_self")
        }

        setCurrentPhase((prev) => {
            if (typeof prev.num !== "number" || prev.num >= phases.length - 1) return prev
            return { name: phases[prev.num + 1], num: prev.num + 1 }
        })
    }

    // used for each subform
    async function submitSubForm(
        url: string,
        values: Record<string, any>,
        analyticsEvent?: () => void,
        httpMethod?: Method
    ) {
        setIsLoading(true)
        fetchWrapper(url, httpMethod ?? Method.POST, values)
            .then(() => {
                analyticsEvent && analyticsEvent()
                advancePhase()
            })
            .catch((e) => {
                // Survey failed or failed so bad they're banned
                if (e.code === "501" || e.code === "502") {
                    setSurveyErrorCode(e.code)
                } else {
                    serverErrorToast()
                }
            })
            .finally(() => setIsLoading(false))
    }

    function goBack() {
        if (currentPhase.num === 1 || currentPhase.num === 0) {
            setIsBackVisible(false)
        }

        // Due to the dynamic nature of the survey components, pressing back on any of those screens
        // will result in returning to the beginning of all surveys
        if (currentPhase.name === REG_SCREENS.Surveys) {
            setCurrentPhase({
                name: REG_SCREENS.SurveyIntro,
                num: phases.findIndex((phase) => phase === REG_SCREENS.SurveyIntro),
            })
            // Standard behaviour
        } else {
            setCurrentPhase((prev) => {
                if (typeof prev.num !== "number" || prev.num === 0) return prev
                return { name: phases[prev.num - 1], num: prev.num - 1 }
            })
        }
    }

    const ProgressValue = React.useMemo(() => {
        // At this point we're unable to determine the phases length. A fake value is returned instead
        const lenIs2 = phases.length === 2
        const firstIsAccountType = phases[0] === REG_SCREENS.AccountType
        const secondIsWhere = phases[1] === REG_SCREENS.WhereReside || phases[1] === REG_SCREENS.WhereIncorporated
        if (lenIs2 && firstIsAccountType && secondIsWhere) {
            return 8
        }
        return currentPhase.num ? Math.round((currentPhase.num / phases.length) * 100) : 0
    }, [currentPhase, phases])

    // Variety of scenarios that result in a screen that's not part of normal onboarding
    const RenderBlockedFromOnboardingScreen = React.useMemo(() => {
        if (userObj?.userState === TUserState.PENDING_EVALUATION) {
            return <PendingApproval isCanada={userObj?.country === "CA"} />
        } else if (userObj?.country === "XX") {
            return <NotNA />
        } else if (
            userObj?.userState === TUserState.APPROVED &&
            userObj?.type === AccountTypeEnum.BUSINESS &&
            userObj.country !== "CA"
        ) {
            return <NotCanadian toggleOff={() => window.open("/logout", "_self")} />
        } else if (userObj?.verification?.status === "on_mailing_list") {
            return <OnMailingList />
        } else if (userObj?.verification?.surveys.some((survey) => !!survey.failed_at)) {
            const arr = userObj?.verification.surveys
            if (arr) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].failed_at) {
                        return arr[i].name === "risk" ? <NoMoreAttempts /> : <ThirdParty />
                    }
                }
            }
            return null
        } else {
            return null
        }
    }, [userObj])

    const RenderedScreen = React.useMemo(() => {
        switch (currentPhase.name) {
            case REG_SCREENS.WhereReside: {
                return withOnboardingProps(WhereReside)({
                    setIsLoading,
                    countSurveyScreens,
                    phases,
                    setCurrentPhase,
                    doSurveyData,
                    setPhases,
                })
            }
            case REG_SCREENS.WhereIncorporated: {
                return withOnboardingProps(whereIncorporated)({
                    setIsLoading,
                    countSurveyScreens,
                    phases,
                    setCurrentPhase,
                    doSurveyData,
                    setPhases,
                })
            }
            case REG_SCREENS.AccountType: {
                return withOnboardingProps(AccountType)({
                    setIsLoading,
                    phases,
                    setCurrentPhase,
                    setPhases,
                })
            }
            case REG_SCREENS.Surveys:
                return withOnboardingProps(Surveys)({
                    surveys,
                    surveyNames,
                    surveyErrorCode,
                    setSurveyErrorCode,
                    setCurrentPhase,
                    setIsBackVisible,
                })
            // Covers all cases where a screen doesn't require additonal props
            default:
                return currentPhase?.name ? withOnboardingProps(SCREEN_MAPPING[currentPhase.name])({}) : <></>
        }
    }, [
        currentPhase,
        isLoading,
        surveyErrorCode,
        surveys,
        surveyNames,
        phases,
        setPhases,
        setSurveyNames,
        userObj,
        countSurveyScreens,
        setIsLoading,
    ])

    return (
        <Box h="100%" w="100%" display="flex" flexDir="column">
            <Header isBackVisible={isBackVisible} goBack={goBack} progress={ProgressValue} />
            <Box w="full" h="full" px="0.75rem">
                <Box
                    maxW={
                        currentPhase?.name && BIG_MAX_WIDTH_SCREENS.includes(currentPhase.name)
                            ? LARGE_SCREEN_WIDTH
                            : SMALL_SCREEN_WIDTH
                    }
                    w="100%"
                    mx="auto"
                    mt={{ base: "0", md: "4rem" }}
                    pb="0.75rem"
                >
                    {RenderBlockedFromOnboardingScreen || (
                        <>
                            {currentPhase.name && userObj && RenderedScreen}
                            {!currentPhase.name && isInitialLoading && (
                                <Box rowGap="1.5rem" display="flex" flexDir="column" alignItems="center">
                                    <Spinner color="black" />
                                    <Text textStyle="ManropeMediumXLarge" color="black">
                                        Loading...
                                    </Text>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
