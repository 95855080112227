import { TextProps, Text, Flex } from "@chakra-ui/react"
import IconButton from "@components/ui/buttons/icon"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { useState } from "react"
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6"

type Props = {
    children: string
} & TextProps

function maskString(input: string): string {
    const visiblePart = input.slice(-4)
    return "*****" + visiblePart
}

export function TextWithVisibility({ children, ...rest }: Props) {
    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => {
        setIsVisible(!isVisible)
    }

    return (
        <Flex gap="10px" alignItems="center">
            <Text {...rest}>{isVisible ? children : maskString(children)}</Text>
            <IconButton
                onClick={toggleVisibility}
                icon={isVisible ? FaRegEyeSlash : FaRegEye}
                iconOutline={isVisible ? FaRegEyeSlash : FaRegEye}
                name="visibility"
                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_GHOST}
                size="sm"
            />
        </Flex>
    )
}
