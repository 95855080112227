import { Box, Text } from "@chakra-ui/layout"
import ScreenContainer from "@components/ui/containers/screen"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

export default function AssetStatement() {
    const { asset } = useParams()

    const { t } = useTranslation("common")

    const [htmlContent, setHtmlContent] = useState("")

    useEffect(() => {
        if (asset) {
            const fetchHtmlFile = async () => {
                try {
                    const content = await import(`../../../assets/assetStatements/${asset}.html`)
                    setHtmlContent(content.default)
                } catch (error) {
                    console.error("Error fetching the HTML file:", error)
                }
            }
            fetchHtmlFile()
        }
    }, [asset])

    return (
        <ScreenContainer>
            <Box pb={8} borderBottomWidth={1} borderBottomColor="grey.light.10">
                <Text textStyle="ManropeMedium4xLarge">{t("cryptoAssetStatement")}</Text>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: htmlContent }} py={8} className="hosted-page" />
        </ScreenContainer>
    )
}
